<template>
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <main class="py-6">
            <div class="row align-items-center">
                <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                    <div class="card-header" style="background: none; border: none">
                        <h2 class="mb-0">Help</h2>
                    </div>
                </div>
            </div>
            <div style="background: #f4f5fa" class="table-align">

                <div class="card border-0 mb-7 table-align" id="userForDiv">
                    <div style="padding-bottom: 15px">
                        <h1>{{title}}</h1>
                    </div>
                    <div class="table-responsive">
                        <span v-html="description"></span>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
export default {
    name: "Help",
    props: {},
    components: {
    },
    data() {
        return {
            apiLoading: false,
            title: "",
            description: "",
        };
    },
    mounted() {
        this.helpPage();
    },
    created() { },
    computed: {},
    methods: {
        helpPage() {
            this.apiLoading = true;
            let data = {
                id: 1,
            };
            this.$store
                .dispatch("supportEdit", data)
                .then((response) => {
                    if (response.success) {
                        let res = response && response.data[0] ? response.data[0] : "";
                        this.title = res && res.title ? res.title : "";
                        this.description = res && res.description ? res.description : "";
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.apiLoading = false));
        },
    },
};
</script>
<style scoped>
.table-align {
    border-radius: 15px;
    padding: 15px;
    margin: 10px;
}

.header th {
    height: 10px;
}

.row {
    margin-right: 0px;
}

/* style start */
.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

@media (min-width: 1400px) {}

@media (max-width: 1599.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {

    div#userForDiv {
        margin: 0;
    }

    .table-responsive {
        border: 0;
    }

    .table-responsive .table th {
        border: 0;
    }

}

@media (max-width: 480px) {}
</style>