<template>
  <div>
  <div v-if="apiLoading" class="loading">
      Loading&#8230;
    </div>
  <main class="py-6">
    <div class="row align-items-center">
      <div class="col-sm-6 col-12 mb-4 mb-sm-0">
        <div class="card-header" style="background: none; border: none">
          <h2 class="mb-0">Request Review</h2>
        </div>
      </div>

      <div class="row col-md-6 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
        <div class="col-12 col-md-6 mb-2 pb-1">
          <button type="button" class="sendNowButton" @click="openSingleModal()">
            <p class="buttonText">+&ensp;New Request</p>
          </button>
        </div>
        <div class="col-12 col-md-6 mb-2 pb-1">
          <button type="button" class="bulkRequestButton" @click="openBulkModel()">
            <p class="buttonText">Bulk Request</p>
          </button>
        </div>
        <div id="v-step-8"></div>
        <div class="v-step-2"></div>
      </div>
    </div>
    <div style="background: #f4f5fa" class="table-align">

      <div class="card border-0 mb-7 table-align" id="userForDiv">
        <div style="padding-bottom: 15px">
          <h1>Requests</h1>
        </div>

        <ActionBar @updateListing="updateList($event)" :selected-users="selectedReviews" :filter-options="[]"
          :actionType="'request_reviews'" />
        <div class="table-responsive">
          <table class="table table-hover table-nowrap formtable">
            <thead class="thead-light">
              <tr class="header">
                <th class="heading" scope="col" style="padding-left: 15px">
                  <div class="
                      custom-control custom-checkbox
                      checkbox-lg
                      form-check-inline
                    ">
                    <input type="checkbox" class="custom-control-input" @change="pushAllSelectedReviews($event)"
                      id="all" />
                    <label class="custom-control-label checkLabel" for="all"></label>
                  </div>
                </th>
                <th class="heading" scope="col">DATE</th>
                <th class="heading" scope="col">CUSTOMER NAME</th>
                <th class="heading" scope="col">SENT BY</th>
                <th class="heading" scope="col">STATUS</th>
                <th class="heading" scope="col">USER</th>
                <th class="heading" scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody v-if="requestListing.length > 0">
              <tr v-for="request in requestListing" :key="request.id">
                <td>
                  <div class="
                      custom-control custom-checkbox
                      checkbox-lg
                      form-check-inline
                    ">
                    <input :id="request.id" type="checkbox" class="custom-control-input"
                      @change="pushSelectedReviews($event, request.id)" />
                    <label class="custom-control-label checkLabel" :for="request.id"></label>
                  </div>
                </td>
                <td>
                  <p class="text-heading">
                    {{
                        moment(String(request.created_at)).format("MM-DD-YYYY")
                    }}
                  </p>
                </td>
                <td>
                  <p class="text-heading">
                    {{
                        `${request.first_name} ${request.last_name ? request.last_name : ""
                        }`
                    }}
                  </p>
                </td>
                <td>
                  <p class="text-heading"
                    v-if="(request.MAIL == 0 || request.MAIL == 1) && (request.SMS == 0 || request.SMS == 1)">
                    {{ `Both` }}
                  </p>
                  <p class="text-heading" v-else-if="request.MAIL == 0 || request.MAIL == 1">
                    {{ `Email` }}
                  </p>
                  <p class="text-heading" v-else-if="request.SMS == 0 || request.SMS == 1">
                    {{ `SMS` }}
                  </p>
                </td>
                <td>
                  <p v-if="request.scheduled" class="text-heading outer-circle-new">
                    <span class="inner-new">
                      {{ request.scheduled == 1 ? "Scheduled" : "" }}
                    </span>
                  </p>
                  <p v-else-if="request.ReviewSubmitted" class="text-heading outer-circle-resolved">
                    <span class="inner-resolved">
                      {{ request.ReviewSubmitted == 1 ? "Review Submitted" : "" }}
                    </span>
                  </p>
                  <p v-else-if="request.ReviewClicks" class="text-heading outer-circle-pending">
                    <span class="inner-pending">
                      {{ request.ReviewClicks == 1 ? "Clicked" : "" }}
                    </span>
                  </p>
                  <p v-else-if="request.opened" class="text-heading outer-circle-pending">
                    <span class="inner-pending">
                      {{ request.opened == 1 ? "Opened" : "" }}
                    </span>
                  </p>
                  <p v-else-if="request.failed" class="text-heading outer-circle-resolved">
                    <span class="inner-resolved">
                      {{ request.failed == 1 ? "Failed" : "" }}
                    </span>
                  </p>
                  <p v-else-if="request.MAIL" class="text-heading outer-circle-resolved">
                    <span class="inner-resolved">
                      {{ request.MAIL == 1 ? "Sent" : "" }}
                    </span>
                  </p>
                  <p v-else-if="request.SMS" class="text-heading outer-circle-resolved">
                    <span class="inner-resolved">
                      {{ request.SMS == 1 ? "Sent" : "" }}
                    </span>
                  </p>
                  <!-- <p class="text-heading">
                    {{ request.is_status ? "Sent" : "" }}
                  </p> -->
                </td>
                <td>
                  <p class="text-heading">
                    {{ `${request.userName}` }}
                  </p>
                </td>
                <td>
                  <a href="#" class="btn btn-sm btn-neutral" @click="viewSingleRequst(request.id)"><img
                      src="resources/assets/view_icon.svg" /></a>
                  <a href="#" class="btn btn-sm btn-neutral" @click="deleteSingleRequst(request.id)">
                    <img src="resources/assets/trash_icon.svg" />
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <h1>No Requests Found.</h1>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="requestReviewCount > requestReviewPerPage" class="admin-new-pagination role-table-pagination">
          <b-pagination v-model="requestReviewCurrentPage" :total-rows="requestReviewCount"
            :per-page="requestReviewPerPage" @change="showPage" first-number last-number align="center" pills size="lg">
          </b-pagination>
        </div>
      </div>
    </div>
    <transition name="modal" v-if="createModel">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-schedule">
            <div class="row">
              <div class="mb-4 pb-2 requestFrom">
                <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                            formPTag
                          ">
                  <p> Fill out the form below to send a request</p>
                  <div class="d-flex">
                    <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>

                  </div>
                </div>

                <div style="padding: 15px">
                  <div class="row">
                    <div class="col-6 col-md-6 mb-4 pb-1">
                      <div class="form-outline form-white">
                        <input type="text" class="form-control userForm form-control-lg inputOrange"
                          placeholder="First Name" v-model="requestData.first_name" v-validate="'required'"
                          v-bind:name="'requestData.first_name'" v-bind:ref="'requestData.first_name'" :class="{
                            'is-invalid': errors.has('requestData.first_name'),
                          }" />
                      </div>
                    </div>
                    <div class="col-6 col-md-6 mb-4 pb-1">
                      <div class="form-outline form-white">
                        <input type="text" class="form-control userForm form-control-lg inputOrange"
                          placeholder="Last Name (Optional)" v-model="requestData.last_name" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6 col-md-6 mb-4 pb-1">
                      <div class="form-outline form-white">
                        <input type="number" class="form-control userForm form-control-lg inputOrange"
                          placeholder="Mobile Phone" v-model="requestData.phone"
                          v-validate="{ 'required': requestData.email.length < 1 || requestData.SMS || requestData.both, 'max': 15, 'numeric': true }"
                          v-bind:name="'requestData.phone'" v-bind:ref="'requestData.phone'" :class="{
                            'is-invalid': errors.has('requestData.phone'),
                          }" />
                      </div>
                    </div>
                    <div class="col-6 col-md-6 mb-4 pb-1">
                      <div class="form-outline form-white">
                        <input type="text" class="form-control userForm form-control-lg inputOrange"
                          placeholder="Email Address " v-model="requestData.email"
                          v-validate="{ 'required': requestData.phone.length < 1 || requestData.MAIL || requestData.both, 'email': true }"
                          v-bind:name="'requestData.email'" v-bind:ref="'requestData.email'" :class="{
                            'is-invalid': errors.has('requestData.email'),
                          }" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6 col-md-6 mb-2 pb-1">
                      <div class="form-outline form-white">
                        <select class="
                      viewReviewSecondPartdropdownText
                      inputOrange
                      assignFollowUp
                      form-select
                    " v-model="requestData.shortName" v-validate="'required'" v-bind:name="'requestData.code'"
                          v-bind:ref="'requestData.code'" :class="{
                            'is-invalid': errors.has('requestData.code'),
                          }">
                          <option class="viewReviewSecondPartdropdownText" value="">
                            Select Country
                          </option>
                          <option class="viewReviewSecondPartdropdownText" :value="location.shortName"
                            v-for="location in countryCodelist" :key="location.id">
                            {{ `${location.name}` }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <p class="formPTag" style="padding-top: 20px; line-height: 29px">
                      SEND BY
                    </p>
                    <div>
                      <div class="
                    custom-control custom-checkbox
                    checkbox-lg
                    form-check-inline
                  ">
                        <input type="checkbox" class="custom-control-input" id="SMS" name="SMS" value="SMS"
                          v-model="requestData.SMS" @click="changeSMSColor()" />
                        <label class="custom-control-label checkLabel" :class="[{ active: isActiveSMS }]" for="SMS">
                          SMS Text</label>
                      </div>

                      <div class="
                    custom-control custom-checkbox
                    checkbox-lg
                    form-check-inline
                  ">
                        <input type="checkbox" class="custom-control-input" id="email" name="email" value="email"
                          v-model="requestData.MAIL" @click="changeEmailColor()" />
                        <label class="custom-control-label checkLabel" :class="[{ active: isActiveEmail }]"
                          for="email">Email</label>
                      </div>

                      <div class="
                    custom-control custom-checkbox
                    checkbox-lg
                    form-check-inline
                  ">
                        <input type="checkbox" class="custom-control-input" name="both" id="both" value="both"
                          v-model="requestData.both" @click="changeBothColor()" />
                        <label class="custom-control-label checkLabel" :class="[{ active: isActiveBoth }]"
                          for="both">Both</label>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-5">
                    <div class="col-6 col-md-6 mb-2 pb-1">
                      <div class="center-item">
                        <button type="button" class="scheduleButton">
                          <a>
                            <p @click="openscheduleModel()" class="buttonText"
                              style="background: white; color: #00053c">
                              Schedule for Later
                            </p>
                          </a>
                        </button>
                      </div>
                    </div>
                    <div class="col-6 col-md-6 mb-2 pb-1">
                      <button type="button" class="sendNowButton" @click="singleRequest()">
                        <p class="buttonText">Send Now</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </transition>
    <transition name="modal" v-if="scheduleModel">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-schedule">
            <div>
              <form class="d-block">
                <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 25px">
                  <h1 class="bulk-title">Select Time</h1>
                  <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                </div>
                <template>
                  <datetime type="datetime" v-model="scheduleDate" input-class="form-control userForm
                    form-control-lg
                    inputOrange ml-2" :phrases="{ ok: 'Continue', cancel: 'Exit' }" :hour-step="1" :minute-step="1"
                    :week-start="7" use12-hour auto></datetime>
                </template>
              </form>
            </div>

            <div class="col-md-4 col-12 mb-4 mb-sm-0"></div>
            <div class="row mt-5 d-flex justify-content-center">
              <div class="col-6 col-md-6 mb-2 pb-1" style="padding: 0px 25px">
                <button type="button" class="sendNowButton" @click="scheduleSendNow()">
                  <p class="buttonText">Send Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" v-if="bulkRequestModel">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container small-wdth" :class="{ containerHeight: activeButton == true }">
            <div>
              <form class="d-block">
                <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 25px">
                  <h1 class="bulk-title">Bulk Request</h1>
                  <a :href="csvLink" @click="downloadCsv()" class="btn btn btn-outline-dark">Download Sample File</a>

                  <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                </div>
                <div class="row">
                  <div class="col-12 mb-4 pb-2">
                    <div class="drop-it-hot" id="drop-area">
                      <div class="circle">
                        <svg width="74" height="73" viewBox="0 0 74 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="37" cy="36.4998" r="36.5" fill="#9194B3" fill-opacity="0.2" />
                          <path opacity="0.3"
                            d="M22.416 37.9582C22.416 37.229 23.1452 36.4998 23.8743 36.4998C24.6035 36.4998 25.3327 37.229 25.3327 37.9582C25.3327 38.4443 25.3327 40.8748 25.3327 45.2498C25.3327 46.8606 26.6385 48.1665 28.2493 48.1665H45.7494C47.3602 48.1665 48.666 46.8606 48.666 45.2498V37.9582C48.666 37.1527 49.3189 36.4998 50.1244 36.4998C50.9298 36.4998 51.5827 37.1527 51.5827 37.9582V45.2498C51.5827 48.4715 48.971 51.0832 45.7494 51.0832H28.2493C25.0277 51.0832 22.416 48.4715 22.416 45.2498C22.416 40.8748 22.416 38.4443 22.416 37.9582Z"
                            fill="#9194B3" />
                          <path opacity="0.3"
                            d="M38.4577 23.3751C38.4577 22.5697 37.8048 21.9168 36.9993 21.9168C36.1939 21.9168 35.541 22.5697 35.541 23.3751V40.8751C35.541 41.6806 36.1939 42.3335 36.9993 42.3335C37.8048 42.3335 38.4577 41.6806 38.4577 40.8751V23.3751Z"
                            fill="#9194B3" />
                          <path
                            d="M37.0528 23.9257L30.7395 30.239C30.17 30.8085 29.2467 30.8085 28.6771 30.239C28.1076 29.6695 28.1076 28.7461 28.6771 28.1766L35.9688 20.885C36.5173 20.3365 37.3991 20.3133 37.9756 20.8322L45.2672 27.3947C45.8659 27.9335 45.9144 28.8556 45.3756 29.4542C44.8368 30.0529 43.9148 30.1014 43.3161 29.5626L37.0528 23.9257Z"
                            fill="#9194B3" />
                        </svg>
                      </div>
                      <form class="choose-files">
                        <div class="button-wrapper">
                          <label class="label file-upload-text" for="fileElem">Drag and Drop or Browse to upload your
                            CSV
                            file</label>
                          <input type="file" id="fileElem" multiple @change="onFileChange" accept=".csv" />
                        </div>
                      </form>
                      <div id="gallery"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div v-if="bulkLaterDate">
              <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 25px">
                <p class="buttonText" style="color: #323b4b">Select Time</p>
              </div>
              <template>
                <datetime type="datetime" v-model="bulkScheduleDate" input-class="form-control userForm
                    form-control-lg
                    inputOrange ml-2" :phrases="{ ok: 'Continue', cancel: 'Exit' }" :hour-step="1" :minute-step="1"
                  :week-start="7" use12-hour auto></datetime>
              </template>
            </div>
            <div class="col-md-4 col-12 mb-4 mb-sm-0"></div>
            <div class="row mt-5 repaddmobile">
              <div class="col-12 col-md-6 mb-2 pb-1" style="padding-right: 50px" v-for="(file, index) in files"
                :key="index">
                <div class="form-outline fileInnerBox d-flex">
                  <div class="fileIcon">
                    <img src="resources/assets/file_icon.svg" />
                  </div>
                  <div class="text-start" style="width: 80%">
                    <a class="fileTitle"> {{ file.name }}</a>
                    <br />
                    <a class="fileSize"> {{ file.size }} </a>
                  </div>

                  <div class="fileCrossIcon">
                    <a><img src="resources/assets/crossicon.png" @click="removeFile(file, index)" /></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5" v-if="activeButton">
              <div class="col-12 col-md-6 mb-2 pb-1">
                <div class="center-item">
                  <button type="button" class="scheduleButton" @click="bulkSendNow('later')">
                    <p class="buttonText" style="background: white; color: #00053c">
                      Send Later
                    </p>
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-2 pb-1">
                <button type="button" class="sendNowButton" @click="bulkSendNow('now')">
                  <p class="buttonText">Send Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" v-if="customerDetails">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-view">
            <div>
              <form>
                <div class="d-flex justify-content-between align-items-center mb-5">
                  <h2>Request Review Details</h2>
                  <div class="d-flex">
                    <a v-if="!customerRequestEdit" v-b-tooltip.hover @click="editRequest()" style="cursor: pointer"
                      title="Edit"><img src="resources/assets/editIcon.png" /></a>
                    <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                  </div>
                </div>

                <h1></h1>
                <form class="d-block">
                  <div class="row align-items-center">
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                      <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                          <a class="profileFormTitle"> Customer Name</a>
                          <br />
                          <a class="profileFormTitleValue">
                            {{
                                `${currentReqestReview.first_name} ${currentReqestReview.last_name
                                  ? currentReqestReview.last_name
                                  : ""
                                }`
                            }}</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                      <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                          <a class="profileFormTitle"> Email</a>
                          <br />
                          <a v-if="customerRequestEdit" class="profileFormTitleValue">
                            <input type="email" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Customer Email Address" v-model="currentReqestReview.email"
                              v-validate="'required'" v-bind:name="'feedbackViewData.email'"
                              v-bind:ref="'feedbackViewData.email'" :class="{
                                'is-invalid': errors.has(
                                  'feedbackViewData.email'
                                ),
                              }" /></a>
                          <a v-else class="profileFormTitleValue">
                            {{
                                currentReqestReview.email
                                  ? currentReqestReview.email
                                  : "NA"
                            }}</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                      <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                          <a class="profileFormTitle"> Phone</a>
                          <br />
                          <a v-if="customerRequestEdit" class="profileFormTitleValue">
                            <input type="email" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Customer Email Address" v-model="currentReqestReview.phone"
                              v-validate="'required'" v-bind:name="'feedbackViewData.phone'"
                              v-bind:ref="'feedbackViewData.phone'" :class="{
                                'is-invalid': errors.has(
                                  'feedbackViewData.phone'
                                ),
                              }" /></a>
                          <a v-else class="profileFormTitleValue">
                            {{
                                currentReqestReview.phone
                                  ? currentReqestReview.phone
                                  : "NA"
                            }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                      <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                          <a class="profileFormTitle"> Country Code</a>
                          <br />
                          <a v-if="customerRequestEdit" class="profileFormTitleValue">
                            <select class="
                                viewReviewSecondPartdropdownText
                                inputOrange
                                assignFollowUp
                                form-select
                              " v-model="requestCode" v-validate="'required'" v-bind:name="'requestData.code'"
                              v-bind:ref="'requestData.code'" :class="{
                                'is-invalid': errors.has('requestData.code'),
                              }">
                              <option class="viewReviewSecondPartdropdownText" value="">
                                Select Country
                              </option>
                              <option class="viewReviewSecondPartdropdownText" :value="location.name"
                                v-for="location in countryCodelist" :key="location.id">
                                {{ `${location.name} (+${location.code})` }}
                              </option>
                            </select></a>
                          <a v-else class="profileFormTitleValue">
                            {{
                                requestCountryCode ? requestCountryCode : "NA"
                            }}</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                      <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                          <a class="profileFormTitle"> Status</a>
                          <br />
                          <a v-if="currentReqestReview.scheduled" class="profileFormTitleValue">
                            {{ currentReqestReview.scheduled == 1 ? "Scheduled" : "" }}</a>

                          <a v-else-if="currentReqestReview.ReviewSubmitted" class="profileFormTitleValue">
                            {{ currentReqestReview.ReviewSubmitted == 1 ? "Review Submitted" : "" }}</a>

                          <a v-else-if="currentReqestReview.ReviewClicks" class="profileFormTitleValue">
                            {{ currentReqestReview.ReviewClicks == 1 ? "Clicked" : "" }}</a>

                          <a v-else-if="currentReqestReview.opened" class="profileFormTitleValue">
                            {{ currentReqestReview.opened == 1 ? "Opened" : "" }}</a>

                          <a v-else-if="currentReqestReview.failed" class="profileFormTitleValue">
                            {{ currentReqestReview.failed == 1 ? "Failed" : "" }}</a>

                          <a v-else-if="currentReqestReview.MAIL" class="profileFormTitleValue">
                            {{ currentReqestReview.MAIL == 1 ? "Sent" : "" }}</a>
                          <a v-else-if="currentReqestReview.SMS" class="profileFormTitleValue">
                            {{ currentReqestReview.SMS == 1 ? "Sent" : "" }}</a>
                          <!-- <a class="profileFormTitleValue">
                            {{
                            currentReqestReview.is_status ? "Sent" : "NA"
                            }}</a> -->

                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                      <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                          <a class="profileFormTitle"> User</a>
                          <br />
                          <a class="profileFormTitleValue">
                            {{
                                currentReqestReview.userName
                                  ? currentReqestReview.userName
                                  : "NA"
                            }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                      <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                          <a class="profileFormTitle"> Sent By</a>
                          <br />
                          <a class="profileFormTitleValue">
                            {{
                                `${currentReqestReview.MAIL &&
                                  currentReqestReview.SMS
                                  ? "Both"
                                  : currentReqestReview.MAIL
                                    ? "MAIL"
                                    : currentReqestReview.SMS
                                      ? "SMS"
                                      : "NA"
                                }`
                            }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a v-if="customerRequestEdit" class="d-flex justify-content-end">
                    <button class="saveButton" @click.prevent="updateRequest()" style="width: 180px; margin-top: 15px">
                      Save
                    </button>
                  </a>
                </form>
              </form>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </main>
</div>
</template>
<script lang="javascript" src="dist/xlsx.full.min.js"></script>
<script>
import ActionBar from "./ActionBar";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import Datepicker from "vuejs-datepicker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import * as XLSX from "xlsx";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  name: "RequestReview",
  props: {},
  components: {
    ActionBar,
    DateRangePicker,
    Datepicker,
    Datetime,
  },
  data() {
    return {
      moment: moment,
      filterOptions: ["platform", "By User", "By Ranking", "Select Data Range"],
      bulkRequestModel: false,
      scheduleModel: false,
      createModel: false,
      requestData: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        userID: "",
        SMS: false,
        MAIL: false,
        both: false,
        shortName: "US",
      },
      requestListing: [],
      selectedReviews: [],
      currentReqestReview: [],
      files: [],
      activeButton: false,
      requestReviewCurrentPage: 1,
      requestReviewPerPage: 20,
      requestReviewCount: 0,
      isActiveSMS: false,
      isActiveEmail: false,
      isActiveBoth: false,
      sendValue: "",
      cust_range: ["01/09/2018", "01/10/2018", false],
      cust_range_options: {
        timePicker: false,
        autoUpdateInput: true,
        startDate: moment(),
        endDate: moment(),
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      autoApply: false,
      scheduleDate: "",
      countryCodelist: [],
      requestCode: "",
      disabledDates: {
        to: new Date(),
      },
      bulkLaterDate: false,
      csvLink: "",
      bulkScheduleDate: "",
      customerDetails: false,
      customerRequestEdit: false,
      apiLoading:false,
    };
  },
  mounted() {
    this.requestData.userID = localStorage.getItem("id");
    this.requestReviewListing();
    this.countryCode();
  },
  created() {},
  computed: {},
  methods: {
    downloadCsv() {
      this.$store.dispatch("csvFile").then((response) => {
        this.csvLink = response;
      });
    },

    customFormatter(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    changeSMSColor() {
      this.isActiveSMS = !this.isActiveSMS;
    },
    changeEmailColor() {
      this.isActiveEmail = !this.isActiveEmail;
    },
    changeBothColor() {
      this.isActiveBoth = !this.isActiveBoth;
    },
    cancel() {
      this.bulkRequestModel = false;
      this.scheduleModel = false;
      this.customerDetails = false;
      this.createModel = false;
    },
    openSingleModal(){
      this.clearSingleRequest();
      this.createModel = true;
    },
    openBulkModel() {
      let data = {
        userID: localStorage.getItem("id"),
      };
      this.apiLoading = true;
      this.$store.dispatch("requestReviewValidate", data).then((response) => {
        if (response.success) {
          this.files = [];
          this.bulkScheduleDate = "";
          this.bulkLaterDate = false;
          this.activeButton = false;
          this.bulkRequestModel = true;
        } else {
          response.data.forEach((element) => {
            if (element) {
              this.$toastr.e(`${element}`);
            }
          });
        }
      }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    async openscheduleModel() {
      let result = await this.$validator.validateAll();
      if (result) {
        if (
          this.requestData.SMS == "" &&
          this.requestData.MAIL == "" &&
          this.requestData.both == ""
        ) {
          this.$toastr.e("Please select atleast one option for contact.");
        } else {
          this.scheduleModel = true;
        }
      }
    },
    countryCode() {
      this.$store.dispatch("requestCountryCode").then((response) => {
        if (response.success) {
          this.countryCodelist = response.data;
        }
      });
    },

    scheduleSendNow() {
      let countryName = this.countryCodelist.find(
        (element) => element.shortName == this.requestData.shortName
      );

      let data = {
        first_name: this.requestData.first_name.trim(),
        last_name: this.requestData.last_name
          ? this.requestData.last_name.trim()
          : "",
        phone: this.requestData.phone,
        email: this.requestData.email,
        userID: this.requestData.userID,
        SMS: this.requestData.both ? 0 : this.requestData.SMS ? 0 : "",
        MAIL: this.requestData.both ? 0 : this.requestData.MAIL ? 0 : "",
        item: this.scheduleDate ? "later" : "now",
        date_string: this.scheduleDate ? this.scheduleDate : "",
        code: countryName ? "+" + countryName.code : "+1",
        countryName: countryName ? countryName.name : "UNITED STATES",
      };
      this.apiLoading = true;
      this.$store.dispatch("singleRequest", data).then((response) => {
        if (response.success) {
          this.$toastr.s(`${response.message}`);
          this.clearSingleRequest();
          this.requestReviewListing();
          this.createModel = false;
          this.scheduleModel = false;
        } else {
          if (response.message == "Validation Error.") {
            this.$toastr.e(`${response.data.email}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        }
      }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    async singleRequest() {
      let result = await this.$validator.validateAll();
      if (result) {
        let countryName = this.countryCodelist.find(
          (element) => element.shortName == this.requestData.shortName
        );

        if (
          this.requestData.SMS == "" &&
          this.requestData.MAIL == "" &&
          this.requestData.both == ""
        ) {
          this.$toastr.e("Please select atleast one option for contact.");
        } else {
          let data = {
            first_name: this.requestData.first_name.trim(),
            last_name: this.requestData.last_name
              ? this.requestData.last_name.trim()
              : "",
            phone: this.requestData.phone,
            email: this.requestData.email,
            userID: this.requestData.userID,
            SMS: this.requestData.both ? 0 : this.requestData.SMS ? 0 : "",
            MAIL: this.requestData.both ? 0 : this.requestData.MAIL ? 0 : "",
            item: "now",
            code: countryName ? "+" + countryName.code : "+1",
            countryName: countryName ? countryName.name : "UNITED STATES",
          };
          this.apiLoading = true;
          this.$store.dispatch("singleRequest", data).then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.clearSingleRequest();
              this.requestReviewListing();
              this.createModel = false;
            } else {
              if (response.message == "Validation Error.") {
                this.$toastr.e(`${response.data.email}`);
              } else {
                this.$toastr.e(`${response.message}`);
              }
            }
          }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
        }
      }
    },
    requestReviewListing() {
      let data = {
        page: this.requestReviewCurrentPage ? this.requestReviewCurrentPage : 1,
        userID: localStorage.getItem("id"),
      };

      this.$store.dispatch("requestReviewListing", data).then((response) => {
        this.requestListing = response.data.data;
        this.requestReviewCount = response.data.total;
      });
    },
    /**
     * @desc: Show Page
     * @param: page
     * @return: None
     * */
    showPage(page) {
      this.requestReviewCurrentPage = page;
      this.requestReviewListing();
    },
    deleteSingleRequst(id) {
      this.$store.dispatch("deleteSingleRequst", id).then((response) => {
        if (response.success) {
          this.requestReviewListing();
          this.$toastr.s(`${response.message}`);
        } else {
          this.$toastr.e("something went wrong, Please try again later");
        }
      });
    },
    viewSingleRequst(id) {
      this.currentReqestReview = this.requestListing.find((x) => x.id === id);
      let countryData = this.countryCodelist.find(
        (element) => element.name == this.currentReqestReview.countryName
      );
      this.requestCode =
        countryData && countryData.name ? countryData.name : "";
      this.requestCountryCode =
        countryData && countryData.name && countryData.code
          ? `${countryData.name} (+${countryData.code}) `
          : "";
      this.customerRequestEdit = false;
      this.customerDetails = true;
    },
    pushSelectedReviews(e, id) {
      if (e.target.checked) {
        this.selectedReviews.push(id);
      } else {
        const index = this.selectedReviews.indexOf(id);
        if (index > -1) {
          this.selectedReviews.splice(index, 1);
        }
      }
    },
    pushAllSelectedReviews(e) {
      if (e.target.checked) {
        this.selectedReviews = this.requestListing.map((x) => x.id);
        this.selectedReviews.forEach((element) => {
          document.getElementById(element).checked = true;
        });
      } else {
        this.selectedReviews.forEach((element) => {
          document.getElementById(element).checked = false;
        });
        this.selectedReviews = [];
      }
    },
    updateList() {
      this.requestReviewListing();
    },
    clearSingleRequest() {
      this.requestData.first_name = "";
      this.requestData.last_name = "";
      this.requestData.phone = "";
      this.requestData.email = "";
      this.requestData.SMS = false;
      this.requestData.MAIL = false;
      this.requestData.both = false;
      this.requestData.shortName = "US";
      this.isActiveSMS= false;
      this.isActiveEmail= false;
      this.isActiveBoth= false;
      this.$nextTick(() => {
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      });
    },

    onFileChange(event) {
      let data = event.target.files ? event.target.files : null;
      data.forEach((e) => {
        let fileName = e.name;
        let fileExtension = fileName.split(".").pop();
        if (fileExtension == "csv" && e) {
          const reader = new FileReader();
          reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {
              type: "binary",
              cellDates: true,
              dateNF: "yyyy/mm/dd;@",
            });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {
              raw: true,
              defval: null,
            });
            data.forEach((e, index) => {
              const entries = Object.entries(e);
              const nonEmptyOrNull = entries.filter(
                ([key, val]) => val !== "" && val !== null
              );
              if (nonEmptyOrNull.length != 9) {
                this.$toastr.e(
                  `Invalid data in row ${index + 1} of file ${fileName} `
                );
              }
            });
          };
          reader.readAsBinaryString(e);
          this.files.push(e);
        }
      });
      if (this.files) {
        this.activeButton = true;
      }
    },

    bulkSendLater() {
      this.bulkLaterDate = true;
    },
    bulkSendNow(item) {
      if (item == "later" && this.bulkLaterDate != true) {
        this.bulkLaterDate = true;
        return;
      }

      let formData = new FormData();
      let dateString = this.bulkScheduleDate
        ? moment.utc(this.bulkScheduleDate).format()
        : "";

      formData.append("userID", localStorage.getItem("id"));
      for (let i = 0; i < this.files.length; i++) {
        formData.append("file[" + i + "]", this.files[i]);
      }
      formData.append("item", item);
      formData.append("date_string", dateString);
      this.apiLoading = true;
      this.$store.dispatch("sendBuldRequst", formData).then((response) => {
        if (response.success) {
          this.$toastr.s(`${response.message}`);
          this.bulkRequestModel = false;
          this.requestReviewListing();
        } else {
          this.$toastr.e(`${response.message}`);
        }
      }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    removeFile(data, index) {
      this.files.splice(index, 1);
      if (this.files.length == 0) {
        this.activeButton = false;
      }
    },
    editRequest() {
      this.customerRequestEdit = true;
    },
    updateRequest() {
      let countryName = this.countryCodelist.find(
        (element) => element.name == this.requestCode
      );

      let data = {
        id: this.currentReqestReview.id,
        userID: localStorage.getItem("id"),
        email: this.currentReqestReview.email,
        phone: this.currentReqestReview.phone,
        code: countryName ? "+" + countryName.code : "+1",
        countryName: countryName ? countryName.name : "UNITED STATES",
      };

      this.$store
        .dispatch("requestUpdate", data)
        .then((response) => {
          if (response.success) {
            this.customerRequestEdit = false;
            this.requestCountryCode =
              countryName && countryName.name && countryName.code
                ? `${countryName.name} (+${countryName.code}) `
                : "";
            this.requestReviewListing();
            this.$toastr.s(`${response.message}`);
          } else {
            if (response.data.email) {
              this.$toastr.e(response.data.email);
            }
            if (response.data.phone) {
              this.$toastr.e(response.data.phone);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.userForm {
  height: 58px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}

.formPTag {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.checkLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #9194b3;
  padding: 0px 5px;
}

.requestFrom {
  background-color: white;
  margin: 10px 10px;
  border-radius: 20px;
}

.newRequestButton {
  font-family: "Inter", sans-serif;
  background: #00053c;
  color: white;
  font-size: 18px;
  border-radius: 15px;
  width: 100%;
  height: 50px;
  font-weight: 600;
}

.bulkRequestButton {
  font-family: "Inter", sans-serif;
  background: #38cb89;
  color: white;
  font-size: 18px;
  border-radius: 15px;
  width: 100%;
  height: 50px;
  font-weight: 600;
}

.requestButton {
  /* padding: 200px; */
  display: flex;
  justify-content: space-around;
}

.buttonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border-radius: 15px;
  padding: 0px 25px;
  margin-top: 10px;
  display: contents;
}

.sendButtons {
  display: flex;
  justify-content: space-around;
}

.sendNowButton {
  font-family: "Inter", sans-serif;
  background: #00053c;
  color: white;
  font-size: 18px;
  border-radius: 15px;
  height: 50px;
  width: 100%;
  font-weight: 600;
}

.scheduleButton {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 100%;
  height: 50px;
  font-weight: 600;
}

.formtable {
  text-align: center;
  border-collapse: inherit;
}

.table>tbody>tr>td {
  padding: 30px 15px;
}

.heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00053c !important;
}

.text-heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  text-transform: capitalize;
  color: #00053c;
  white-space: initial;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
}

.modal-container {
  width: 600px;
  /* height: 347px;
  margin-left: 600px;
  margin-left: 500px;
  margin-right: 500px;
  margin-top: 100px;
  margin-bottom: 250px; */
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container-view {
  width: 800px;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-schedule {
  width: 600px;
  /* height: 300px;
  margin-left: 600px;
  margin-right: 500px;
  margin-top: 100px;
  margin-bottom: 100px; */
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 40px; */
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button-wrapper.btn:hover {
  background-color: #4e5056;
}

.button-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.choose-files {
  align-self: flex-end;
  z-index: 10;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  color: #f4f5fa;
  background-color: white;
  border: 3px solid black;
  width: 100px;
  height: 100px;
}

.circle {
  transition: transform 150ms ease-in;
  z-index: 10;
}

svg {
  width: 40px;
  height: 40px;
}

.circle:before {
  content: "";
  background-color: #f4f5fa;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  transition: transform 250ms ease-in, opacity 200ms ease-in;
  z-index: 0;
}

.circle:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.circle:hover {
  transform: scale(1.2);
  opacity: 0.9;
}

.circle:before {
  transform: scale(8);
  opacity: 1;
}

.circle:after {
  border: 3px solid white;
}

svg {
  color: white;
  z-index: 1;
}

.drop-it-hot {
  /* margin: 34px 0px; */
  /* background-color: #41162a; */
  border-radius: 15px;
  /* height: 518px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px 0 rgba(black, 0.1);
  overflow: hidden;
  width: 500px;
  height: 219px;
  color: #f4f5fa;
  border: 1px dashed rgba(145, 148, 179, 0.5);
  box-sizing: border-box;
}

.drop-it-hot:after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 2px dashed #e6e7f0;
  border-radius: 5px;
  z-index: 0;
}

.label {
  margin-bottom: 20px;
  color: #4e5056;
  font-size: 18px;
}

.view-button {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 25%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
  padding: 10px;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: none;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.file-ui {
  font-family: "Inter", sans-serif;
  color: white;
  font-size: 18px;
  border-radius: 15px;
  height: 51px;
  font-weight: 600;
}

.fileInnerBox {
  width: 250px;
  height: 58px;
  left: 455px;
  top: 546px;

  background: rgba(56, 203, 137, 0.1);
  border-radius: 15px;
  padding: 10px;
}

.fileIcon {
  width: 44px;
  height: 43px;
  left: 463px;
  top: 554px;

  background: rgba(56, 203, 137, 0.2);
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  padding: 7px 0px 0px 5px;
}

.fileSize {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: #9194b3;
}

.fileTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: #00053c;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
  display: inline-block;
}

.fileCrossIcon {
  width: 24px;
  height: 24px;
  left: 664px;
  top: 563px;
}

::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;

  color: #9194b3;
}

.active {
  color: #00053c;
}

.table-align {
  border-radius: 15px;
  padding: 15px;
  margin: 10px;
}

.header th {
  height: 10px;
}

table th:first-child {
  border-radius: 15px 0 0 15px;
}

table th:last-child {
  border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
  border-top: none;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 8px;
  padding-left: 6px;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
}

table th {
  background-color: #f4f5fa !important;
  height: 51px !important;
  padding: 15px !important;
  text-align: center !important;
}

.bulk-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #323b4b;
}

.file-upload-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  text-align: center;

  color: #9194b3;
}

.viewReviewSecondPartdropdownText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  /* font-size: 16px !important; */
  line-height: 29px;
  display: flex;
  align-items: center;
  /* color: #9194b3; */
}

.assignFollowUp {
  height: 50px;
  left: 889px;
  top: 413px;
  /* background: #ffffff; */
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
}

.intl-tel-input {
  display: table-cell;
}

.intl-tel-input .selected-flag {
  z-index: 4;
}

.intl-tel-input .country-list {
  z-index: 5;
}

.input-group .intl-tel-input .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}

.containerHeight {
  height: auto;
}

.viewReviewInnerBox {
  background-color: white;
  margin-bottom: 20px;
}

.profileFormTitle {
  text-decoration-line: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9194b3 !important;
}

.profileFormTitleValue {
  text-decoration-line: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* font-size: 12px; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c !important;
}

.row {
  margin-right: 0px;
}

/* style start */
.card-header h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px;
  color: #00053c;
}

.table-outer-wrap .pagination button,
.table-outer-wrap .pagination span {
  margin: 0 3px;
}

table td h1 {
  font-size: 16px;
}

.saveButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  background: #00053c;
  color: #ffffff;
  width: 180px;
  height: 51px;
  border-radius: 15px;
}

.outer-circle-new {
  background: rgba(137, 176, 240, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin: 0;
}

.outer-circle-pending {
  background: rgba(255, 171, 0, 0.12);
  border-radius: 15px;
  padding: 10px;
}

.outer-circle-resolved {
  background: #e5f7ef;
  border-radius: 15px;
  padding: 10px;
}

.inner-new {
  color: #89b0f0;
}

.inner-pending {
  color: #ffab00;
}

.inner-resolved {
  color: #38cb89;
}

@media (min-width: 1400px) {
  .modal-container {
    max-width: 1000px;
    /* width: 100% !important; */
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .modal-container.small-wdth {
    width: auto;
  }
}

@media (max-width: 1599.98px) {
  .table>tbody>tr>td {
    padding: 10px;
  }

  table .btn {
    padding: 0 5px !important;
  }
}

@media (max-width: 1199.98px) {
  .scheduleButton .sendNowButton {
    font-size: 14px !important;
  }

  .bulk-title {
    font-size: 28x;
  }
}

@media (max-width: 991.98px) {
  .modal-container-view {
    top: 0;
    transform: translate(-50%, 50%);
    width: 90%;
  }
}

@media (max-width: 767.98px) {
  .bulkRequestButton {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .scheduleButton .sendNowButton {
    margin-bottom: 10px;
  }

  header.bg-surface-primary {
    margin: 0 !important;
  }

  .requestFrom {
    margin: 0 0px 0 4px;
  }

  .requestFrom .col-6 {
    width: 100%;
    padding: 0 !important;
  }

  .requestFrom>.formPTag {
    padding: 15px 0 0 !important;
    display: block;
    text-align: center;
  }

  div#userForDiv {
    margin: 0;
  }

  .table-responsive {
    border: 0;
  }

  .table-responsive .table th {
    border: 0;
  }

  .modal-wrapper a {
    cursor: pointer;
  }

  .modal-container-schedule,
  .modal-container {
    width: 90%;
  }

  .bulk-title {
    font-size: 24px;
  }

  .drop-it-hot {
    width: auto;
  }

  .file-upload-text {
    white-space: unset;
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 1.3;
  }

  .repaddmobile .col-12 {
    padding-right: 0 !important;
  }

  .repaddmobile .fileInnerBox {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .bulk-title {
    font-size: 20px;
  }
}
</style>