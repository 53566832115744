/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
// import Vue from "vue";

import router from "../js/Routers";
import VueToastr from "vue-toastr";
import { store } from "../js/Store/index";
// import  store  from "../js/Store/new-index";
import VueSession from "vue-session";
import * as VeeValidate from 'vee-validate';
import VueAxios from 'vue-axios';
import Vue from "vue";
import Vue2Editor from "vue2-editor";
import { BootstrapVue } from "bootstrap-vue";
import common from "./Common/config";
import GAuth from 'vue-google-oauth2';
import VueTour from 'vue-tour';
import { StripePlugin } from '@vue-stripe/vue-stripe';
require('vue-tour/dist/vue-tour.css');
require('./bootstrap');
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

window.Vue = require('vue').default;
Vue.use(VueToastr, {
  defaultStyle: { "font-size": "15px" },
});
Vue.use(VueSession);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields"
});

Vue.use(GAuth, {
  clientId: common.googleClientId,
  scope: 'profile email',
  prompt: 'select_account',
  plugin_name: common.googlePluginName
})
const options = {
  pk: common.STRIPE_PUBLISHABLE_KEY,
  stripeAccount: common.STRIPE_ACCOUNT,
  apiVersion: common.API_VERSION,
  locale: common.LOCALE,
};
Vue.use(Vue2Editor);
Vue.use(VueAxios, axios);
Vue.use(VueTour);

Vue.use(StripePlugin, options);// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.component('app', require('./components/App.vue').default);

const app = new Vue({
  el: "#app",
  router,
  store
});
