<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="action-btns">
        <div class="d-flex">
          <div class="dropdown mr-4">
            <button class="btn drop-btn dropdown-toggle" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              Action
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="drop-btn2">
                <a class="dropdown-item" href="#" @click="deleteMultipleUsers">Delete Selected</a>
              </li>
            </ul>
          </div>
          <div class="action_btn-platform dropdown mr-4" v-if="showRefresh && actionType === 'reviews'">
            <button @click="syncPlatform()" name="submit" class="action_btn-platform action_btn_color__disconnected"
              type="button">
              <p style="width: 100px" class="loginButtonText">Refresh</p>
            </button>
          </div>
        </div>
      </div>

      <div class="dropdown">
        <date-range-picker v-model="cust_range" :options="cust_range_options" :autoApply="autoApply" class="ml-2"
          v-if="datePicker" @click="selectFilter(cust_range, 'Select Data Range')" :ranges="false" opens="left" />
        <ul id="menu">
          <li class="parent">
            <img src="resources/assets/filtersIcon.png" class="btn drop-btn filter-icon" id="dropdownMenuButton"
              alt="" />
            <ul class="child">
              <li v-if="dateStyle">Filter</li>

              <li class="parent" v-for="(filter, index) in filterOptions[0]" v-bind:key="index">
                <a @click="selectFilter(data, filter.title)" tabindex="-1" href="#" v-if="filter.title == 'By User'">{{
                filter.title }}</a>
                <a class="" tabindex="-1" href="#" v-else>{{ filter.title }} <span class="expand">»</span></a>
                <ul class="child">
                  <li class="dropdown-item" v-for="(data, index) in filter.data" v-bind:key="index"
                    @click="selectFilter(data, filter.title)">
                    <a tabindex="-1" href="#">{{ data }}</a>
                  </li>
                </ul>
              </li>
              <li v-if="dateStyle">
                <a tabindex="-1" href="#" @click="showPicker('picker')">Select Date Range</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "ActionBar",
  props: {
    selectedUsers: {
      type: Array,
      default: null,
    },
    filterOptions: {
      type: Array,
      default: null,
    },
    actionType: {
      type: String,
      default: null,
    },
    dateStyle: {
      type: Boolean,
      default: false,
    },
    showRefresh: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DateRangePicker,
  },
  watch: {
    cust_range() {
      this.selectFilter(this.cust_range, "Select Data Range");
    },
  },
  data() {
    return {
      cust_range: ["01/09/2018", "01/10/2018", false],
      cust_range_options: {
        timePicker: false,
        autoUpdateInput: true,
        startDate: moment(),
        endDate: moment(),
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      autoApply: false,
      showDropdowns: true,
      minDate: "01/09/2018",
      maxDate: "01/09/2028",
      dateRange: {},
      dateFormat: "DD/MM/YYYY",
      disabledDates: {
        to: new Date(),
      },
      userLoginId: "",
      filterdList: [],
      filterData: [],
      datePicker: false,
      // showRefresh: true,
    };
  },
  mounted() {
    this.userLoginId = localStorage.getItem("id");
  },
  methods: {
    ...mapActions({
      deleteMultipleUser: "users/deleteMultipleUser",
    }),

    deleteMultipleUsers() {
      const selectedUsersString = this.selectedUsers.toString();
      let module = this.actionType;
      if (this.actionType === "users") {
        // await this.deleteMultipleUser(selectedUsersString)
        this.$store
          .dispatch("deleteMultipleUser", selectedUsersString)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.$emit("updateList", {
                actionType: module,
              });
            } else {
              this.$toastr.e(`${response.message}`);
            }
          });
      }

      if (this.actionType === "reviews") {
        // await this.deleteMultipleReview(selectedUsersString)
        this.$store
          .dispatch("deleteMultipleReview", selectedUsersString)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.$emit("updateList", {
                actionType: module,
              });
            } else {
              this.$toastr.e(`${response.message}`);
            }
          });
      }

      if (this.actionType === "feedback") {
        // await this.deleteMultipleFeedback(selectedUsersString)
        this.$store
          .dispatch("deleteMultipleFeedback", selectedUsersString)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.$emit("updateList", {
                actionType: module,
              });
            } else {
              this.$toastr.e(`${response.message}`);
            }
          });
      }

      if (this.actionType === "request_reviews") {
        this.$store
          .dispatch("deleteMultipleRequest", selectedUsersString)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.$emit("updateListing", "update");
            } else {
              this.$toastr.e(`${response.message}`);
            }
          });
      }
    },

    selectFilter(data, title) {
      this.$emit("changeTitle", {
        data,
        title,
        actionType: this.actionType,
      });
      this.datePicker = false;
    },
    showPicker(title) {
      title == "picker" ? (this.datePicker = true) : (this.datePicker = false);
    },
    openMenu(e) {
      e.stopPropagation();
      $(e.target).next("ul").toggle();
    },
    syncPlatform() {
      this.$emit("callSync", true);
    },
  },
};
</script>
<style scoped>
.drop-btn {
  font-family: "Roboto", sans-serif;
  width: 114px;
  height: 43px;
  background-color: #f4f5fa;
  margin: 0 0 20px 0;
  border-radius: 15px;
  color: #00053c;
  font-size: 14px;
  font-weight: 500;
}

.drop-btn2 {
  font-family: "Roboto", sans-serif;
  /* background-color: #F4F5FA;    */
  color: #00053c;
  font-size: 14px;
  font-weight: 500;
}

.filter-icon {
  /* position: absolute; */
  width: 63px;
  height: 43px;
  left: 1282px;
  top: 248px;
}

.filter-list {
  /* position: absolute; */
  width: 203px;
  height: auto;
  left: 1068px;
  top: 248px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  /* border-radius: 10px 0px 10px 10px; */
  border-radius: 10px;
}

.filter-menu {
  /* width: 57px; */
  /* left: 1093px; */
  /* top: 323px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 17px; */
  align-items: center;
  color: #00053c;
}

.icon-down {
  /* position: absolute; */
  width: 8.5px;
  height: 4.25px;
  left: 1241px;
  top: 335.75px;

  transform: rotate(-90deg);
}

.filter-name {
  /* position: absolute; */
  width: 46px;
  height: 22px;
  left: 1093px;
  top: 271px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #9194b3;
  margin: 15px 30px;
}

.filter-hr {
  /* position: absolute; */
  width: 153px;
  height: 1px;
  left: 1093px;
  top: 307px;
  background: #f4f5fa;
}

.viewReviewSecondPartdropdownText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  /* font-size: 16px !important; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
  padding: 10px;
}

.assignFollowUp {
  height: 50px;
  left: 889px;
  top: 413px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  /* margin: 10px; */
  /* padding: 10px; */
  justify-content: flex-end;
}

.filter-bg-color {
  background-color: #f4f5fa;
  border-radius: 15px;
  padding: 10px;
  margin: 5px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  right: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
  /* display: none; */
  position: absolute;
  right: 0%;
  top: -7px;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-left: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  right: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.parent {
  display: block;
  position: relative;
  float: left;
  line-height: 30px;
  /* background-color: #4fa0d8; */
  /* border-right: #ccc 1px solid; */
}

.parent a {
  margin: 10px;
  color: #ffffff;
  text-decoration: none;
}

.parent:hover>ul {
  display: block;
  position: absolute;
}

.child {
  display: none;
}

.child li {
  background-color: #e4eff7;
  line-height: 30px;
  border-bottom: #ccc 1px solid;
  border-right: #ccc 1px solid;
  width: 100%;
}

.child li a {
  color: #000000;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0px;
  min-width: 10em;
}

ul ul ul {
  left: 100%;
  top: 0;
  margin-left: 1px;
}

/* li:hover {
  background-color: #95b4ca;
} */
.parent li:hover {
  background-color: #f0f0f0;
}

.expand {
  font-size: 12px;
  float: right;
  margin-right: 5px;
}

/* filter dropdown style */
#menu .parent ul.child {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px 0px 10px 10px;
  top: 10px;
  right: 50px;
  width: 200px;
}

#menu .parent ul.child[data-v-6c90b5a2] .parent ul.child {
  right: 150px;
}

#menu .parent ul.child li {
  background: transparent;
  border: 0;
  padding: 5px 20px;
  position: relative;
}

#menu .parent ul.child li a,
.parent ul.child li {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #00053c;
  margin: 0;
  display: inline-block;
  width: 100%;
}

#menu .parent ul.child li:first-child {
  padding-top: 15px;
}

#menu .parent ul.child li:last-child {
  padding-bottom: 15px;
}

#menu .parent ul.child .child {
  top: 0;
  left: auto;
  min-width: 200px;
  width: 200px;
  z-index: 1;
}

#menu .parent ul.child li::after {
  position: absolute;
  content: "";
  background: #f4f5fa;
  width: 80%;
  height: 1px;
  left: 20px;
  bottom: 0;
}

/* style ends */

.action_btn-platform {
  display: inline;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  height: 40px;
  border-radius: 10px;
  background: #ff8c00;
}

.loginButtonText {
  margin: auto;
}
</style>
