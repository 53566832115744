export default {
   api: process.env.MIX_API_URL,
   app: process.env.MIX_APP_URL,
   googleClientId: process.env.MIX_GOOGLE_CLIENT_ID,
   googleClientSecret: process.env.MIX_GOOGLE_CLIENT_SECRET,
   googlePluginName: process.env.MIX_GOOGLE_PLUGIN_NAME,
   googleRedirectUri: process.env.MIX_GOOGLE_REDIRECT,
   FBClientId:  process.env.MIX_FACEBOOK_ID,
   FBClientSecret:  process.env.MIX_FACEBOOK_SECRET,
   FBRedirectUri:  process.env.MIX_FACEBOOK_URL,
   csvFile: process.env.MIX_CSV_FILE,
   STRIPE_PUBLISHABLE_KEY: process.env.MIX_STRIPE_KEY,
   STRIPE_ACCOUNT: process.env.MIX_STRIPE_ACCOUNT,
   API_VERSION: process.env.MIX_STRIPE_API_VERSION,
   LOCALE: '',
};