<template>
  <main class="py-6">
    <div>
      <div class="row align-items-center heading-wrap mb-5">
        <div class="col-6 mb-4 mb-sm-0 pl-4">
          <div class="card-header" style="background: none; border: none">
            <h2 class="mb-0">Reports</h2>
          </div>
        </div>
        <div class="
            col-6
            mb-4 mb-sm-0
            d-flex
            justify-content-end
            action-wrap
            align-items-center
            pr-4
          ">
          <div v-if="report_type != 'conversion'">
            <div class="action-btns">
              <div class="dropdown">
                <select class="
                    report-type
                    inputOrange
                    userForm
                    form-control-lg
                    report-change
                  " @change="onPlatformChange($event)" v-model="platform">
                  <option class="report-type" value="">All</option>
                  <option class="report-type" value="facebook">Facebook</option>
                  <option class="report-type" value="google">Google</option>
                  <option class="report-type" value="zillow">Zillow</option>
                  <option class="report-type" value="yelp">Yelp</option>
                </select>
              </div>
            </div>
            <div class="filter"></div>
          </div>
          <div>
            <select class="
                report-type
                inputOrange
                userForm
                form-control-lg
                report-change
              " v-model="report_type" @change="onChange($event)">
              <option class="report-type" value="">Report type</option>
              <option class="report-type" value="reviews">Reviews</option>
              <option class="report-type" value="conversion">Conversion</option>
            </select>
          </div>
          <div v-if="report_type != 'conversion'">
            <div class="action-btns">
              <div class="dropdown">
                <button class="btn drop-btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Date Range
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li class="drop-btn2">
                    <a class="dropdown-item" href="#" @click="showPicker('picker')">Range</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="filter"></div>
          </div>
          <div>
            <date-range-picker v-model="cust_range" :options="cust_range_options" :autoApply="autoApply" class="ml-2"
              @click="selectFilter(cust_range)" :ranges="false" opens="left" v-if="datePicker" />
          </div>
        </div>
      </div>
      <div v-if="report_type == 'reviews'">
        <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
          :paginate-elements-by-height="1400" filename="review-report" :pdf-quality="2" :manual-pagination="false"
          pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" @progress="onProgress($event)"
          ref="html2Pdf">
          <section slot="pdf-content">
            <div class="row">
              <div class="col-md-2 mb-4 reportStatistics">
                <h2 class="cardTitle">Reviews</h2>
                <div class="inner-head-wrap">
                  <h1 class="data-h1">
                    {{ cardReview }}
                  </h1>

                  <p v-if="percentageGrowth > 0" class="data-per data-plus">
                    {{ `+ ${percentageGrowth}%` }}&nbsp;
                    <img src="/resources/assets/arrow-up.svg" alt="" />
                  </p>
                  <p v-else class="data-per data-minus">
                    {{ `${percentageGrowth}%` }}&nbsp;
                    <img src="/resources/assets/arrow-down.svg" alt="" />
                  </p>
                </div>
                <p class="date-color">{{ `Started: ${cardDate}` }}</p>
              </div>
              <div class="col-md-2 mb-4 reportStatistics">
                <h2 class="cardTitle">Average Ratings</h2>
                <div class="inner-head-wrap">
                  <h1 class="data-h1">
                    {{ cardAverageRating }}
                  </h1>

                  <p v-if="averageRatingGrowth > 0" class="data-per data-plus">
                    {{ `+ ${averageRatingGrowth}%` }}&nbsp;
                    <img src="/resources/assets/arrow-up.svg" alt="" />
                  </p>
                  <p v-else class="data-per data-minus">
                    {{ `${averageRatingGrowth}%` }}&nbsp;
                    <img src="/resources/assets/arrow-down.svg" alt="" />
                  </p>
                </div>
              </div>
            </div>
            <div class="row report-chart-wrap">
              <div class="col-12 mb-4 pb-2 reportGraph">
                <div class="action-btns d-flex justify-content-end">
                  <div class="dropdown dropdownMargin">
                    <select class="
                        yearSelectDropdown
                        inputOrange
                        yearSelect
                        form-select
                      " v-model="yearFilter" @change="onChangeYear($event)">
                      <option class="yearSelectDropdown" :value="year" v-for="year in years" :key="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                </div>
                <div id="chart">
                  <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>
        <div class="table-align" style="background: #f4f5fa; border-radius: 15px; padding: 15px">
          <div class="row">
            <div class="col-md-2 mb-4 reportStatistics">
              <h2 class="cardTitle">Reviews</h2>
              <div class="inner-head-wrap">
                <h1 class="data-h1">
                  {{ cardReview }}
                </h1>

                <p v-if="percentageGrowth > 0" class="data-per data-plus">
                  {{ `+ ${percentageGrowth}%` }}&nbsp;
                  <img src="/resources/assets/arrow-up.svg" alt="" />
                </p>
                <p v-else class="data-per data-minus">
                  {{ `${percentageGrowth}%` }}&nbsp;
                  <img src="/resources/assets/arrow-down.svg" alt="" />
                </p>
              </div>
              <p class="date-color">{{ `Started: ${cardDate}` }}</p>
            </div>
            <div class="col-md-2 mb-4 reportStatistics">
              <h2 class="cardTitle">Average Ratings</h2>
              <div class="inner-head-wrap">
                <h1 class="data-h1">
                  {{ cardAverageRating }}
                </h1>

                <p v-if="averageRatingGrowth > 0" class="data-per data-plus">
                  {{ `+ ${averageRatingGrowth}%` }}&nbsp;
                  <img src="/resources/assets/arrow-up.svg" alt="" />
                </p>
                <p v-else class="data-per data-minus">
                  {{ `${averageRatingGrowth}%` }}&nbsp;
                  <img src="/resources/assets/arrow-down.svg" alt="" />
                </p>
              </div>
            </div>
          </div>
          <div class="row report-chart-wrap">
            <div class="col-12 mb-4 pb-2 reportGraph">
              <div class="action-btns d-flex justify-content-end">
                <div class="dropdown dropdownMargin">
                  <select class="
                      yearSelectDropdown
                      inputOrange
                      yearSelect
                      form-select
                    " v-model="yearFilter" @change="onChangeYear($event)">
                    <option class="yearSelectDropdown" :value="year" v-for="year in years" :key="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
              <div id="chart">
                <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4 mb-4 mb-sm-0 d-flex center-item file-wrap">
          <div>
            <div class="action-btns">
              <div class="dropdown">
                <button class="btn export-ui" type="button" @click="generatePDF()">
                  <img src="resources/assets/export-pdf.svg" />&nbsp; Export to
                  PDF &nbsp;
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="action-btns">
              <div class="dropdown">
                <button class="btn export-ui" type="button" @click="downloadFile()">
                  <img src="resources/assets/export-excel.svg" />&nbsp; Export
                  to Excel &nbsp;
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="action-btns">
              <div class="dropdown">
                <a href="mailto:" target="_blank">
                  <button class="btn export-ui" type="button">
                    <img src="resources/assets/email-report.svg" />&nbsp; Email
                    &nbsp;
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="report_type == 'conversion'">
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1400" filename="review-report" :pdf-quality="2" :manual-pagination="false"
        pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" @progress="onProgress($event)"
        ref="html2Pdf">
        <section slot="pdf-content">
          <div class="custom-card-wrapper">
            <div class="custom-card">
              <div class="custom-inner">
                <h2 class="cardTitle">Delivery</h2>
                <div class="inner-card pt-0">
                  <div id="main" style="width: 350px; height: 200px">
                    <apacheChart :option="chartOptions_1" autoresize />
                  </div>
                  <ul class="status-list">
                    <li>
                      <p class="delivery-header">
                        <span class="fcircle"></span>&nbsp; Failed
                      </p>
                      <p class="delivery-data">{{ failed }}</p>
                    </li>
                    <li>
                      <p class="delivery-header">
                        <span class="dcircle"></span>&nbsp; Delivered
                      </p>
                      <p class="delivery-data">{{ delivered }}</p>
                    </li>
                    <li>
                      <p class="delivery-header">
                        <span class="scircle"></span>&nbsp;Scheduled
                      </p>
                      <p class="delivery-data">{{ scheduled }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="custom-card">
              <div class="custom-inner">
                <h2 class="cardTitle">Perfomance</h2>
                <div class="inner-card">
                  <div class="row">
                    <div class="col-md-4">
                      <p class="delivery-header">Delivered</p>
                      <p class="delivery-data">{{ `${deliveredPer}%` }}</p>
                    </div>
                    <div class="progress col-md-4">
                      <!-- style="width: 100%" -->
                      <div class="progress-bar deliver-bar" role="progressbar" aria-valuenow="25"
                        aria-valuemin="`${parseInt(deliveredPer)}`" aria-valuemax="100"
                        :style="{ width: deliveredPer + '%' }">
                        {{ delivered }}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-4">
                      <p class="delivery-header">Opened</p>
                      <p class="delivery-data">{{ `${openedPer}%` }}</p>
                    </div>
                    <div class="progress col-md-4">
                      <div class="progress-bar open-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                        aria-valuemax="100" :style="{ width: openedPer + '%' }">
                        {{ opened }}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-4">
                      <p class="delivery-header">Review Clicks</p>
                      <p class="delivery-data">{{ `${reviewClicksPer}%` }}</p>
                    </div>
                    <div class="progress col-md-4">
                      <div class="progress-bar click-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                        aria-valuemax="100" :style="{ width: reviewClicksPer + '%' }">
                        {{ reviewClicks }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <div class="custom-card-wrapper">
        <div class="custom-card">
          <div class="custom-inner">
            <h2 class="cardTitle">Delivery</h2>
            <div class="inner-card pt-0">
              <div id="main" style="width: 350px; height: 200px">
                <apacheChart :option="chartOptions_1" autoresize />
              </div>
              <ul class="status-list">
                <li>
                  <p class="delivery-header">
                    <span class="fcircle"></span>&nbsp; Failed
                  </p>
                  <p class="delivery-data">{{ failed }}</p>
                </li>
                <li>
                  <p class="delivery-header">
                    <span class="dcircle"></span>&nbsp; Delivered
                  </p>
                  <p class="delivery-data">{{ delivered }}</p>
                </li>
                <li>
                  <p class="delivery-header">
                    <span class="scircle"></span>&nbsp;Scheduled
                  </p>
                  <p class="delivery-data">{{ scheduled }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="custom-inner">
            <h2 class="cardTitle">Perfomance</h2>
            <div class="inner-card">
              <div class="row">
                <div class="col-md-4">
                  <p class="delivery-header">Delivered</p>
                  <p class="delivery-data">{{ `${deliveredPer}%` }}</p>
                </div>
                <div class="progress col-md-4">
                  <!-- style="width: 100%" -->
                  <div class="progress-bar deliver-bar" role="progressbar" aria-valuenow="25"
                    aria-valuemin="`${parseInt(deliveredPer)}`" aria-valuemax="100"
                    :style="{ width: deliveredPer + '%' }">
                    {{ delivered }}
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <p class="delivery-header">Opened</p>
                  <p class="delivery-data">{{ `${openedPer}%` }}</p>
                </div>
                <div class="progress col-md-4">
                  <div class="progress-bar open-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                    aria-valuemax="100" :style="{ width: openedPer + '%' }">
                    {{ opened }}
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <p class="delivery-header">Review Clicks</p>
                  <p class="delivery-data">{{ `${reviewClicksPer}%` }}</p>
                </div>
                <div class="progress col-md-4">
                  <div class="progress-bar click-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                    aria-valuemax="100" :style="{ width: reviewClicksPer + '%' }">
                    {{ reviewClicks }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4 mb-4 mb-sm-0 d-flex center-item">
        <div>
          <!-- @click="generateConversionPDF" -->
          <div class="action-btns">
            <div class="dropdown">
              <button class="btn export-ui" type="button" @click="downloadConversionFile">
                <img src="resources/assets/export-excel.svg" />&nbsp; Export to
                Excel &nbsp;
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="action-btns">
            <div class="dropdown">
              <a href="mailto:" target="_blank">
                <button class="btn export-ui" type="button">
                  <img src="resources/assets/export-email.svg" />&nbsp; Email
                  &nbsp;
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ActionBar from "./ActionBar";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import ECharts from "vue-echarts";
import * as echarts from "echarts";
import VueHtml2pdf from "vue-html2pdf";
import exportFromJSON from "export-from-json";
export default {
  name: "Reports",
  props: {
    filterPlatform: {
      type: String,
    },
  },
  components: {
    ActionBar,
    apexchart: VueApexCharts,
    DateRangePicker,
    apacheChart: ECharts,
    VueHtml2pdf,
  },
  watch: {
    cust_range() {
      this.selectFilter(this.cust_range);
    },
  },
  data() {
    return {
      moment: moment,
      cust_range: ["01/09/2018", "01/10/2018", false],
      cust_range_options: {
        timePicker: false,
        autoUpdateInput: true,
        startDate: moment(),
        endDate: moment(),
        locale: {
          format: "DD/MM/YYYY",
        },
      },

      dateRange: {},
      autoApply: false,
      report_type: "reviews",
      // report_type: "conversion",
      series: [
        {
          name: "Reviews",
          type: "column",
          data: [],
        },
        {
          name: "Average Rating",
          type: "line",
          data: [],
        },
      ],
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: "12%",
            startingShape: "round",
            endingShape: "round",
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        colors: ["#FA5244", "#6A8FCD"],
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
        },
        stroke: {
          show: true,
          width: [0, 4],
          // curve: "smooth",
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          distributed: false,
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#6A8FCD",
            opacity: 0.9,
          },
          formatter: (value, { seriesIndex, dataPointIndex, w }) => {
            return this.lineGraphData[dataPointIndex];
          },
        },
        fill: {
          colors: ["#FA5144"],
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#FDD640"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: [
          {
            title: {
              text: "Reviews",
            },
            min: 0,
          },
        ],
        markers: {
          size: 10,
          colors: "#6A8FCD",
          strokeColors: "#6A8FCD",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 4,
          offsetX: 0,
          offsetY: 0,
          onClick: function (e) {
            // do something on marker click
            console.log("e", e);
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          shared: false,
          intersect: true,
          enabled: true,
          marker: {
            show: true,
          },
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            if (seriesIndex) {
              return (
                '<div class="col-md-2 mb-4 pb-2 reportStatistics" style="border-radius:25px">' +
                '<h2 class="cardTitle">Average Rating</h2>' +
                '<div style="padding: 15px">' +
                ' <div class="row center-item">' +
                '  <div class="col-6 col-md-6 mb-2 pb-1 form-outline form-white">' +
                '<h1 class="data-h1">' +
                `${this.lineGraphData[dataPointIndex]}` +
                "</h1>" +
                "</div>" +
                " </div>" +
                "</div>" +
                "</div>"
              );
            } else {
              return (
                '<div class="col-md-2 mb-4 pb-2 reportStatistics">' +
                '<h2 class="cardTitle">Reviews</h2>' +
                '<div style="padding: 15px">' +
                ' <div class="row center-item">' +
                '  <div class="col-6 col-md-6 mb-2 pb-1 form-outline form-white">' +
                '<h1 class="data-h1">' +
                series[seriesIndex][dataPointIndex] +
                "</h1>" +
                "</div>" +
                " </div>" +
                "</div>" +
                "</div>"
              );
            }
          },
        },
      },
      chartOptions_1: {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: ["60%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 25, name: "Failed" },
              { value: 75, name: "Delivered" },
              { value: 75, name: "Scheduled" },
            ],
          },
        ],
        color: ["#FA4444", "#38CB89", "#f9c70a"],
      },
      startDate: "",
      endDate: "",
      reviewGraphData: [],
      platform: "",
      userLoginId: "",
      datePicker: false,
      cardReview: "",
      cardAverageRating: "",
      cardDate: "",
      yearFilter: "All Time",
      failed: 0,
      scheduled: "",
      delivered: "",
      opened: "",
      totalCount: "",
      deliveredPer: "",
      openedPer: "",
      reviewClicks: "",
      reviewClicksPer: "",
      reviewList: [],
      lineGraphData: [],
      percentageGrowth: "",
      averageRatingGrowth: "",
      adminId: ""
    };
  },
  mounted() {
    console.clear();
    this.userLoginId = localStorage.getItem("id");
    this.adminId = localStorage.getItem("adminId");
    this.platform = this.filterPlatform ? this.filterPlatform : "";
    this.reviewGraph();
    this.reviewCard();
    this.conversionGraph();
  },
  created() { },
  computed: {
    years() {
      const year = new Date().getFullYear();
      let years = Array.from(
        { length: year - 1970 },
        (value, index) => 1971 + index
      ).reverse();
      years.unshift("All Time");
      return years;
    },
  },
  methods: {
    onChange(event) {
      this.report_type = event.target.value ? event.target.value : "reviews";
      if (this.report_type == "conversion") {
        this.conversionGraph();
      }
    },
    onPlatformChange(event) {
      this.platform = event.target.value;
      this.reviewGraph();
      this.reviewCard();
    },
    selectFilter(data) {
      this.startDate = moment(data.startDate).format("YYYY-MM-DD");
      this.endDate = moment(data.endDate).format("YYYY-MM-DD");
      this.reviewGraph();
      this.reviewCard();
    },
    onChangeYear(e) {
      this.yearFilter = e.target.options[e.target.options.selectedIndex].text;
      if (this.yearFilter) {
        this.reviewGraph();
        this.reviewCard();
      }
    },
    reviewGraph() {
      let year;
      if (this.yearFilter) {
        year = this.yearFilter + "-01-01";
      } else {
        year = moment(this.startDate).format("YYYY");
      }
      const selectedStartOfYear = moment([year])
        .startOf("year")
        .format("YYYY-MM-DD");

      const startOfYear = moment([year]).startOf("year").format("YYYY-MM-DD");
      const endOfYear = moment([year]).endOf("year").format("YYYY-MM-DD");

      let data = {
        date: this.startDate
          ? ""
          : selectedStartOfYear != "Invalid date"
            ? selectedStartOfYear
            : "",
        platformName: this.platform ? this.platform : "",
        fromDate: this.startDate ? this.startDate : "",
        toDate: this.endDate ? this.endDate : "",
        userId: this.adminId != 'null' ? this.adminId : this.userLoginId,
      };
      this.$store.dispatch("reviewGraph", data).then((response) => {
        this.reviewList = response.data;
        let categoriesData = this.reviewList.map((categories) => {
          return categories.Month;
        });
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: [...categoriesData],
            },
          },
        };
        this.series[0].data = this.reviewList.map((data) => {
          return data.count ? data.count : 0;
        });

        this.series[1].data = this.reviewList.map((data) => {
          return data.count ? data.count : 0;
        });

        this.lineGraphData = this.reviewList.map((data) => {
          return data.reviewRating
            ? (data.reviewRating / data.count).toFixed(2)
            : 0;
        });

        window.dispatchEvent(new Event("resize"));
      });
    },
    reviewCard() {
      let year;
      if (this.yearFilter) {
        year = this.yearFilter + "-01-01";
      } else {
        year = moment(this.startDate).format("YYYY");
      }

      const selectedStartOfYear = moment([year])
        .startOf("year")
        .format("YYYY-MM-DD");

      let data = {
        date: this.startDate
          ? ""
          : selectedStartOfYear != "Invalid date"
            ? selectedStartOfYear
            : "",
        platformName: this.platform ? this.platform : "",
        fromDate: this.startDate ? this.startDate : "",
        toDate: this.endDate ? this.endDate : "",
        userId: this.adminId != 'null' ? this.adminId : this.userLoginId,
      };
      this.$store.dispatch("reviewGraphCard", data).then((response) => {
        let res = response.data["total"];
        let percentage = response.reportCalculation;
        this.cardReview = res && res["count"] ? res["count"] : "0";
        this.cardAverageRating =
          res && res["reviewRating"]
            ? (res["reviewRating"] / res["count"]).toFixed(2)
            : "0";
        this.cardDate = res && res["date"] ? res["date"] : "";

        this.percentageGrowth = percentage["percentageGrowth"]
          ? percentage["percentageGrowth"]
          : 0;
        this.averageRatingGrowth = percentage["averageRatingGrowth"]
          ? percentage["averageRatingGrowth"]
          : 0;
      });
    },
    showPicker(title) {
      title == "picker" ? (this.datePicker = true) : (this.datePicker = false);
    },
    conversionGraph() {
      let data = {
        userId: this.adminId != 'null' ? this.adminId : this.userLoginId,
      };
      this.$store.dispatch("reviewConversionGraph", data).then((response) => {
        let res = response.data;

        this.chartOptions_1.series[0].data[0].value = res.failed
          ? res.failed
          : 0;
        this.chartOptions_1.series[0].data[1].value = res.delivered
          ? res.delivered
          : 0;
        this.chartOptions_1.series[0].data[2].value = res.scheduled
          ? res.scheduled
          : 0;
        this.failed = res.failed ? res.failed : 0;
        this.scheduled = res.scheduled;
        this.delivered = res.delivered;
        this.opened = res.opened;
        this.totalCount = res.totalCount;
        this.reviewClicks = res.ReviewClicks;
        this.deliveredPer = Math.round((res.delivered / res.totalCount) * 100);
        this.openedPer = Math.round((res.opened / res.totalCount) * 100);
        this.reviewClicksPer = Math.round(
          (res.ReviewClicks / res.totalCount) * 100
        );
      });
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      // alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    generateConversionPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    downloadFile() {
      const data = [];
      const fileName = "review-excel";
      const exportType = exportFromJSON.types.csv;

      this.reviewList.forEach((element) => {
        data.push({
          Month: element.Month,
          Reviews: element.count,
          "Average Ratings": (element.reviewRating / element.count).toFixed(2),
        });
      });
      data.push({
        Month: "Total",
        Reviews: this.cardReview,
        "Average Ratings": this.cardAverageRating,
      });
      if (data) exportFromJSON({ data, fileName, exportType });
    },

    downloadConversionFile() {
      const data = [];
      const fileName = "conversion-excel";
      const exportType = exportFromJSON.types.csv;

      data.push(
        {
          Delivery: "Failed",
          value: this.failed,
        },
        {
          Delivery: "Delivered",
          value: this.delivered,
        },
        {
          Delivery: "Scheduled",
          value: this.scheduled,
        },
        {
          Delivery: "",
          value: "",
        },
        {
          Delivery: "",
          value: "",
        },
        {
          Delivery: "Perfomance",
          value: "Percentage",
        },
        {
          Delivery: "Delivered",
          value: `${this.deliveredPer}%`,
        },
        {
          Delivery: "Opened",
          value: `${this.openedPer}%`,
        },
        {
          Delivery: "Review Clicks",
          value: `${this.reviewClicksPer}%`,
        }
      );
      if (data) exportFromJSON({ data, fileName, exportType });
    },
  },
};
</script>
<style scoped>
.reportStatistics {
  background-color: white;
  margin: 10px;
  border-radius: 20px;
  width: 280px;
  padding: 20px;
}

.reportGraph {
  background-color: white;
  border-radius: 20px;
}

.drop-btn {
  font-family: "Roboto", sans-serif;
  width: 114px;
  height: 43px;
  background-color: #f4f5fa;
  margin: 10px 31px;
  border-radius: 15px;
  color: #00053c;
  font-size: 14px;
  font-weight: 500;
}

.drop-btn2 {
  font-family: "Roboto", sans-serif;
  /* background-color: #F4F5FA;    */
  color: #00053c;
  font-size: 14px;
  font-weight: 500;
}

.cardTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  color: #9194b3;
  margin-bottom: 25px;
}

.inner-head-wrap {
  display: flex;
  display: -ms-flexbox;
  align-items: baseline;
}

.inner-head-wrap .data-h1 {
  padding-right: 10px;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-around-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.data-per {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
  margin-right: 5px;
}

.data-plus {
  color: #38cb89;
}

.data-minus {
  color: #fa4444;
}

.data-h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.3;
  color: #00053c;
}

.date-color {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  color: #b0b7c3;
  margin: 0;
}

.export-ui {
  background: #f4f5fa;
  border-radius: 15px;
  margin: 15px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: #00053c;
}

.report-type {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 1.3;
  color: #00053c;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: none;
}

.report-change {
  height: 43px;
  top: 500px;
  background: #f4f5fa;
  border: 1px solid #f3f3f3;
  border-radius: 15px;
  margin: 10px;
}

.table-align {
  margin-top: 15px;
  border-radius: 36px;
  margin-left: 10px;
}

.delivery-header {
  left: 370px;
  top: 500px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.delivery-data {
  width: 65px;
  height: 24px;
  left: 350px;
  top: 521px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.fcircle {
  background: #fa4444;
  clip-path: circle(50%);
  height: 14px;
  width: 14px;
}

.dcircle {
  background: #38cb89;
  clip-path: circle(50%);
  height: 14px;
  width: 14px;
}

.scircle {
  background: #f9c70a;
  clip-path: circle(50%);
  height: 14px;
  width: 14px;
}

.progress {
  height: 20px;
  width: 66%;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f4f5fa;
  padding: 0px;
  margin-top: 20px;
  box-shadow: none;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.deliver-bar {
  background: linear-gradient(270deg,
      #4285f4 0%,
      rgba(66, 133, 244, 0.35) 100%);
}

.click-bar {
  background: linear-gradient(270deg,
      #6789c1 0%,
      rgba(137, 176, 240, 0.35) 100%);
}

.open-bar {
  background: linear-gradient(270deg,
      #fdd640 0%,
      rgba(253, 214, 64, 0.35) 100%);
}

.p25 {
  padding: 25px;
}

.cards {
  background: #f4f5fa;
  border-radius: 15px;
  padding: 0px 15px;
  height: 400px;
}

/* style start */
.card-header h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px;
  color: #00053c;
}

.report-chart-wrap {
  margin: 10px 5px;
}

.action-wrap .report-change,
.action-wrap button.drop-btn {
  margin: 0 !important;
  width: 180px !important;
  margin-left: 15px !important;
}

/* cards-style */
.custom-card-wrapper {
  display: flex;
  display: -ms-flexbox;
}

.custom-card {
  width: 50%;
  padding: 0 12px;
}

.custom-inner {
  background: rgb(244, 245, 250);
  border-radius: 15px;
  padding: 20px;
}

ul.status-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}

ul.status-list li {
  width: 33.333%;
}

.inner-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 25px;
}

.inner-card hr {
  background-color: #f4f5fa;
  margin: 13px 0;
}

/* responsive start */
@media (max-width: 1198.98px) {
  .custom-card-wrapper div#main {
    width: auto !important;
  }
}

@media (max-width: 767.98px) {
  .heading-wrap>.col-6 {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }

  button#dropdownMenuButton1 {
    margin: 10px;
  }

  .file-wrap {
    flex-wrap: wrap;
  }

  .file-wrap .action-btns button {
    margin: 0;
    margin-bottom: 10px;
    width: 200px;
    display: block !important;
  }

  .reportStatistics {
    width: 90%;
  }

  .custom-card-wrapper {
    display: block;
  }

  .custom-card {
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }

  .progress {
    width: 100%;
  }

  .card-header h2 {
    margin-bottom: 10px !important;
  }

  .card-header h2 {
    margin-bottom: 10px !important;
  }

  .action-wrap .report-change,
  .action-wrap button.drop-btn {
    margin: 0 !important;
    margin-bottom: 10px !important;
    width: 150px !important;
    margin-right: 10px !important;
  }

  .heading-wrap.mb-5 {
    margin-bottom: 0px !important;
  }

  .table-align {
    margin: 0 !important;
  }
}

.yearSelectDropdown {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  /* font-size: 16px !important; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.yearSelect {
  height: 50px;
  left: 889px;
  top: 413px;
  /* background: #ffffff; */
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
  margin: auto 10px;
}

.dropdownMargin {
  margin: 10px;
}
</style>