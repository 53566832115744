<template>
  <div class="signup-wrap">
    <div class="row row-height">
      <div class="col-12 col-lg-6 firstPart">
        <div class="logo-wrap">
          <img src="resources/assets/logo-sidebar.svg" alt="logo" />
        </div>
        <div class="review-feature">
          <div class="review-inner">
            <h1 class="colorWhite">Review</h1>
            <h1 class="colorWhite weight300">Features</h1>
            <ul class="list">
              <li>
                <img src="resources/assets/circleCheck.png" /> SMS Review Invite
                Messages
              </li>
              <li>
                <img src="resources/assets/circleCheck.png" />Email Review
                Invite Messages
              </li>
              <li>
                <img src="resources/assets/circleCheck.png" />Custom Invite
                Messages
              </li>
              <li>
                <img src="resources/assets/circleCheck.png" />Custom Feedback
                Form
              </li>
              <li>
                <img src="resources/assets/circleCheck.png" />
                Interactive into Facebook, Google, Yelp and Zillow Reviews
              </li>
              <li>
                <img src="resources/assets/circleCheck.png" />SMS Review Invite
                Messages
              </li>
              <li><img src="resources/assets/circleCheck.png" />Reporting</li>
              <li>
                <img src="resources/assets/circleCheck.png" />No Contracts!
                Cancel Anytime
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 p-0">
        <div v-if="firstPart === true" class="signUpForm">
          <div class="topText">
            <p>
              You're just one step away from more positive reviews and deep
              insight into how your customers really feel! Fill out the
              formbelow get started.
            </p>
          </div>
          <div>
            <h1 class="mb-0 mt-5 largeFont">Get Started</h1>
            <h1 class="doller-per-month mb-5">$79.99/Month</h1>
            <form class="w-100">
              <div class="row">
                <div class="col-md-6 mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.first_name" type="text" id="form3Examplea4"
                      class="loginInputsEmail inputOrange" placeholder="First Name" v-on:keypress="isLetter($event)"
                      v-validate="'required'" v-bind:name="'registerForm.first_name'"
                      v-bind:ref="'registerForm.first_name'" :class="{
                        'is-invalid': errors.has('registerForm.first_name'),
                      }" autocomplete="off" />
                  </div>
                </div>
                <div class="col-md-6 mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.last_name" type="text" id="form3Examplea5"
                      class="loginInputsEmail inputOrange" placeholder="Last Name" v-on:keypress="isLetter($event)"
                      v-validate="'required'" v-bind:name="'registerForm.last_name'"
                      v-bind:ref="'registerForm.last_name'" :class="{
                        'is-invalid': errors.has('registerForm.last_name'),
                      }" autocomplete="off" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.organisation_name" type="text" id="form3Examplea2"
                      class="loginInputsEmail inputOrange" placeholder="Business/Organization Name"
                      v-validate="'required'" v-bind:name="'registerForm.organisation_name'"
                      v-bind:ref="'registerForm.organisation_name'" :class="{
                        'is-invalid': errors.has(
                          'registerForm.organisation_name'
                        ),
                      }" autocomplete="off" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.email" type="email" id="form3Examplea4"
                      class="loginInputsEmail inputOrange" placeholder="Email" v-validate="'required'"
                      v-bind:name="'registerForm.email'" v-bind:ref="'registerForm.email'" :class="{
                        'is-invalid': errors.has('registerForm.email'),
                      }" autocomplete="off" />
                  </div>
                </div>
                <div class="col-md-6 mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.phone" type="number" id="form3Examplea5"
                      class="loginInputsEmail inputOrange" placeholder="Phone" v-validate="'required'"
                      v-bind:name="'registerForm.phone'" v-bind:ref="'registerForm.phone'" :class="{
                        'is-invalid': errors.has('registerForm.phone'),
                      }" autocomplete="off" />
                  </div>
                </div>
              </div>
              <div class="mb-4 pb-2">
                <div class="form-outline form-white">
                  <input v-model="registerForm.password" :type="passwordFieldType" id="form3Examplea2"
                    class="loginInputsPassword inputOrange" placeholder="Password" v-validate="'required|min:6'"
                    v-bind:name="'registerForm.password'" v-bind:ref="'registerForm.password'" :class="{
                      'is-invalid': errors.has('registerForm.password'),
                    }" @keydown.space.prevent autocomplete="off" />
                  <span @click="switchVisibility()">
                    <i :class="passwordIcon" style="font-size: 18px"></i>
                  </span>
                </div>
              </div>
              <div class="mb-4 pb-2">
                <div class="form-outline form-white">
                  <input v-model="registerForm.billing_address" type="text" id="autocomplete"
                    class="loginInputsEmail inputOrange" placeholder="Billing Address" v-validate="'required'"
                    v-bind:name="'registerForm.billing_address'" v-bind:ref="'registerForm.billing_address'" :class="{
                      'is-invalid': errors.has('registerForm.billing_address'),
                    }" autocomplete="off" @input="initialize" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.city" type="text" id="form3Examplea4"
                      class="loginInputsEmail inputOrange" placeholder="City" v-validate="'required'"
                      v-bind:name="'registerForm.city'" v-bind:ref="'registerForm.city'" :class="{
                        'is-invalid': errors.has('registerForm.city'),
                      }" autocomplete="off" />
                  </div>
                </div>
                <div class="col-md-3 mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.state" type="text" id="form3Examplea5"
                      class="loginInputsEmail inputOrange" placeholder="State" v-validate="'required'"
                      v-bind:name="'registerForm.state'" v-bind:ref="'registerForm.state'" :class="{
                        'is-invalid': errors.has('registerForm.state'),
                      }" autocomplete="off" />
                  </div>
                </div>
                <div class="col-md-3 mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input v-model="registerForm.zip_code" type="text" id="form3Examplea5"
                      class="loginInputsEmail inputOrange" placeholder="Zip" v-validate="'required'"
                      v-bind:name="'registerForm.zip_code'" v-bind:ref="'registerForm.zip_code'" :class="{
                        'is-invalid': errors.has('registerForm.zip_code'),
                      }" autocomplete="off" />
                  </div>
                </div>
              </div>
              <div class="d-grid gap-2">
                <button class="loginButton" style="" type="button" @click="changeView">
                  <p class="loginButtonText">Next</p>
                </button>
              </div>
            </form>
            <div class="form-check fadeColor">
              <p class="front-sub" @click="directLogin()" style="cursor: pointer">
                Already a FrontRunner Subscriber?
              </p>
              <p class="front-sub">
                Visit step1 of your account to bundle and save Reviewsy
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <Payment :registerData="registerForm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Payment from "./Payment";
export default {
  name: "SignUp",
  props: {
    msg: String,
  },
  components: {
    Payment,
  },
  data() {
    return {
      firstPart: true,
      registerForm: {
        id: "",
        first_name: "",
        last_name: "",
        organisation_name: "",
        email: "",
        phone: "",
        billing_address: "",
        city: "",
        state: "",
        zip_code: "",
        password: "",
        b_firstName: "",
        b_lastNAme: "",
        b_billingAddress: "",
        b_city: "",
        b_state: "",
        b_zip: "",
        b_cardNumber: "",
        b_expDate: "",
        b_cvv: "",
      },
      registerFormChecked: false,
      terms_cond: false,
      passwordFieldType: "password",
      passwordIcon: "bi bi-eye-slash",
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
    };
  },
  methods: {
    async changeView() {
      let result = await this.$validator.validateAll();
      if (result) {
        this.$store.dispatch("register", this.registerForm).then((response) => {
          if (response.success == true) {
            this.firstPart = false;
          } else {
            if (response.data.data.email) {
              this.$toastr.e(response.data.data.email);
            }
            if (response.data.data.password) {
              this.$toastr.e(response.data.data.password);
            }
          }
        });
      } else {
        this.$toastr.e("Please fill the required fields");
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    check() {
      if (this.registerFormChecked) {
        this.registerForm.b_firstName = this.registerForm.first_name;
        this.registerForm.b_lastNAme = this.registerForm.last_name;
        this.registerForm.b_billingAddress = this.registerForm.billing_address;
        this.registerForm.b_city = this.registerForm.city;
        this.registerForm.b_state = this.registerForm.state;
        this.registerForm.b_zip = this.registerForm.zip_code;
      } else {
        this.resetPaymentForm();
      }
    },
    resetPaymentForm() {
      this.registerForm.b_firstName = "";
      this.registerForm.b_lastNAme = "";
      this.registerForm.b_billingAddress = "";
      this.registerForm.b_city = "";
      this.registerForm.b_state = "";
      this.registerForm.b_zip = "";
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordIcon === "bi bi-eye-slash"
          ? "bi bi-eye"
          : "bi bi-eye-slash";
    },
    directLogin() {
      this.$router.push("/login");
    },
    initialize(e) {
      var self = this;
      let input = document.getElementById("autocomplete");
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        let place = autocomplete.getPlace();
        let addressComponent = place.address_components
        let city =addressComponent.find(d => {
          return d.types[0] === 'locality'}
        ); 

        let postal_code =addressComponent.find(d => {
          return d.types[0] === 'postal_code'}
        ); 
        let state =addressComponent.find(d => {
          return d.types[0] === 'administrative_area_level_1'}
        ); 

        self.registerForm.billing_address = place.name
        self.registerForm.city = city.long_name
        self.registerForm.state =state.long_name
        self.registerForm.zip_code= postal_code.long_name
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");

.bgBlack {
  background: #27293b;
}

.review-feature {
  height: calc(100% - 60px);
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: center;
}

.review-feature .colorWhite {
  font-family: "Inter";
  font-weight: 600;
  font-size: 60px;
  color: #ffffff;
}

.colorWhite {
  color: white;
  font-size: 3.25rem;
  font-weight: 500;
}

.weight300 {
  font-weight: 300 !important;
  margin-left: 60px;
}

.logo {
  margin-left: 15px;
  margin-top: 15px;
}

.list {
  border-top: 1px solid rgba(145, 148, 179, 0.3);
  padding-top: 30px;
  margin-top: 50px;
  padding-left: 30px;
  margin-left: 20px;
}

.list li {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #9194b3;
  list-style: none;
  margin-bottom: 10px;
}

.list li img {
  margin-right: 2vw;
  /* content: '\f00c';
  color: greenyellow; */
}

.signUpForm {
  padding: 40px;
}

.doller-per-month {
  margin-left: 60px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 60px;
  color: #00053c;
}

.largeFont {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 60px;
  color: #00053c;
}

.firstPart {
  background: #27293b;
  padding: 40px;
}

.topText {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #9194b3;
  padding-right: 30%;
}

.fadeColor {
  color: #9194b3;
  text-align: center;
  margin-top: 30px;
}

.signUpHeading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  color: #00053c;
}

.signUpButton {
  width: 32.291666666666664vw;
  height: 6.189967982924227vh;
}

.loginInputsEmail {
  width: 100%;
  height: 58px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 15px;
}

.loginInputsPassword {
  width: 100%;
  height: 58px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 15px;
}

form input {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  color: #9194b3;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.loginButton {
  margin-top: 20px;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
  transition: 0.2s ease;
}

.loginButtonText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  /* box-shadow: 0 0 10px #719ECE; */
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.front-sub {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #9194b3;
  margin: 0;
}

/* custom-style start */
.signup-wrap {
  padding: 0 8px;
}

.review-inner {
  position: relative;
}

.review-inner::after {
  position: absolute;
  content: "";
  background: rgba(145, 148, 179, 0.3);
  width: 1px;
  height: 100%;
  top: -20px;
  left: 20px;
}

/* responsive start */
@media (max-width: 1600px) {
  .largeFont {
    font-size: 50px;
  }

  .doller-per-month {
    font-size: 50px;
  }

  .review-feature .colorWhite {
    font-size: 50px;
  }
}

@media (max-width: 1200px) {
  .largeFont {
    font-size: 40px;
  }

  .doller-per-month {
    font-size: 40px;
  }

  .review-feature .colorWhite {
    font-size: 40px;
  }

  .weight300 {
    margin-left: 50px;
  }

  .list li {
    font-size: 16px;
  }

  .topText {
    padding-right: 0;
  }

  .list {
    padding-left: 20px;
    margin-top: 30px;
    padding-top: 20px;
    margin-left: 10px;
  }

  .list li img {
    margin-right: 15px;
  }

  .review-inner::after {
    left: 10px;
  }

  .fadeColor {
    margin-top: 20px;
  }
}

@media (max-width: 767.98px) {
  .signup-wrap .logo-wrap {
    padding-bottom: 60px;
  }

  .list li {
    font-size: 14px;
  }

  .firstPart {
    padding: 20px;
  }

  .signUpForm {
    padding: 20px;
  }
}
</style>
