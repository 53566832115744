<template>
  <div>
    <div v-if="apiLoading || disconnectLoading" class="loading">
      Loading&#8230;
    </div>
    <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
      <!-- Vertical Navbar -->
      <nav class="
          navbar
          show
          navbar-vertical
          h-lg-screen
          navbar-expand-lg
          px-0
          py-0
          navbar-light
          bg-white
          border-bottom border-bottom-lg-0 border-end-lg
          sideBar
        " id="navbarVertical">
        <div class="container-fluid leftPanel">
          <div class="btn-logo-wrap">
            <!-- Toggler -->
            <button class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Brand -->
            <a class="navbar-brand logo-center" role="button" @click="showDiv('dashboard')">
              <img src="resources/assets/logo-sidebar.svg" alt="..." />
            </a>
            <!-- User menu (mobile) -->
            <div class="navbar-user d-none d-lg-none">
              <!-- Dropdown -->
              <div class="dropdown">
                <!-- Toggle -->
                <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <div class="avatar-parent-child">
                    <img v-if="adminDetails.profile" alt="..." :src="adminDetails.profile"
                      class="avatar avatar-xl rounded-circle me-2 loginPic" />
                    <span class="avatar-child avatar-badge bg-success"></span>
                  </div>
                </a>
                <!-- Menu -->
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                  <a href="#" class="dropdown-item">Profile</a>
                  <a href="#" class="dropdown-item">Settings</a>
                  <a href="#" class="dropdown-item">Billing</a>
                  <hr class="dropdown-divider" />
                  <a href="#" class="dropdown-item">Settings/Account</a>
                  <a href="#" class="dropdown-item">Logout</a>
                </div>
              </div>
            </div>
          </div>

          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="sidebarCollapse" v-bind:class="{ 'is-active': isActive }">
            <!-- Navigation -->
            <ul class="navbar-nav">
              <li class="nav-item">
                <a :class="{ active: show == 'dashboard' }" class="nav-link" @click="showDiv('dashboard')" href="#">
                  <img
                    :src="show == 'dashboard' ? 'resources/assets/activedashboard.svg' : 'resources/assets/dashboard.svg'"
                    alt />
                  &nbsp;Dashboard
                </a>
              </li>
              <li class="nav-item">
                <a :class="{ active: show == 'reviews' }" class="nav-link" @click="showDiv('reviews')" href="#">
                  <img :src="show == 'reviews' ? 'resources/assets/active-reviews.svg' : 'resources/assets/reviews.svg'"
                    alt />
                  &nbsp;Reviews &nbsp;<sup style="color: red">{{
                      reviewStatus
                  }}</sup>
                </a>
              </li>
              <li class="nav-item">
                <a :class="{ active: show == 'request_reviews' }" class="nav-link" @click="showDiv('request_reviews')"
                  href="#">
                  <img
                    :src="show == 'request_reviews' ? 'resources/assets/active-request-review.svg' : 'resources/assets/request-review.svg'"
                    alt />
                  &nbsp;Request Reviews
                </a>
              </li>
              <li class="nav-item" v-if="checkPermission">
                <a :class="{ active: show == 'users' }" class="nav-link" @click="showDiv('users')" href="#">
                  <img :src="show == 'users' ? 'resources/assets/active-user.svg' : 'resources/assets/users.svg'" alt />
                  &nbsp;Users
                </a>
              </li>
              <li class="nav-item">
                <a :class="{ active: show == 'feedback' }" class="nav-link" @click="showDiv('feedback')" href="#">
                  <img
                    :src="show == 'feedback' ? 'resources/assets/active-feedback.svg' : 'resources/assets/feedback.svg'"
                    alt />
                  &nbsp;Feedback&nbsp;<sup style="color: red">{{
                      feedbackStatus
                  }}</sup>
                </a>
              </li>
              <li class="nav-item">
                <a :class="{ active: show == 'reports' }" class="nav-link" @click="showDiv('reports')" href="#">
                  <img :src="show == 'reports' ? 'resources/assets/active-reports.svg' : 'resources/assets/reports.svg'"
                    alt />
                  &nbsp;Reports
                </a>
              </li>
              <li class="nav-item">
                <a :class="{ active: show == 'settings' }" class="nav-link" @click="showDiv('settings')" href="#">
                  <img
                    :src="show == 'settings' ? 'resources/assets/active-seetings.svg' : 'resources/assets/settings.svg'"
                    alt />
                  &nbsp;Settings/Account
                </a>
              </li>
              <li class="nav-item">
                <a :class="{ active: show == 'setup' }" class="nav-link" @click="showDiv('setup')" href="#">
                  <img height="24" width="24" src="resources/assets/setup.svg" alt />&nbsp;Setup Wizard
                </a>
              </li>
              <li class="nav-item">
                <a :class="{ active: show == 'help' }" class="nav-link" @click="showDiv('help')" href="#">
                  <img :src="show == 'help' ? 'resources/assets/active-help.svg' : 'resources/assets/help.svg'" alt
                    height="24" width="24" />
                  &nbsp;&nbsp;Help
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click="logout">
                  <i class="bi bi-box-arrow-left"></i>&nbsp;Logout
                </a>
              </li>
              <li class="nav-item" v-if="superAdminToken">
                <a class="nav-link" href="#" @click="adminLogin">
                  <i class="bi bi-box-arrow-left"></i>&nbsp;Login Back as Admin
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- Main content -->
      <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
        <!-- Header -->
        <header class="bg-surface-primary pt-6" style="margin-right: 32px; margin-bottom: 15px">
          <div>
            <div class="mb-npx">
              <div class="row align-items-center">
                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                  <!-- Title -->
                  <!-- <div class="searchBoxDiv">
                    <input
                      type="text"
                      class="form-control searchBox"
                      placeholder="Search"
                      name="search"
                    />
                    <img
                      src="resources/assets/searchIcon.png"
                      style="margin-left: -32px"
                    />
                  </div> -->
                </div>
                <!-- Actions -->
                <div class="
                    dropdown
                    profile-wrap
                    col-md-8 col-12
                    mb-4 mb-sm-0
                    d-flex
                    justify-content-end
                  ">
                  <div class="profile-inner">
                    <span class="profile-img" v-if="adminDetails.profile">
                      <img v-if="adminDetails.profile" alt="..." :src="adminDetails.profile" />
                    </span>
                    <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      style="cursor: pointer">
                      <p class="loginName">
                        {{ adminDetails.firstName }}
                        {{ adminDetails.lastName ? adminDetails.lastName : "" }}
                        <img class="profile-dropdown" src="resources/assets/profile-dropdown.svg" alt="" />
                      </p>
                    </div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a :class="{ active: show == 'settings' }" class="nav-link" @click="showDiv('settings')"
                        style="cursor: pointer">
                        <p class="d-flex align-items-center loginName">
                          Settings
                        </p>
                      </a>
                      <a style="cursor: pointer" class="nav-link" @click="logout">
                        <p class="d-flex align-items-center loginName">
                          Logout
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <!-- Main -->
        <transition name="modal" v-if="userTour">
          <v-tour name="userTour" :steps="userSteps" :callbacks="userCallbacks">
            <template slot-scope="tour" style="background: #fff; color: #000">
              <transition name="fade">
                <v-step class="v-step" v-if="tour.steps[tour.currentStep]" :key="tour.currentStep"
                  :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep" :next-step="tour.nextStep"
                  :stop="tour.stop" :skip="tour.skip" :is-first="tour.isFirst" :is-last="tour.isLast"
                  :labels="tour.labels">
                  <template v-if="tour.currentStep === 0">
                    <div slot="content">
                      <h1>Welcome to Reviewzy! <img @click="tour.stop" src="resources/assets/crossicon.png" /></h1>
                      <p class="vue-para"> You are just a few steps away from improving your online reputation and
                        gaining more positive reviews. Follow the steps to easily set up your account and begin sending
                        reviews!.</p>
                    </div>
                    <div slot="actions">
                      <button @click="tour.stop" class="tourButton">Maybe Later</button>
                      <button @click="tour.nextStep" class="tourButton">Get Started</button>
                    </div>
                  </template>
                  <template v-if="tour.currentStep === 1">
                    <div slot="content">
                      <h3>Settings | Basic Information Step 1 of 4 <img @click="tour.stop"
                          src="resources/assets/crossicon.png" /></h3>
                      <p class="vue-para"> Click edit below to update your information</p>
                    </div>

                    <div slot="actions">
                      <!-- <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button> -->
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 2">
                    <div slot="content">
                      <h3> Send Your First Review! Step 2 of 4 <img @click="tour.stop"
                          src="resources/assets/crossicon.png" /></h3>
                      <p class="vue-para"> It's time to send your first review! It might be a good idea to send it to
                        yourself first to make sure your communications settings are what you wanted. You can also
                        schedule review requests to be sent later here as well as mass upload people to send reviews to!
                      </p>
                    </div>
                    <div slot="actions">
                      <!-- <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button> -->
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 3">
                    <div slot="content">
                      <h3>Feedback Page Step 3 of 4 <img @click="tour.stop" src="resources/assets/crossicon.png" />
                      </h3>
                      <p class="vue-para"> Don't forget to regularly check your feedback page for negative reviews that
                        came back. Click the "eye" icon to assign to see what they said and then "view" to respond to
                        it. Remember, the quicker you deal with negative reviews the more likely they won't make it
                        public. You can also thank the people who left great reviews left here!</p>
                    </div>
                    <div slot="actions">
                      <!-- <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button> -->
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 4">
                    <div slot="content">
                      <h3>Full Review Page Step 4 of 4 <img @click="tour.stop" src="resources/assets/crossicon.png" />
                      </h3>
                      <p class="vue-para"> Not every review you get will be because you requested it! Check this page
                        frequently to see new reviews and easily assign them to followed up with by one of your team
                        members! Reviewzy will go fetch new reviews once per day automatically however, you can always
                        click "refresh" to bring in new reviews whenever you'd like.</p>
                    </div>
                    <div slot="actions">
                      <!-- <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button> -->
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.stop; stopWizard()" class="tourButton">Finish Wizard</button>
                    </div>
                  </template>
                </v-step>
              </transition>
            </template>
          </v-tour>
        </transition>
        <transition name="modal" v-if="tour">
          <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks">
            <template slot-scope="tour" style="background: #fff; color: #000">
              <transition name="fade">
                <v-step class="v-step" v-if="tour.steps[tour.currentStep]" :key="tour.currentStep"
                  :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep" :next-step="tour.nextStep"
                  :stop="tour.stop" :skip="tour.skip" :is-first="tour.isFirst" :is-last="tour.isLast"
                  :labels="tour.labels">
                  <template v-if="tour.currentStep === 0">
                    <div slot="content">
                      <h1>Welcome to Reviewzy! <img @click="tour.stop" src="resources/assets/crossicon.png"
                          v-if="wizard" /></h1>
                      <p class="vue-para"> You are just a few steps away from improving your online reputation and
                        gaining more positive reviews. Follow the steps to easily set up your account and begin sending
                        reviews!.</p>
                    </div>
                    <div slot="actions">
                      <button @click="tour.stop" class="tourButton">Maybe Later</button>
                      <button @click="tour.nextStep" class="tourButton">Get Started</button>
                    </div>
                  </template>
                  <template v-if="tour.currentStep === 1">
                    <div slot="content">
                      <h3>Settings | Basic Information Step 1 of 10 <img @click="tour.stop"
                          src="resources/assets/crossicon.png" v-if="wizard" /></h3>
                      <p class="vue-para"> Click edit below to update your information</p>
                    </div>

                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>
                  <template v-if="tour.currentStep === 2">
                    <div slot="content">
                      <h3>Connect Google Step 2 of 10
                        <img @click="tour.stop" src="resources/assets/crossicon.png" v-if="wizard" />
                      </h3>
                      <p class="vue-para"> Click connect to connect your google account for reviews! Once you log in
                        you'll be able to search for your business. Google will bring in your last 260 Reviews.</p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 3">
                    <div slot="content">
                      <h3>Connect Facebook Step 3 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png"
                          v-if="wizard" />
                      </h3>
                      <p class="vue-para"> Click connect to connect your Facebook Account Business Page</p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 4">
                    <div slot="content">
                      <h3>Connect Yelp Step 4 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png"
                          v-if="wizard" /></h3>
                      <p class="vue-para"> Connect Your Yelp Account. Note the format below and select the address. Yelp
                        will bring in your last 3 new reviews. Don't worry, more will come in after requesting them!
                        Example <a href="https://www.yelp.com/biz/yourprofilename"></a></p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 5">
                    <div slot="content">
                      <h3>Connect Zillow Step 5 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png"
                          v-if="wizard" />
                      </h3>
                      <p class="vue-para"> Connect Your zillow Account. Please Note the format below. Zillow will bring
                        in your last 3 reviews but don't worry more will come in after you request them! Example <a
                          href="https://www.zillow.com/profile/yourprofilename"></a></p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 6">
                    <div slot="content">
                      <h3>Communication Settings Step 6 of 10 <img @click="tour.stop"
                          src="resources/assets/crossicon.png" v-if="wizard" /></h3>
                      <p class="vue-para">Decide how you will communicate with your customers and upload a custom logo
                        to be displayed on your review request form! We highly recommend watching the short step
                        tutorial video before filling out this page.</p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 7">
                    <div slot="content">
                      <h3>Add some Users Step 7 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png"
                          v-if="wizard" />
                      </h3>
                      <p class="vue-para"> Now it's time to add some of your team to give them a custom dashboard and
                        allow them to send reviews too! Make sure to let them know their login credentials after you
                        create them! If it's just you, go ahead and click next step.</p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 8">
                    <div slot="content">
                      <h3> Send Your First Review! Step 8 of 10 <img @click="tour.stop"
                          src="resources/assets/crossicon.png" v-if="wizard" /></h3>
                      <p class="vue-para"> It's time to send your first review! It might be a good idea to send it to
                        yourself first to make sure your communications settings are what you wanted. You can also
                        schedule review requests to be sent later here as well as mass upload people to send reviews to!
                      </p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 9">
                    <div slot="content">
                      <h3>Feedback Page Step 9 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png"
                          v-if="wizard" />
                      </h3>
                      <p class="vue-para"> Don't forget to regularly check your feedback page for negative reviews that
                        came back. Click the "eye" icon to assign to see what they said and then "view" to respond to
                        it. Remember, the quicker you deal with negative reviews the more likely they won't make it
                        public. You can also thank the people who left great reviews left here!</p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.nextStep" class="tourButton">Next Step</button>
                    </div>
                  </template>

                  <template v-if="tour.currentStep === 10">
                    <div slot="content">
                      <h3>Full Review Page Step 10 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png"
                          v-if="wizard" />
                      </h3>
                      <p class="vue-para"> Not every review you get will be because you requested it! Check this page
                        frequently to see new reviews and easily assign them to followed up with by one of your team
                        members! Reviewzy will go fetch new reviews once per day automatically however, you can always
                        click "refresh" to bring in new reviews whenever you'd like.</p>
                    </div>
                    <div slot="actions">
                      <button @click="tutorialVideo(tour.currentStep)" class="tourButton">Tutorial Video</button>
                      <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                      <button @click="tour.stop; stopWizard()" class="tourButton">Finish Wizard</button>
                    </div>
                  </template>
                </v-step>
              </transition>
            </template>
          </v-tour>
        </transition>
        <div v-if="show == 'dashboard'">
          <main class="py-6">
            <div class="container">
              <div id="v-step-0"></div>
              <div class="v-step-0"></div>
              <form class="d-block d-flex connectMessage" v-if="googleRecentReview.length == 0 &&
              fbRecentReview.length == 0 &&
              yelpRecentReview.length == 0 &&
              zillowRecentReview.length == 0">
                <div class="card border-0 mb-7 table-align" id="userForDiv" style="width: 100vw;">
                  <div class="table-outer-wrap">
                    <div class="inner-white-wrap">
                      <div class="table-responsive">
                        <h1 v-if="isAdmin == true">
                          No Platform Connected, Please first connect platform to see dashboard data.
                        </h1>
                        <h1 v-else>You are logged in!</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <!-- Card stats -->
              <div class="row g-6 mb-6" v-if="googleRecentReview.length > 0">
                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">
                  <div>
                    <div class="col-md-5 pt-4">
                      <p class="review-journey">Review Journey</p>
                    </div>
                    <div class="col-md-7 pt-4">
                      <div class="card-body text-center rating-wrap">
                        <img src="resources/assets/googleLogo.png" />
                        <p>
                          <img v-for="index in 5" :key="index + 'yellow'" src="resources/assets/yellowStar.png" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  googleAllReview ? googleAllReview : ""
                              }}</span>&nbsp;Reviews
                            </h2>
                            <div class="inner-head-wrap">
                              <p class="dashboard-review-star">
                                <img src="resources/assets/yellowStar.png" />
                                &nbsp;{{
                                    googleAllReviewRating
                                      ? googleAllReviewRating
                                      : ""
                                }}
                              </p>
                            </div>
                            <p class="date-color">
                              Started: {{ googleStarted }}
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  googleCurrentReview ? googleCurrentReview : ""
                              }}</span>&nbsp;Reviews This Period
                            </h2>
                            <div class="inner-head-wrap">
                              <p class="dashboard-review-star">
                                <img src="resources/assets/yellowStar.png" />
                                &nbsp;{{
                                    googleCurrentReviewRating
                                      ? googleCurrentReviewRating
                                      : ""
                                }}
                              </p>
                            </div>
                            <p class="date-color">Current</p>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-all-perc">
                                {{ googleAllTime }}
                              </p>
                            </div>
                            <p class="date-color">All Time Growth</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-month-perc">
                                {{ googleThisMonth }}
                              </p>
                            </div>
                            <p class="date-color">Growth This Period</p>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="dashboard-graph-card dv-card">
                            <div class="col-12 mb-4 mb-sm-0">
                              <p class="dashboard-recent-reviews" style="margin: 10px">
                                New Reviews
                              </p>
                            </div>
                            <div id="chart">
                              <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                              <button type="button" style="margin: 15px auto" class="d-flex view-more-btn"
                                @click="showReport('google')">
                                <a style="margin: 0 auto; text-decoration:none">
                                  <p style="background: white; color: #00053c;margin: 10px auto">
                                    View More
                                  </p>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="dashboard-review-card dv-card">
                        <div class="col-12 mb-4 mb-sm-0">
                          <p class="dashboard-recent-reviews" style="margin: 10px">
                            Recent Reviews
                          </p>
                        </div>

                        <div class="row" v-for="googleReview in googleRecentReview" :key="googleReview.id">
                          <div class="col-12 d-flex">
                            <div v-if="googleReview.reviewAuthorImage">
                              <img style="margin: 10px" alt="..." :src="googleReview.reviewAuthorImage" class="
                                  avatar avatar-xl
                                  rounded-circle
                                  me-2
                                  loginPic
                                " />
                            </div>

                            <div>
                              <div class="
                                  customer-name
                                  form-outline form-white
                                  inner-white
                                ">
                                <h3 class="heading">
                                  {{
                                      googleReview.reviewAuthor
                                        ? googleReview.reviewAuthor
                                        : ""
                                  }}
                                </h3>
                              </div>
                            </div>
                            <div class="col-6" style="position: relative">
                              <h3 class="heading review-star">
                                <img v-for="index in parseInt(
                                  googleReview.reviewRating
                                )" :key="index + 'yello'" src="resources/assets/yellowStar.png" />
                                <img v-for="index in 5 -
                                parseInt(googleReview.reviewRating)" :key="index + 'blank'"
                                  src="resources/assets/blankStar.png" />
                              </h3>
                              <p class="review-time">{{ googleReview.reviewDate }}</p>
                            </div>
                          </div>

                          <div class="col-12 mb-4 mb-sm-0 d-flex">
                            <div class="form-outline form-white inner-white" style="margin-left: 20%"></div>
                            <div class="form-outline form-white">
                              <p class="communicationFormLabel">
                                {{
                                    googleReview.reviewText
                                      ? googleReview.reviewText
                                      : ""
                                }}
                              </p>
                            </div>
                          </div>

                          <!-- <div class="card border-0 feeback-action col-9" style="margin-left: auto; margin-right: 20px">
                            <div class="mb-4 mb-sm-0 d-flex">
                              <div class="form-outline form-white" style="width: 49%; padding: 10px">
                                <p class="communicationFormLabel">2 Reviews</p>
                              </div>
                              <div class="form-outline form-white" style="width: 51%; padding: 10px">
                                <p class="communicationFormLabel" style="justify-content: end">
                                  a week ago
                                </p>
                              </div>
                            </div>
                            <div class="mb-4 mb-sm-0 d-flex">
                              <div class="form-outline form-white">
                                <p class="heading">Response from the owner</p>
                              </div>
                            </div>
                            <div class="mb-4 mb-sm-0 d-flex">
                              <div class="form-outline form-white">
                                <p class="communicationFormLabel">
                                  Lorem ipsum dolor sit, amet consectetur
                                  adipisicing elit. Quam in reprehenderit neque
                                  odit quas fuga itaque, nihil aliquid quasi
                                  modi architecto, vitae eveniet ratione
                                  officia.
                                </p>
                              </div>
                            </div>
                          </div> -->
                        </div>

                        <button type="button" style="margin: 15px auto" class="d-flex view-more-btn">
                          <a style="margin: 0 auto; text-decoration:none" :href="googleView" target="_blank">
                            <p style="background: white; color: #00053c;margin: 10px auto">
                              View on Google
                            </p>
                          </a>
                        </button>
                        <hr size="2" />
                      </div>
                    </div>
                  </div>
                  <button @click="showReport()" type="button" style="margin: 15px auto"
                    class="d-flex platFormCardButton">
                    <p class="buttonText" style="margin: 10px auto">
                      View Full Report
                    </p>
                  </button>
                </div>
              </div>

              <div class="row g-6 mb-6" v-if="fbRecentReview.length > 0">
                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">

                  <div>
                    <div class="col-md-5 pt-4">
                      <p class="review-journey">Review Journey</p>
                    </div>
                    <div class="col-md-7 pt-4">
                      <div class="card-body text-center rating-wrap">
                        <img src="resources/assets/facebookLogo.png" />
                        <p>
                          <img v-for="index in 5" :key="index + 'fb'" src="resources/assets/fb-star.svg" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  fbAllReview
                              }}</span>&nbsp;Reviews
                            </h2>
                            <div class="inner-head-wrap">
                              <p>
                                <span class="fbRatingStar">
                                  <img src="resources/assets/rate_star_icon.svg" />&nbsp;
                                  {{
                                      fbAllReviewRating ? fbAllReviewRating : 0
                                  }}
                                  &nbsp;</span>
                              </p>
                              <p class="dashboard-review-text">
                                out of &nbsp;<span class="dashboard-review-count">5</span>
                              </p>
                            </div>
                            <p class="date-color">
                              Started: {{ fbStarted ? fbStarted : "" }}
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  fbCurrentReview
                              }}</span>&nbsp;Reviews This Period
                            </h2>
                            <div class="inner-head-wrap">
                              <p>
                                <span class="fbRatingStar">
                                  <img src="resources/assets/rate_star_icon.svg" />&nbsp;
                                  {{
                                      fbCurrentReviewRating
                                        ? fbCurrentReviewRating
                                        : 0
                                  }}
                                  &nbsp;</span>
                              </p>
                              <p class="dashboard-review-text">
                                out of &nbsp;<span class="dashboard-review-count">5</span>
                              </p>
                            </div>
                            <p class="date-color">Current</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-all-perc">{{ fbAllTime }}</p>
                            </div>
                            <p class="date-color">All Time Growth</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-month-perc">
                                {{ fbThisMonth }}
                              </p>
                            </div>
                            <p class="date-color">Growth This Period</p>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="dashboard-graph-card dv-card">
                            <div class="col-12 mb-4 mb-sm-0">
                              <p class="dashboard-recent-reviews" style="margin: 10px">
                                New Reviews
                              </p>
                            </div>
                            <div id="chart">
                              <apexchart type="line" height="350" :options="chartOptions_fb" :series="series_fb">
                              </apexchart>

                              <button type="button" style="margin: 15px auto" class="d-flex view-more-btn"
                                @click="showReport('facebook')">
                                <a style="margin: 0 auto; text-decoration:none">
                                  <p style="background: white; color: #00053c;margin: 10px auto">
                                    View More
                                  </p>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="dashboard-review-card dv-card">
                        <div class="col-12 mb-4 mb-sm-0">
                          <p class="dashboard-recent-reviews" style="margin: 10px">
                            Recent Reviews
                          </p>
                        </div>

                        <!-- <div class="row d-flex">
                          <div class="inner-head-wrap">
                            <p class="dashboard-review-star">
                              <span class="fbRatingStar" style="width: 100px">
                                <img src="resources/assets/rate_star_icon.svg" />&nbsp; 4.8 &nbsp;</span>
                            </p>
                            <p class="dashboard-review-text" style="width: 100%">
                              out of &nbsp;<span class="dashboard-review-count">5 &nbsp;</span>•&nbsp;
                              <span class="dashboard-review-count">136 Reviews</span>
                            </p>
                          </div>
                        </div>
                        <hr /> -->
                        <div class="row" v-for="fbReview in fbRecentReview" :key="fbReview.id">
                          <div class="col-md-6 d-flex">
                            <div v-if="fbReview.reviewAuthorImage">
                              <img style="margin: 10px" alt="..." :src="fbReview.reviewAuthorImage" class="
                                    avatar avatar-xl
                                    rounded-circle
                                    me-2
                                    loginPic
                                  " />
                            </div>

                            <div class="
                                  customer-name
                                  form-outline form-white
                                  inner-white
                                  star-wrapper
                                ">
                              <h3 class="heading">
                                {{
                                    fbReview.reviewAuthor
                                      ? fbReview.reviewAuthor
                                      : ""
                                }}
                              </h3>
                            </div>
                            <p>
                              <span class="fbRatingStar">
                                <img src="resources/assets/rate_star_icon.svg" />&nbsp;
                                {{
                                    fbReview.reviewRating
                                      ? fbReview.reviewRating
                                      : 0
                                }}
                                &nbsp;</span>
                            </p>
                          </div>
                          <div class="col-12 mb-4 mb-sm-0 d-flex">
                            <div class="form-outline form-white inner-white" style="margin-left: 5%"></div>
                            <div class="form-outline form-white">
                              <p class="communicationFormLabel">
                                {{
                                    fbReview.reviewText
                                      ? fbReview.reviewText
                                      : ""
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <button type="button" style="margin: 15px auto" class="d-flex view-more-btn">
                          <a style="margin: 0 auto; text-decoration:none" :href="fbView" target="_blank">
                            <p style="background: white; color: #00053c;margin: 10px auto">
                              View on Facebook
                            </p>
                          </a>
                        </button>
                        <hr size="2" />
                      </div>
                    </div>
                    <button @click="showReport()" type="button" style="margin: 15px auto"
                      class="d-flex platFormCardButton">
                      <p class="buttonText" style="margin: 10px auto">
                        View Full Report
                      </p>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row g-6 mb-6" v-if="yelpRecentReview.length > 0">
                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">
                  <div>
                    <div class="col-md-5 pt-4">
                      <p class="review-journey">Review Journey</p>
                    </div>
                    <div class="col-md-7 pt-4">
                      <div class="card-body text-center rating-wrap">
                        <img src="resources/assets/yellpLogo.png" />
                        <p>
                          <img v-for="index in 5" :key="index + 'yelp'" src="resources/assets/yelp-star.svg" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  yelpAllReview
                              }}</span>&nbsp;Reviews
                            </h2>
                            <div class="inner-head-wrap">
                              <p class="dashboard-review-star">
                                <img src="resources/assets/yelp-star.svg" />&nbsp;
                                {{
                                    yelpAllReviewRating ? yelpAllReviewRating : ""
                                }}
                              </p>
                            </div>
                            <p class="date-color">Started: {{ yelpStarted }}</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  yelpCurrentReview ? yelpCurrentReview : ""
                              }}</span>&nbsp;Reviews This Period
                            </h2>
                            <div class="inner-head-wrap">
                              <p class="dashboard-review-star">
                                <img src="resources/assets/yelp-star.svg" />&nbsp;
                                {{
                                    yelpCurrentReviewRating
                                      ? yelpCurrentReviewRating
                                      : ""
                                }}
                              </p>
                            </div>
                            <p class="date-color">Current</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-all-perc">
                                {{ yelpAllTime }}
                              </p>
                            </div>
                            <p class="date-color">All Time Growth</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-month-perc">
                                {{ yelpThisMonth }}
                              </p>
                            </div>
                            <p class="date-color">Growth This Period</p>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="dashboard-graph-card dv-card">
                            <div class="col-12 mb-4 mb-sm-0">
                              <p class="dashboard-recent-reviews" style="margin: 10px">
                                New Reviews
                              </p>
                            </div>
                            <div id="chart">
                              <apexchart type="line" height="350" :options="chartOptions_yelp" :series="series_yelp">
                              </apexchart>
                              <button type="button" style="margin: 15px auto" class="d-flex view-more-btn"
                                @click="showReport('yelp')">
                                <a style="margin: 0 auto; text-decoration:none">
                                  <p style="background: white; color: #00053c;margin: 10px auto">
                                    View More
                                  </p>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5 dv-card">
                      <div class="dashboard-review-card">
                        <div class="col-12 mb-4 mb-sm-0">
                          <p class="dashboard-recent-reviews" style="margin: 10px">
                            Recent Reviews
                          </p>
                        </div>

                        <div class="row" v-for="yelpReview in yelpRecentReview" :key="yelpReview.id">
                          <div class="col-md-6 d-flex">
                            <div v-if="yelpReview.reviewAuthorImage">
                              <img style="margin: 10px" alt="..." src="" class="
                                  avatar avatar-xl
                                  rounded-circle
                                  me-2
                                  loginPic
                                " />
                            </div>

                            <div class="
                                customer-name
                                form-outline form-white
                                inner-white
                                star-wrapper
                              ">
                              <h3 class="heading">
                                {{
                                    yelpReview.reviewAuthor
                                      ? yelpReview.reviewAuthor
                                      : ""
                                }}
                              </h3>
                              <p>
                                <img v-for="index in 5" :key="index + 'yelp'" src="resources/assets/yelp-star.svg" />
                              </p>
                            </div>
                          </div>
                          <div class="col-12 mb-4 mb-sm-0 d-flex">
                            <div class="form-outline form-white inner-white" style="margin-left: 5%"></div>
                            <div class="form-outline form-white">
                              <p class="communicationFormLabel">
                                {{
                                    yelpReview.reviewText
                                      ? yelpReview.reviewText
                                      : ""
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <button type="button" style="margin: 15px auto" class="d-flex view-more-btn">
                          <a style="margin: 0 auto; text-decoration:none" :href="yelpView" target="_blank">
                            <p style="background: white; color: #00053c;margin: 10px auto">
                              View on Yelp
                            </p>
                          </a>
                        </button>
                        <hr size="2" />
                      </div>
                    </div>
                  </div>
                  <button @click="showReport()" type="button" style="margin: 15px auto"
                    class="d-flex platFormCardButton">
                    <p class="buttonText" style="margin: 10px auto">
                      View Full Report
                    </p>
                  </button>
                </div>
              </div>

              <div class="row g-6 mb-6" v-if="zillowRecentReview.length > 0">
                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">
                  <div>
                    <div class="col-md-5 pt-4">
                      <p class="review-journey">Review Journey</p>
                    </div>
                    <div class="col-md-7 pt-4">
                      <div class="card-body text-center rating-wrap">
                        <img src="resources/assets/zillowLogo.png" />
                        <p>
                          <img v-for="index in 5" :key="index + 'zillow'" src="resources/assets/zillow-star.svg" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  zillowAllReview
                              }}</span>&nbsp;Reviews
                            </h2>
                            <div class="inner-head-wrap">
                              <p class="dashboard-review-star">
                                <img src="resources/assets/zillow-star.svg" />&nbsp;
                                {{
                                    zillowAllReviewRating
                                      ? zillowAllReviewRating
                                      : ""
                                }}
                              </p>
                            </div>
                            <p class="date-color">
                              Started: {{ zillowStarted ? zillowStarted : "" }}
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="dashboard-view-card dv-card">
                            <h2 class="dashboard-review-text">
                              <span class="dashboard-review-count">{{
                                  zillowCurrentReview ? zillowCurrentReview : ""
                              }}</span>&nbsp;Reviews This Period
                            </h2>
                            <div class="inner-head-wrap">
                              <p class="dashboard-review-star">
                                <img src="resources/assets/zillow-star.svg" />&nbsp;
                                {{
                                    zillowCurrentReviewRating
                                      ? zillowCurrentReviewRating
                                      : ""
                                }}
                              </p>
                            </div>
                            <p class="date-color">Current</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-all-perc">
                                {{ zillowAllTime }}
                              </p>
                            </div>
                            <p class="date-color">All Time Growth</p>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="
                              dashboard-view-card dashboard-per-card
                              dv-card
                            ">
                            <div class="inner-head-wrap">
                              <p class="dashboard-month-perc">
                                {{ zillowThisMonth }}
                              </p>
                            </div>
                            <p class="date-color">Growth This Period</p>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="dashboard-graph-card dv-card">
                            <div class="col-12 mb-4 mb-sm-0">
                              <p class="dashboard-recent-reviews" style="margin: 10px">
                                New Reviews
                              </p>
                            </div>
                            <div id="chart">
                              <apexchart type="line" height="350" :options="chartOptions_zillow"
                                :series="series_zillow">
                              </apexchart>
                              <button type="button" style="margin: 15px auto" class="d-flex view-more-btn"
                                @click="showReport('zillow')">
                                <a style="margin: 0 auto; text-decoration:none">
                                  <p style="background: white; color: #00053c;margin: 10px auto">
                                    View More
                                  </p>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="dashboard-review-card dv-card">
                        <div class="col-12 mb-4 mb-sm-0">
                          <p class="dashboard-recent-reviews" style="margin: 10px">
                            Recent Reviews
                          </p>
                        </div>

                        <div class="row" v-for="zillowReview in zillowRecentReview" :key="zillowReview.id">
                          <div class="col-12 d-flex">
                            <div v-if="zillowReview.reviewAuthorImage">
                              <img style="margin: 10px" alt="..." src="" class="
                                      avatar avatar-xl
                                      rounded-circle
                                      me-2
                                      loginPic
                                    " />
                            </div>

                            <div class="
                                    customer-name
                                    form-outline form-white
                                    inner-white
                                    star-wrapper
                                  ">
                              <h3 class="heading">
                                {{
                                    zillowReview.reviewAuthor
                                      ? zillowReview.reviewAuthor
                                      : ""
                                }}
                              </h3>
                              <p>
                                <img v-for="index in 5" :key="index + 'zillow'"
                                  src="resources/assets/zillow-star.svg" />
                              </p>
                            </div>
                          </div>
                          <div class="col-12 mb-4 mb-sm-0 d-flex">
                            <div class="form-outline form-white inner-white" style="margin-left: 5%"></div>
                            <div class="form-outline form-white">
                              <p class="communicationFormLabel">
                                {{
                                    zillowReview.reviewText
                                      ? zillowReview.reviewText
                                      : ""
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <button type="button" style="margin: 15px auto" class="d-flex view-more-btn">
                          <a style="margin: 0 auto; text-decoration:none" :href="zillowView" target="_blank">
                            <p style="background: white; color: #00053c;margin: 10px auto">
                              View on Zillow
                            </p>
                          </a>
                        </button>
                        <hr size="2" />
                      </div>
                    </div>
                  </div>
                  <button @click="showReport()" type="button" style="margin: 15px auto"
                    class="d-flex platFormCardButton">
                    <p class="buttonText" style="margin: 10px auto">
                      View Full Report
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'users'">
          <main class="py-6">
            <div class="row align-items-center">
              <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                <div class="card-header" style="background: none; border: none">
                  <h2 class="mb-0">Users</h2>
                </div>
              </div>
              <div class="col-md-9 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                <button class="createButton" @click="userModalOpen" :class="{ squareWizard: userCreateIcon }">
                  + &ensp; Create New
                </button>
                <div id="v-step-7" class="mb-0 profileSettingHeading"></div>
              </div>
            </div>
            <transition name="modal" v-if="userModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container user-modal">
                    <div>
                      <form class="d-block">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1 class="user-modal-heading">
                            {{ userModalHeading }}
                          </h1>
                          <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="userForm.firstName" type="text" id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="First Name" style="padding-left: 25px" />
                            </div>
                          </div>
                          <div class="col-md-6 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="userForm.lastName" type="text" id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Last Name" style="padding-left: 25px" />
                            </div>
                          </div>
                        </div>
                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="userForm.email" type="text" id="form3Examplea2" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Email Address" style="padding-left: 25px" />
                          </div>
                        </div>
                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="userForm.password" :type="passwordFieldType" id="form3Examplea2" class="
                                form-control form-control1
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Password" style="padding-left: 25px" @keydown.space.prevent />
                            <span @click="switchVisibility()">
                              <i :class="passwordIcon" style="font-size: 18px"></i>
                            </span>
                          </div>
                        </div>
                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="userForm.confirmPassword" :type="cPasswordFieldType" id="form3Examplea2"
                              class="
                                form-control form-control1
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Confirm Password" style="padding-left: 25px" @keydown.space.prevent />
                            <span @click="cSwitchVisibility()">
                              <i :class="cPasswordIcon" style="font-size: 18px"></i>
                            </span>
                          </div>
                        </div>

                        <button v-if="buttonText == 'Create'" type="button" class="loginButton" @click="createUser()"
                          style="height: 45px">
                          <p style="height: 30px" class="loginButtonText">
                            Create User
                          </p>
                        </button>
                        <button v-else type="button" class="loginButton" @click="updateUser()" style="height: 45px">
                          <p style="height: 30px" class="loginButtonText">
                            Update User
                          </p>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="vSteps">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container modal-video" style="background: none;box-shadow:none">
                    <div>
                      <div class="d-flex mb-2" style="justify-content: end;">
                        <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                      </div>
                      <div class="table-responsive">
                        <div class="plyr__video-embed" id="player">
                          <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen
                            allowtransparency allow="autoplay"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="card border-0 mb-7 table-align" id="userForDiv">
              <div class="table-outer-wrap">
                <div class="inner-white-wrap">
                  <ActionBar @updateList="updateListing($event)" :selected-users="selectedUsers" :filter-options="[]"
                    :actionType="'users'" :dateStyle="false" />
                  <div class="table-responsive">
                    <table class="table table-hover table-nowrap formtable">
                      <thead class="thead-light">
                        <tr class="header">
                          <th class="heading" scope="col" style="padding-left: 15px">
                            <div class="
                                custom-control custom-checkbox
                                checkbox-lg
                                form-check-inline
                              ">
                              <input type="checkbox" class="custom-control-input" @change="pushAllSelectedUsers($event)"
                                id="all" />
                              <label class="custom-control-label checkLabel" for="all"></label>
                            </div>
                          </th>
                          <th class="heading" scope="col">Date</th>
                          <th class="heading" scope="col">User Name</th>
                          <th class="heading" scope="col">Email</th>
                          <th class="heading" scope="col">Permission</th>
                          <th class="heading" scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="userList.length > 0">
                        <tr v-for="(users) in userList" v-bind:key="users.id">
                          <td>
                            <div class="
                                custom-control custom-checkbox
                                checkbox-lg
                                form-check-inline
                              ">
                              <input :id="users.id" type="checkbox" class="custom-control-input"
                                @change="pushSelectedUsers($event, users.id)" />
                              <label class="custom-control-label checkLabel" :for="users.id"></label>
                            </div>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#">
                              {{
                                  moment(String(users.created_at)).format(
                                    "MM-DD-YYYY"
                                  )
                              }}
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#">
                              {{ ` ${users.first_name} ${users.last_name}` }}
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#">
                              {{ users.email }}
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#">
                              {{ users.isAdmin === 1 ? "Admin" : "User" }}
                            </a>
                          </td>
                          <td>
                            <a href="#" class="btn btn-sm btn-neutral" @click="editUser(users.id)"
                              style="background: none"><img src="resources/assets/edit_pen_icon.svg" /></a>
                            <a href="#" @click="deleteUser(users.id)" class="btn btn-sm btn-neutral"
                              style="background: none">
                              <img src="resources/assets/trash_icon.svg" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="6" style="text-align: center; font-weight: 600">
                            <button class="createButton" style="height: 45px" @click="userModalOpen">
                              + &ensp; Create New
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="usersCount > usersPerPage" class="admin-new-pagination role-table-pagination">
                    <b-pagination v-model="usersCurrentPage" :total-rows="usersCount" :per-page="usersPerPage"
                      @change="showUserPage" first-number last-number align="center" pills size="lg"></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'reviews'">
          <main class="py-6">
            <div class="row align-items-center">
              <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                <div class="card-header" style="background: none; border: none">
                  <h2 class="mb-0">Review</h2>
                </div>
              </div>
              <div class="col-md-9 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                <div id="v-step-10" class="mb-0 profileSettingHeading"></div>
                <div class="mb-0 profileSettingHeading v-step-4"></div>
              </div>
            </div>
            <transition name="modal" v-if="reviewModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div>
                      <form class="d-block">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1></h1>
                          <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                        </div>
                        <div class="row" style="margin: 0px -23px">
                          <div class="col-md-6 mb-4 pb-2" style="background: #f4f5fa; border-radius: 15px">
                            <div>
                              <div class="
                                  form-outline form-white
                                  viewReviewInnerBox
                                " style="
                                  border-radius: 15px;
                                  padding: 25px 0px;
                                  margin-top: 20px;
                                ">
                                <img v-if="currentReview.reviewAuthorImage" alt="..."
                                  :src="currentReview.reviewAuthorImage" class="
                                    avatar avatar-xl
                                    me-2
                                    viewReviewInnerBoxImg
                                  " />
                                <!-- <img v-else alt="" src="" class="viewReviewInnerBoxImg" style="margin-right: 60px" /> -->
                                <a class="reviewModalName">{{ currentReview.reviewAuthor }}
                                </a>
                                <span class="ratingSpan" :class="{
                                  googleStarColor: googleColor,
                                  fbStarColor: fbColor,
                                  yelpStarColor: yelpColor,
                                  zillowStarColor: zillowColor,
                                }">
                                  <img src="resources/assets/rate_star_icon.svg" />&nbsp;
                                  {{
                                      currentReview.reviewRating
                                        ? currentReview.reviewRating
                                        : 0
                                  }}
                                  &nbsp;</span>
                                <div class="text-width" style="
                                    width: 90%;
                                    margin-left: 20px;
                                    background: white;
                                  ">
                                  <span class="viewReviewInnerBoxPara">
                                    {{ currentReview.reviewText }}</span>
                                  <br />
                                  <br />
                                  <span class="viewReviewInnerBoxPara">
                                    {{
                                        moment(
                                          String(currentReview.rawDate)
                                        ).format("MMM DD, YYYY")
                                    }}
                                    <!-- • 11 Reviews -->
                                  </span>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="detail-wrap col-md-5 mb-4 pb-2" style="margin-left: 35px">
                            <div class="row align-items-center">
                              <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                <p class="
                                    d-flex
                                    align-items-center
                                    viewReviewSecondPartTopText
                                  ">
                                  {{
                                      moment(
                                        String(currentReview.rawDate)
                                      ).format("MM/DD/YYYY")
                                  }}
                                </p>
                              </div>
                              <!-- Actions -->
                              <div class="
                                  col-md-8 col-12
                                  mb-4 mb-sm-0
                                  d-flex
                                  justify-content-end
                                ">
                                <p class="
                                    d-flex
                                    align-items-center
                                    viewReviewSecondPartTopText
                                  " style="text-transform: capitalize">
                                  {{ currentReview.platformName }}
                                </p>
                              </div>
                            </div>
                            <div class="row align-items-center">
                              <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                <p class="
                                    d-flex
                                    align-items-center
                                    viewReviewSecondPartTopName
                                  ">
                                  {{ adminDetails.firstName }}
                                </p>
                              </div>
                              <!-- Actions -->
                              <div class="
                                  col-md-8 col-12
                                  mb-4 mb-sm-0
                                  d-flex
                                  justify-content-end
                                ">
                                <p class="d-flex align-items-center" v-if="currentReview.reviewRating">
                                  <img v-for="index in parseInt(
                                    currentReview.reviewRating
                                  )" :key="index + 'yellow'" src="resources/assets/yellowStar.png" />
                                  <img v-for="index in 5 -
                                  parseInt(currentReview.reviewRating)" :key="index + 'blank'"
                                    src="resources/assets/blankStar.png" />
                                </p>
                                <p v-else>
                                  <img v-for="index in 5" :key="index + 'blank'" src="resources/assets/blankStar.png" />
                                </p>
                              </div>
                            </div>
                            <div class="form-outline form-white"></div>
                            <button @click="respondNow(currentReview)" type="button" class="loginButton"
                              style="height: 45px">
                              <p style="height: 35px" class="loginButtonText">
                                Respond Now
                              </p>
                            </button>
                            <div class="viewReviewSecondPartdropdown">
                              <select class="
                                  viewReviewSecondPartdropdownText
                                  inputOrange
                                  assignFollowUp
                                  form-select
                                " v-model="userAssignId" @change="onAssignUser($event)" v-validate="'required'"
                                v-bind:name="'userAssign'" v-bind:ref="'userAssign'" :class="{
                                  'is-invalid': errors.has('userAssign'),
                                }">
                                <option class="viewReviewSecondPartdropdownText" value="" disabled selected>
                                  {{
                                      `${reviewUserList.length > 0
                                        ? "Assign for Follow-Up"
                                        : "No User Found."
                                      }`
                                  }}
                                </option>
                                <option class="viewReviewSecondPartdropdownText" :value="user.id"
                                  v-for="user in reviewUserList" :key="user.id">
                                  {{
                                      `${user.first_name} ${user.last_name ? user.last_name : ""
                                      }`
                                  }}
                                </option>
                              </select>
                            </div>
                            <textarea v-model="followUpNotes" id="form3Examplea2" placeholder="Follow-Up Notes"
                              class="viewReviewSecondPartTextArea inputOrange"
                              style="width: 100%; padding: 15px"></textarea>
                            <button type="button" class="loginButton" style="
                                border: 1px solid #00053c;
                                background: white;
                                height: 45px;
                              " @click="saveReviewFeedback(currentReview)">
                              <p class="loginButtonText" style="color: #00053c">
                                Save
                              </p>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="userListData">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div>
                      <UsersModal @closeModal="closeM($event)" :actionType="'reviews'" @changeTitle="ChangeT($event)"
                        :selected="filterList.user" />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="customerReviewModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container-view">
                    <div>
                      <form>
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>Customer Details</h1>
                          <div class="d-flex">
                            <a v-if="!customerReviewModalEdit" v-b-tooltip.hover @click="editReview()"
                              style="cursor: pointer" title="Edit"><img src="resources/assets/editIcon.png" /></a>
                            <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                          </div>
                        </div>

                        <h1></h1>
                        <form class="d-block">
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> customerName</a>
                                  <br />
                                  <a v-if="customerReviewModalEdit && reviewViewData.platformName == 'facebook'"
                                    class="profileFormTitleValue">
                                    <input type="text" class="
                                        form-control
                                        userForm
                                        form-control-lg
                                        inputOrange
                                      " placeholder="Customer Name" v-model="reviewViewData.reviewAuthor"
                                      v-validate="'required'" v-bind:name="'reviewViewData.reviewAuthor'"
                                      v-bind:ref="'reviewViewData.reviewAuthor'" :class="{
                                        'is-invalid': errors.has(
                                          'reviewViewData.reviewAuthor'
                                        ),
                                      }" />
                                  </a>
                                  <a v-else class="profileFormTitleValue">
                                    {{ reviewViewData.reviewAuthor }}</a>

                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Rating</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{ reviewViewData.reviewRating }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Email</a>
                                  <br />
                                  <a v-if="customerReviewModalEdit" class="profileFormTitleValue">
                                    <input type="email" class="
                                        form-control
                                        userForm
                                        form-control-lg
                                        inputOrange
                                      " placeholder="Customer Email Address" v-model="reviewViewData.email"
                                      v-validate="'required'" v-bind:name="'reviewViewData.email'"
                                      v-bind:ref="'reviewViewData.email'" :class="{
                                        'is-invalid': errors.has(
                                          'reviewViewData.email'
                                        ),
                                      }" /></a>
                                  <a v-else class="profileFormTitleValue">
                                    {{ reviewViewData.email ? reviewViewData.email : "NA" }}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Country Code</a>
                                  <br />
                                  <a v-if="customerReviewModalEdit" class="profileFormTitleValue">
                                    <select class="
                                        viewReviewSecondPartdropdownText
                                        inputOrange
                                        assignFollowUp
                                        form-select
                                      " v-model="reviewCode" v-validate="'required'" v-bind:name="'requestData.code'"
                                      v-bind:ref="'requestData.code'" :class="{
                                        'is-invalid':
                                          errors.has('requestData.code'),
                                      }">
                                      <option class="viewReviewSecondPartdropdownText" value="">
                                        Select Country
                                      </option>
                                      <option class="viewReviewSecondPartdropdownText" :value="location.shortName"
                                        v-for="location in countryCodelist" :key="location.id">
                                        {{
                                            `${location.name} (+${location.code})`
                                        }}
                                      </option>
                                    </select>
                                  </a>
                                  <a v-else class="profileFormTitleValue">
                                    {{
                                        reviewCountryCode
                                          ? reviewCountryCode
                                          : "NA"
                                    }}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Number</a>
                                  <br />
                                  <a v-if="customerReviewModalEdit" class="profileFormTitleValue">
                                    <input type="number" class="
                                        form-control
                                        userForm
                                        form-control-lg
                                        inputOrange
                                      " placeholder="Customer Phone Number" v-model="reviewViewData.phone"
                                      v-validate="'required'" v-bind:name="'reviewViewData.phone'"
                                      v-bind:ref="'reviewViewData.phone'" :class="{
                                        'is-invalid': errors.has(
                                          'reviewViewData.phone'
                                        ),
                                      }" /></a>
                                  <a v-else class="profileFormTitleValue">
                                    {{
                                        reviewViewData.phone
                                          ? reviewViewData.phone
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Platform</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{ reviewViewData.platformName }}</a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <a v-if="customerReviewModalEdit" class="d-flex justify-content-end">
                            <button @click.prevent="updateCustomerData()" class="platFormCardButton"
                              style="width: 180px; margin-top: 15px">
                              Save
                            </button>
                          </a>
                        </form>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="vSteps">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container modal-video" style="background: none;box-shadow:none">
                    <div>
                      <div class="d-flex mb-2" style="justify-content: end;">
                        <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                      </div>
                      <div class="table-responsive">
                        <div class="plyr__video-embed" id="player">
                          <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen
                            allowtransparency allow="autoplay"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="card border-0 mb-7 table-align" id="userForDiv">
              <div class="table-outer-wrap">
                <div class="inner-white-wrap">
                  <ActionBar @changeTitle="ChangeT($event)" @updateList="updateListing($event)"
                    @callSync="syncPlatform($event)" :selected-users="selectedUsers" :filter-options="filterOptions"
                    :actionType="'reviews'" :dateStyle="true" :showRefresh="showRefresh" />

                  <div class="container">
                    <ul class="filterText inputOrange assignFilter">
                      <li class="filterText" v-for="(data, index) in filterList.platform" v-bind:key="index">
                        <a class="filter-bg-color">
                          {{ data }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)"
                            style="padding-left: 15px" />
                        </a>
                      </li>
                      <li class="filterText" v-for="(data, index) in filterList.ranking" v-bind:key="index">
                        <a class="filter-bg-color">
                          {{ data }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)"
                            style="padding-left: 15px" />
                        </a>
                      </li>
                      <li class="filterText" v-for="(data, index) in filterList.userName" v-bind:key="index">
                        <a class="filter-bg-color">
                          {{ `${data.first_name} ${data.last_name ? data.last_name : ""}` }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)"
                            style="padding-left: 15px" />
                        </a>
                      </li>
                      <li v-if="filterList.startDate" class="filterText">
                        <a class="filter-bg-color">
                          {{ `Startdate ${filterList.startDate}` }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.startDate)"
                            style="padding-left: 15px" />
                        </a>
                      </li>
                      <li v-if="filterList.endDate" class="filterText">
                        <a class="filter-bg-color">
                          {{ `Enddate ${filterList.endDate}` }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.endDate)"
                            style="padding-left: 15px" />
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-hover table-nowrap formtable">
                      <thead class="thead-light">
                        <tr class="header">
                          <th class="heading" scope="col" style="padding-left: 15px">
                            <div class="
                                custom-control custom-checkbox
                                checkbox-lg
                                form-check-inline
                              ">
                              <input type="checkbox" class="custom-control-input"
                                @change="pushAllSelectedReviews($event)" id="all" />
                              <label class="custom-control-label checkLabel" for="all"></label>
                            </div>
                          </th>
                          <th class="heading" scope="col"></th>
                          <th class="heading" scope="col">DATE</th>
                          <th class="heading" scope="col">CUSTOMER NAME</th>
                          <th class="heading" scope="col">PLATFORM</th>
                          <th class="heading" scope="col">RATINGS & STARS</th>
                          <th class="heading" scope="col">USER</th>
                          <th class="heading" scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="reviewsList.length > 0">
                        <tr v-for="review in reviewsList" :key="review.id">
                          <td>
                            <div class="
                                custom-control custom-checkbox
                                checkbox-lg
                                form-check-inline
                              ">
                              <input :id="review.id" type="checkbox" class="custom-control-input"
                                @change="pushSelectedReviews($event, review.id)" />
                              <label class="custom-control-label checkLabel" :for="review.id"></label>
                            </div>
                          </td>
                          <td>
                            <a class="text-heading new-color" style="cursor:pointer"
                              @click="viewReview(review.id, review.reviewStatus)">
                              {{ review.reviewStatus ? "New!" : "" }}
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#">
                              {{
                                  moment(String(review.rawDate)).format(
                                    "MM-DD-YYYY"
                                  )
                              }}
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#"
                              @click="customerReviewModalOpen(review.id)">
                              {{ review.reviewAuthor }}
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#">
                              {{ review.platformName }}
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#" v-if="review.reviewRating">
                              <img v-for="index in parseInt(review.reviewRating)" :key="index + 'yello'"
                                src="resources/assets/yellowStar.png" />
                              <img v-for="index in 5 -
                              parseInt(review.reviewRating)" :key="index + 'blank'"
                                src="resources/assets/blankStar.png" />
                            </a>
                            <a class="text-heading" style="text-decoration: none" href="#" v-else>
                              <img v-for="index in 5" :key="index + 'blank'" src="resources/assets/blankStar.png" />
                            </a>
                          </td>
                          <td>
                            <a class="text-heading" style="text-decoration: none" href="#">
                              {{ review.assignedUserName }}
                            </a>
                          </td>
                          <td>
                            <a href="#" class="btn btn-sm btn-neutral" style="background: none"
                              @click="viewReview(review.id, review.reviewStatus)"><img
                                src="resources/assets/view_icon.svg" /></a>
                            <a href="#" @click="deleteReview(review.id)" class="btn btn-sm btn-neutral"
                              style="background: none">
                              <img src="resources/assets/trash_icon.svg" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="8" style="text-align: center; font-weight: 600">
                            <h1>No Review Found</h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="reviewCount > reviewPerPage" class="admin-new-pagination role-table-pagination">
                    <b-pagination v-model="reviewCurrentPage" :total-rows="reviewCount" :per-page="reviewPerPage"
                      @change="showPage" first-number last-number align="center" pills size="lg"></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'request_reviews'">
          <RequestReview />
          <transition name="modal" v-if="vSteps">
            <div class="modal-mask">
              <div class="modal-wrapper">
                <div class="modal-container modal-video" style="background: none;box-shadow:none">
                  <div>
                    <div class="d-flex mb-2" style="justify-content: end;">
                      <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                    </div>
                    <div class="table-responsive">
                      <div class="plyr__video-embed" id="player">
                        <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen
                          allowtransparency allow="autoplay"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div v-if="show == 'feedback'">
          <main class="py-6">
            <div class="row align-items-center">
              <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                <div class="card-header" style="background: none; border: none">
                  <h2 class="mb-0">Feedback</h2>
                </div>
              </div>
              <div class="col-md-9 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                <div id="v-step-9" class="mb-0 profileSettingHeading"></div>
                <div class="mb-0 profileSettingHeading v-step-3"></div>
              </div>
            </div>
            <transition name="modal" v-if="feedbackModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div>
                      <form>
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>Create New</h1>
                          <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                        </div>

                        <h1>Feedback form</h1>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="modal" v-if="feedbackViewModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="feedback-modal-container">
                    <div>
                      <form class="d-block">
                        <div class="d-flex justify-content-between mb-5">
                          <h1></h1>
                          <div class="align-items-center">
                            <p class="center-item viewReviewSecondPartTopName">
                              {{ feedbackViewData.customerName }}
                            </p>
                            <p class="center-item">
                              <img v-for="index in parseInt(
                                feedbackViewData.rating
                              )" :key="index + 'yellow'" src="resources/assets/yellowStar.png" />
                              <img v-for="index in 5 -
                              parseInt(feedbackViewData.rating)" :key="index + 'blank'"
                                src="resources/assets/blankStar.png" />
                            </p>
                          </div>
                          <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                        </div>
                        <div class="row">
                          <div class="form-outline form-white"></div>
                          <div class="center-item">
                            <a class="yellowButton" v-if="feedbackViewData.phoneNumber"
                              :href="`tel:${feedbackViewData.phoneNumber}`" style="text-decoration: none">
                              <span class="callButton">Call</span>
                            </a>
                            <button v-else class="yellowButton" disabled>
                              <p class="callButton">Call</p>
                            </button>
                            <button type="button" v-if="feedbackViewData.email" class="yellowButton"
                              @click="changeScrollView()">
                              <p style="margin:0" class="loginButtonText">Email</p>
                            </button>
                            <button type="button" class="yellowButton" v-else disabled>
                              <p style="margin:0" class="loginButtonText">Email</p>
                            </button>
                          </div>
                          <div class="center-item">
                            <span class="viewReviewSecondPartdropdownText">
                              {{ feedbackViewData.feedback }}
                            </span>
                          </div>
                          <div class="center-item">
                            <button type="button" class="view-button">
                              <a @click="getFeedbackId(feedbackViewData)">
                                <p style="margin:0" class="loginButtonText">View</p>
                              </a>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="modal" v-if="customerFeedbackModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container-view">
                    <div>
                      <form>
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>Customer Details</h1>
                          <div class="d-flex">
                            <a v-if="!customerFeedbackModalEdit" v-b-tooltip.hover @click="editFeedback()"
                              style="cursor: pointer" title="Edit"><img src="resources/assets/editIcon.png" /></a>
                            <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                          </div>
                        </div>

                        <h1></h1>
                        <form class="d-block">
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> customerName</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{ feedbackViewData.customerName }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Rating</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        feedbackViewData.rating
                                          ? feedbackViewData.rating
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Email</a>
                                  <br />
                                  <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                    <input type="email" class="
                                        form-control
                                        userForm
                                        form-control-lg
                                        inputOrange
                                      " placeholder="Customer Email Address" v-model="feedbackViewData.email"
                                      v-validate="'required'" v-bind:name="'feedbackViewData.email'"
                                      v-bind:ref="'feedbackViewData.email'" :class="{
                                        'is-invalid': errors.has(
                                          'feedbackViewData.email'
                                        ),
                                      }" /></a>
                                  <a v-else class="profileFormTitleValue">
                                    {{
                                        feedbackViewData.email
                                          ? feedbackViewData.email
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Country Code</a>
                                  <br />
                                  <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                    <select class="
                                        viewReviewSecondPartdropdownText
                                        inputOrange
                                        assignFollowUp
                                        form-select
                                      " v-model="feedbackCode" v-validate="'required'" v-bind:name="'requestData.code'"
                                      v-bind:ref="'requestData.code'" :class="{
                                        'is-invalid':
                                          errors.has('requestData.code'),
                                      }">
                                      <option class="viewReviewSecondPartdropdownText" value="">
                                        Select Country
                                      </option>
                                      <option class="viewReviewSecondPartdropdownText" :value="location.shortName"
                                        v-for="location in countryCodelist" :key="location.id">
                                        {{
                                            `${location.name} (+${location.code})`
                                        }}
                                      </option>
                                    </select></a>
                                  <a v-else class="profileFormTitleValue">
                                    {{
                                        feedbackCountryCode
                                          ? feedbackCountryCode
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle"> Number</a>
                                  <br />
                                  <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                    <input type="number" class="
                                        form-control
                                        userForm
                                        form-control-lg
                                        inputOrange
                                      " placeholder="Customer Phone Number" v-model="feedbackViewData.phoneNumber"
                                      v-validate="'required'" v-bind:name="'feedbackViewData.phoneNumber'"
                                      v-bind:ref="'feedbackViewData.phoneNumber'" :class="{
                                        'is-invalid': errors.has(
                                          'feedbackViewData.phoneNumber'
                                        ),
                                      }" /></a>
                                  <a v-else class="profileFormTitleValue">
                                    {{
                                        feedbackViewData.phoneNumber
                                          ? feedbackViewData.phoneNumber
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="text-start">
                                  <a class="profileFormTitle">
                                    Follow Up Notes</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        feedbackViewData.followUpNotes
                                          ? feedbackViewData.followUpNotes
                                          : "NA"
                                    }}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row align-items-center">
                            <div class="form-outline viewReviewInnerBox d-flex">
                              <div class="text-start">
                                <a class="profileFormTitle"> Feedback</a>
                                <br />
                                <a class="profileFormTitleValue">
                                  {{
                                      feedbackViewData.feedback
                                        ? feedbackViewData.feedback
                                        : "NA"
                                  }}
                                </a>
                              </div>
                            </div>
                          </div>
                          <a v-if="customerFeedbackModalEdit" @click.prevent="updateFeedback()"
                            class="d-flex justify-content-end">
                            <button class="platFormCardButton" style="width: 180px; margin-top: 15px">
                              Save
                            </button>
                          </a>
                        </form>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="modal" v-if="feedbackUserList">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div>
                      <UsersModal :actionType="'feedback'" @changeTitle="ChangeT($event)" :selected="filterList.user"
                        @closeModal="closeM($event)" />
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="modal" v-if="vSteps">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container modal-video" style="background: none;box-shadow:none">
                    <div>
                      <div class="d-flex mb-2" style="justify-content: end;">
                        <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                      </div>
                      <div class="table-responsive">
                        <div class="plyr__video-embed" id="player">
                          <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen
                            allowtransparency allow="autoplay"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="card border-0 mb-7 table-align" id="userForDiv">
              <div class="table-outer-wrap">
                <div class="inner-white-wrap">
                  <ActionBar @changeTitle="ChangeT($event)" @updateList="updateListing($event)"
                    :selected-users="selectedUsers" :filter-options="filterOptions" :actionType="'feedback'"
                    :dateStyle="true" />
                  <div class="container">
                    <ul class="
                        viewReviewSecondPartdropdownText
                        inputOrange
                        assignFilter
                      ">
                      <li class="viewReviewSecondPartdropdownText" v-for="(data, index) in filterList.platform"
                        v-bind:key="index">
                        <a class="filter-bg-color">
                          {{ data }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" />
                        </a>
                      </li>
                      <li class="viewReviewSecondPartdropdownText" v-for="(data, index) in filterList.ranking"
                        v-bind:key="index">
                        <a class="filter-bg-color">
                          {{ data }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" />
                        </a>
                      </li>
                      <li class="filterText" v-for="(data, index) in filterList.userName" v-bind:key="index">
                        <a class="filter-bg-color">
                          {{ `${data.first_name} ${data.last_name ? data.last_name : ""}` }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)"
                            style="padding-left: 15px" />
                        </a>
                      </li>
                      <li v-if="filterList.startDate" class="viewReviewSecondPartdropdownText">
                        <a class="filter-bg-color">
                          {{ `Startdate ${filterList.startDate}` }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.startDate)" />
                        </a>
                      </li>
                      <li v-if="filterList.endDate" class="viewReviewSecondPartdropdownText">
                        <a class="filter-bg-color">
                          {{ `Enddate ${filterList.endDate}` }}
                          <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.endDate)" />
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-hover table-nowrap formtable">
                      <thead class="thead-light">
                        <tr class="header">
                          <th class="heading" scope="col" style="padding-left: 15px">
                            <div class="
                                custom-control custom-checkbox
                                checkbox-lg
                                form-check-inline
                              ">
                              <input type="checkbox" class="custom-control-input"
                                @change="pushAllSelectedFeedbacks($event)" id="all" />
                              <label class="custom-control-label checkLabel" for="all"></label>
                            </div>
                          </th>
                          <th class="heading" scope="col"></th>
                          <th class="heading" scope="col">Date</th>
                          <th class="heading" scope="col">CUSTOMER NAME</th>
                          <th class="heading" scope="col">RATINGS</th>
                          <th class="heading" scope="col">ASSIGNMENT</th>
                          <th class="heading" scope="col">FEEDBACK</th>
                          <th class="heading" scope="col">STATUS</th>
                          <th class="heading" scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="feedbackList.length > 0">
                        <tr v-for="feedback in feedbackList" v-bind:key="feedback.id">
                          <td>
                            <div class="
                                custom-control custom-checkbox
                                checkbox-lg
                                form-check-inline
                              ">
                              <input :id="feedback.id" type="checkbox" class="custom-control-input" @change="
                                pushSelectedFeedbacks($event, feedback.id)
                              " />
                              <label class="custom-control-label checkLabel" :for="feedback.id"></label>
                            </div>
                          </td>
                          <td>
                            <a class="text-heading new-color" style="cursor:pointer;"
                              @click="viewFeedback(feedback.id, feedback.feedbackStatus)">
                              {{ feedback.feedbackStatus ? "New!" : "" }}
                            </a>
                          </td>
                          <td>
                            <p class="text-heading">
                              {{
                                  moment(String(feedback.created_at)).format(
                                    "MM-DD-YYYY"
                                  )
                              }}
                            </p>
                          </td>
                          <td>
                            <a style="cursor: pointer" class="text-heading"
                              @click="customerFeedbackModalOpen(feedback.id)">
                              {{ ` ${feedback.customerName}` }}
                            </a>
                          </td>
                          <td>
                            <p>
                              <img v-for="index in parseInt(feedback.rating)" :key="index + 'yellow'"
                                src="resources/assets/yellowStar.png" />
                              <img v-for="index in 5 - parseInt(feedback.rating)" :key="index + 'blank'"
                                src="resources/assets/blankStar.png" />
                            </p>
                          </td>
                          <td>
                            <p class="text-heading">
                              {{
                                  `${feedback.assignment
                                    ? feedback.assignment
                                    : "NA"
                                  }`
                              }}
                            </p>
                          </td>
                          <td>
                            <a href="#" class="btn btn-sm btn-neutral"
                              @click="viewFeedback(feedback.id, feedback.feedbackStatus)" style="background: none"><img
                                src="resources/assets/view_icon.svg" /></a>
                          </td>
                          <td>
                            <p class="text-heading" :class="{
                              'outer-circle-new': feedback.status === 0,
                              'outer-circle-pending': feedback.status === 1,
                              'outer-circle-resolved': feedback.status === 2,
                            }">
                              <span :class="{
                                'inner-new': feedback.status === 0,
                                'inner-pending': feedback.status === 1,
                                'inner-resolved': feedback.status === 2,
                              }">
                                {{ feedback.status === 0 ? "New" : "" }}
                                {{ feedback.status === 1 ? "Pending" : "" }}
                                {{ feedback.status === 2 ? "Resolved" : "" }}
                              </span>
                            </p>
                          </td>
                          <td>
                            <a href="#" class="btn btn-sm btn-neutral" style="background: none"
                              @click="viewFeedback(feedback.id, feedback.feedbackStatus)"><img
                                src="resources/assets/view_icon.svg" /></a>
                            <a href="#" class="btn btn-sm btn-neutral" @click="deleteFeedback(feedback.id)"
                              style="background: none">
                              <img src="resources/assets/trash_icon.svg" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="9" style="text-align: center; font-weight: 600">
                            <h1>
                              No Feedback yet! Go request a review to begin.
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="feedbackCount > feedbackPerPage" class="admin-new-pagination role-table-pagination">
                    <b-pagination v-model="feedbackCurrentPage" :total-rows="feedbackCount" :per-page="feedbackPerPage"
                      @change="showFeedbackPage" first-number last-number align="center" pills size="lg"></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'feedback3'">
          <Feedback3 :scrollToEmaill="scrollToEmaill" :feedbackId="feedbackId" />
        </div>
        <div v-if="show == 'feedback5'">
          <Feedback5 :scrollToEmaill="scrollToEmaill" :feedbackId="feedbackId" />
        </div>
        <div v-if="show == 'reports'">
          <Reports :filterPlatform="filterPlatform" />
        </div>
        <div v-if="show == 'settings'">
          <main class="py-6">
            <div class="row align-items-center">
              <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                <div class="card-header" style="background: none; border: none">
                  <h2 class="mb-0">Settings/ Account</h2>
                </div>
              </div>
              <div class="col-md-9 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                <span id="v-step-1" class="mb-0 profileSettingHeading"></span>
                <span class="mb-0 profileSettingHeading v-step-1"></span>
                <span id="v-step-2" class="mb-0 profileSettingHeading"></span>
                <span id="v-step-3" class="mb-0 profileSettingHeading"></span>
                <span id="v-step-4" class="mb-0 profileSettingHeading"></span>
                <span id="v-step-5" class="mb-0 profileSettingHeading"></span>
                <span id="v-step-6" class="mb-0 profileSettingHeading"></span>
              </div>

            </div>

            <header class="bg-surface-primary border-bottom pt-6 tabs-outer" style="margin-left: 50px">
              <div>
                <div class="mb-npx">
                  <div class="row align-items-center tab-wrap">
                    <div class="col-md-2 col-12 mb-4 mb-sm-0">
                      <a href="#" @click="showProfiles('basic')" :class="{ active: showProfile == 'basic' }"
                        class="profileSettingSwitch">
                        <span :class="{ 'account-active': showProfile == 'basic' }">
                          Basic Information
                        </span></a>
                    </div>
                    <div class="col-md-2 col-12 mb-4 mb-sm-0" v-if="checkPermission">
                      <a href="#" @click="showProfiles('billing')" :class="{ active: showProfile == 'billing' }"
                        class="profileSettingSwitch">
                        <span :class="{
                          'account-active': showProfile == 'billing',
                        }">
                          Billing Information
                        </span>
                      </a>
                    </div>
                    <div class="col-md-2 col-12 mb-4 mb-sm-0" v-if="checkPermission">
                      <a href="#" @click="showProfiles('platform')" :class="{ active: showProfile == 'platform' }"
                        class="profileSettingSwitch">
                        <span :class="{
                          'account-active': showProfile == 'platform',
                        }">
                          Platform Connections
                        </span></a>
                    </div>
                    <div class="col-md-2 col-12 mb-4 mb-sm-0" v-if="checkPermission">
                      <a href="#" @click="showProfiles('communication')"
                        :class="{ active: showProfile == 'communication' }" class="profileSettingSwitch">
                        <span :class="{
                          'account-active': showProfile == 'communication',
                        }">
                          Communication Settings
                        </span></a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <transition name="modal" v-if="adminProfileModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div>
                      <form class="d-block" enctype="multipart/form-data">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>Edit Profile</h1>
                          <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.firstName" type="text" id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="First Name" />
                            </div>
                          </div>
                          <div class="col-md-6 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.lastName" type="text" id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Last Name" />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.phone" type="number" id="form3Examplea2" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Phone" />
                            </div>
                          </div>
                          <div class="col-md-6 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input type="file" @change="onChangeFile" id="file-upload"
                                class="loginInputsEmail inputOrange" placeholder="Select File" />
                            </div>
                          </div>
                        </div>
                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="adminDetails.email" type="email" id="form3Examplea2" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Email Address" />
                          </div>
                        </div>
                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="adminDetails.organisation_name" type="text" id="form3Examplea2" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Organization Name" />
                          </div>
                        </div>
                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="adminDetails.billing_address" type="text" id="billing_address" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Billing Address" @input="initializeBillingAddress" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.city" type="text" id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="City" />
                            </div>
                          </div>
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.state" type="text" id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="State" />
                            </div>
                          </div>
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.pincode" type="text" id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Zip code" />
                            </div>
                          </div>
                        </div>
                        <button v-if="buttonText == 'Create'" type="button" class="loginButton"
                          @click="updateProfile()">
                          <p class="loginButtonText">Update Profile</p>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="adminBillingInfoModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container">
                    <div>
                      <form class="d-block">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>Edit Blling Info</h1>
                          <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.b_firstName" type="text" id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="First Name" />
                            </div>
                          </div>
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.b_lastNAme" type="text" id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Last Name" />
                            </div>
                          </div>
                          <div class="mb-4 pb-2"></div>
                        </div>

                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="adminDetails.b_billingAddress" type="text" id="b_billingAddress" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Address" @input="initializeBillingInfo" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.b_city" type="text" id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="City" />
                            </div>
                          </div>
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.b_state" type="text" id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="State" />
                            </div>
                          </div>
                          <div class="col-md-4 mb-4 pb-2">
                            <div class="form-outline form-white">
                              <input v-model="adminDetails.b_zip" type="text" id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Zip" />
                            </div>
                          </div>
                        </div>
                        <button v-if="buttonText == 'Create'" type="button" class="loginButton"
                          @click="updateBillingInfo()">
                          <p class="loginButtonText">Update Billing Info</p>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="adminBusinessModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="business-modal-container">
                    <div>
                      <form class="d-block" enctype="multipart/form-data">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>Add Business</h1>
                          <a @click="cancel()" :class="{ isDisabled: !call }" class="f-start"><img
                              src="resources/assets/crossicon.png" /></a>
                        </div>

                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input type="text" class="
                                form-control
                                business-input
                                form-control-lg
                                inputOrange
                              " placeholder="Business name" id="autocomplete" v-model="businessName"
                              @input="initialize" v-validate="'required'" v-bind:name="'businessName'"
                              v-bind:ref="'businessName'" :class="{ 'is-invalid': errors.has('businessName') }" />
                            <button type="button" class="business-btn spinner-center" @click="saveBusiness()"
                              :disabled="loading == 1">
                              <span v-if="loading" class="loginButtonText">Gathering reviews, please do not close the
                                box.
                                <b-spinner class="spinner-center" variant="light" label="Spinner">
                                </b-spinner>
                              </span>
                              <p v-else class="loginButtonText">Submit</p>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="yelpBusinessModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="business-modal-container">
                    <div>
                      <form class="d-block" enctype="multipart/form-data">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>
                            Add Yelp Business
                          </h1>
                          <a @click="cancel()" :class="{ isDisabled: !call }" class="f-start"><img
                              src="resources/assets/crossicon.png" /></a>
                        </div>

                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input type="text" class="
                                form-control yelp-form-control1
                                business-input
                                form-control-lg
                                inputOrange
                              " placeholder="Enter Yelp Business Name" v-model="searchText" v-validate="'required'"
                              v-bind:name="'searchText'" v-bind:ref="'searchText'"
                              :class="{ 'is-invalid': errors.has('searchText') }" />
                            <span class="hiddentxt formInput"><img src="resources/assets/info.svg" /></span><span
                              class="hiddenimg"><img src="resources/assets/JY-Yelp.png" width="1000" /></span>
                          </div>
                        </div>

                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="location" type="text" id="yelpComplete" class="
                            form-control yelp-form-control1
                                business-input
                                form-control-lg
                                inputOrange
                              " placeholder="Enter Yelp Address" @input="initializeYelp" v-validate="'required'"
                              v-bind:name="'location'" v-bind:ref="'location'"
                              :class="{ 'is-invalid': errors.has('location') }" />
                            <span class="hiddentxt formInput"><img src="resources/assets/info.svg" /></span><span
                              class="hiddenimg"><img src="resources/assets/JY-Yelp-address.png" width="1000" /></span>
                            <span class="spinner-center">
                              <b-spinner v-if="loading" class="spinner-center" variant="primary" label="Spinning">
                              </b-spinner>
                            </span>
                            <button type="button" class="business-btn spinner-center" @click="saveYelpBusiness()"
                              :disabled="yelpLoading == 1">
                              <b-spinner v-if="yelpLoading" class="spinner-center" variant="primary" label="Spinning">
                              </b-spinner>
                              <p v-else class="loginButtonText">Submit</p>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="modal" v-if="zillowModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="business-modal-container">
                    <div>
                      <form class="d-block" enctype="multipart/form-data">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>
                            Add Business Link
                            <img src="resources/assets/info.svg"
                              title="Enter Business Link Like 'https://www.zillow.com/profile/FASTREALESTATE'" />
                          </h1>
                          <a @click="cancel()" :class="{ isDisabled: !call }" class="f-start"><img
                              src="resources/assets/crossicon.png" /></a>
                        </div>

                        <div class="mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input type="text" class="
                                form-control
                                business-input
                                form-control-lg
                                inputOrange
                              " placeholder="Ex:-https://www.zillow.com/profile/FASTREALESTATE" v-model="screenName"
                              v-validate="'required'" v-bind:name="'screenName'" v-bind:ref="'screenName'"
                              :class="{ 'is-invalid': errors.has('screenName') }" />
                            <button type="button" class="business-btn spinner-center" @click="saveZillow()"
                              :disabled="loading == 1">
                              <b-spinner v-if="loading" class="spinner-center" variant="primary" label="Spinning">
                              </b-spinner>
                              <p v-else class="loginButtonText">Submit</p>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="modal" v-if="disconnectModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container modal-sm">
                    <div class="buttons-platform">
                      <div class="row viewReviewSecondPartTopText">
                        <p>
                          {{
                              `Are you sure you want to disconnect ${platfomeName} platform?`
                          }}
                        </p>
                      </div>
                      <div class="action_btn-platform-wrap">
                        <button name="submit" class="
                            action_btn-platform
                            action_btn_color__disconnected
                          " type="button" value="Connected" @click="disconnect()">
                          <p style="height: 40px; width: 200px" class="loginButtonText">
                            Yes
                          </p>
                        </button>
                        <button name="submit" class="
                            action_btn-platform
                            action_btn_color__connected
                          " type="button" value="Disconnect" @click.prevent="cancel()">
                          <p style="height: 40px; width: 200px" class="loginButtonText">
                            No
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="modal" v-if="platformHistoryModal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="business-modal-history-container">
                    <div>
                      <form class="d-block" enctype="multipart/form-data">
                        <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                          <h1>Platform Connection History</h1>
                          <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                        </div>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap formtable">
                            <thead class="thead-light">
                              <tr class="header">
                                <th class="heading" scope="col">Id</th>
                                <th class="heading" scope="col">Business Name</th>
                                <th class="heading" scope="col">Platform Name</th>
                                <th class="heading" scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody v-if="platformHistoryList.length > 0">
                              <tr v-for="(platform, index) in platformHistoryList" v-bind:key="index">
                                <td>
                                  <a class="text-heading" style="text-decoration: none" href="#">
                                    {{ index + 1 }}
                                  </a>
                                </td>
                                <td>
                                  <a class="text-heading" style="text-decoration: none" href="#">
                                    {{ platform.businessName }}
                                  </a>
                                </td>
                                <td>
                                  <a class="text-heading" style="text-decoration: none" href="#">
                                    {{ platform.platformName }}

                                  </a>
                                </td>
                                <td>
                                  <a href="#" class="btn btn-sm btn-neutral" style="background: none"
                                    @click="deletePlatform(platform)">
                                    <img src="resources/assets/trash_icon.svg" />
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="6" style="text-align: center; font-weight: 600">
                                  No History Found.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="modal" v-if="vSteps">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-container modal-video" style="background: none;box-shadow:none">
                    <div>
                      <div class="d-flex mb-2" style="justify-content: end;">
                        <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                      </div>
                      <div class="table-responsive">
                        <div class="plyr__video-embed" id="player">
                          <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen
                            allowtransparency allow="autoplay"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <div class="col-sm-11 col-12 mb-4 mb-sm-0 basic-info">
              <h4 v-if="showProfile == 'basic'" class="mb-0 profileSettingHeading">
                Basic Information
              </h4>
              <h4 v-else-if="showProfile == 'billing'" class="mb-0 profileSettingHeading">
                Billing Information
              </h4>
              <h4 v-else-if="showProfile == 'platform'" class="mb-0 profileSettingHeading">
                Platform Connections
              </h4>
              <h4 v-else-if="showProfile == 'communication'" class="mb-0 profileSettingHeading">
                Communication Settings
              </h4>
              <!-- Communication Settings   -->
              <div style="margin-top: 20px">
                <div class="com-set-wrap" style="
                    background-color: white;
                    margin: 0px 27px;
                    border-radius: 15px;
                  " v-if="showProfile == 'communication'">
                  <div class="row communication-setting">
                    <div class="col-md-6 firstPart">
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Select Timezone
                            </p>
                            <select class="
                                viewReviewSecondPartdropdownText
                                userForm
                                form-control-lg
                                loginInputsEmail
                              " style="height: 58px" v-model="communication.timezone" v-validate="'required'"
                              v-bind:name="'timezone'" v-bind:ref="'timezone'"
                              :class="{ 'is-invalid': errors.has('timezone') }">
                              <option class="viewReviewSecondPartdropdownText" :value="timezone.id"
                                v-for="timezone in timeZoneList" :key="timezone.id">
                                {{ `${timezone.zone}` }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Email Send From Name
                            </p>
                            <input v-model="communication.emailSendFromName" type="text" id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                loginInputsEmail
                                account-outline
                              " placeholder="Email" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Text Send From Name
                            </p>
                            <input v-model="communication.textSendFromName" type="text" id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                loginInputsEmail
                                account-outline
                              " placeholder="" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Default “Reply to Email”
                            </p>
                            <input v-model="communication.replyEmail" type="email" id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                loginInputsEmail
                                account-outline
                              " placeholder="" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Review Request Text Default Message
                            </p>
                            <textarea v-model="
                              communication.reviewRequestTextDefaultMessage
                            " id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                account-outline
                                loginInputsTextArea
                              " cols="30" rows="10"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Default 3 Star or Less Response Email
                            </p>
                            <vue-editor v-model="
                              communication.defaultThreeStarResponseEmail
                            " :editorToolbar="customToolbar" class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Default 3 Star for Text Message
                            </p>
                            <vue-editor v-model="
                              communication.defaultThreeStarResponseText
                            " :editorToolbar="customToolbar" class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              4-5 Star Thank You Email
                            </p>
                            <vue-editor v-model="communication.thankyouEmail" :editorToolbar="customToolbar"
                              class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Review Request Message for Feedback
                            </p>
                            <vue-editor v-model="
                              communication.reviewRequestMainPageMessage
                            " :editorToolbar="customToolbar" class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Review Request 3 or Less Star Message
                            </p>
                            <vue-editor v-model="
                              communication.reviewRequestThreeStarMessage
                            " :editorToolbar="customToolbar" class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 firstPart" @dragover.prevent @drop.prevent>
                      <div @drop="dragCommunicationFile" v-if="!communicationImg">
                        <div class="drop-it-hot" id="drop-area">
                          <div class="circle">
                            <svg width="74" height="73" viewBox="0 0 74 73" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="37" cy="36.4998" r="36.5" fill="#9194B3" fill-opacity="0.2" />
                              <path opacity="0.3"
                                d="M22.416 37.9582C22.416 37.229 23.1452 36.4998 23.8743 36.4998C24.6035 36.4998 25.3327 37.229 25.3327 37.9582C25.3327 38.4443 25.3327 40.8748 25.3327 45.2498C25.3327 46.8606 26.6385 48.1665 28.2493 48.1665H45.7494C47.3602 48.1665 48.666 46.8606 48.666 45.2498V37.9582C48.666 37.1527 49.3189 36.4998 50.1244 36.4998C50.9298 36.4998 51.5827 37.1527 51.5827 37.9582V45.2498C51.5827 48.4715 48.971 51.0832 45.7494 51.0832H28.2493C25.0277 51.0832 22.416 48.4715 22.416 45.2498C22.416 40.8748 22.416 38.4443 22.416 37.9582Z"
                                fill="#9194B3" />
                              <path opacity="0.3"
                                d="M38.4577 23.3751C38.4577 22.5697 37.8048 21.9168 36.9993 21.9168C36.1939 21.9168 35.541 22.5697 35.541 23.3751V40.8751C35.541 41.6806 36.1939 42.3335 36.9993 42.3335C37.8048 42.3335 38.4577 41.6806 38.4577 40.8751V23.3751Z"
                                fill="#9194B3" />
                              <path
                                d="M37.0528 23.9257L30.7395 30.239C30.17 30.8085 29.2467 30.8085 28.6771 30.239C28.1076 29.6695 28.1076 28.7461 28.6771 28.1766L35.9688 20.885C36.5173 20.3365 37.3991 20.3133 37.9756 20.8322L45.2672 27.3947C45.8659 27.9335 45.9144 28.8556 45.3756 29.4542C44.8368 30.0529 43.9148 30.1014 43.3161 29.5626L37.0528 23.9257Z"
                                fill="#9194B3" />
                            </svg>
                          </div>
                          <form class="choose-files">
                            <div class="button-wrapper">
                              <label class="label file-upload-text" for="fileElem">Drag and Drop or Browse to upload
                                your
                                logo</label>

                              <input type="file" id="fileElem" multiple accept="image/*"
                                @change="onChangeCommunicationFile" />
                            </div>
                          </form>
                        </div>
                      </div>

                      <div v-if="communicationImg">
                        <div class="communicationImg" id="drop-area">
                          <form class="choose-files">
                            <div class="button-wrapper">
                              <img :src="communicationImg" alt="" class="img" />
                            </div>
                            <button class="d-flex imgBtnCenter createButton" @click="removeFile">
                              <span class="imgBtnCenter" style="padding-top: 10px">REMOVE</span>
                            </button>
                          </form>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Review Request Email Default Message
                            </p>
                            <textarea v-model="
                              communication.reviewRequestEmailDefaultMessage
                            " id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                account-outline
                                loginInputsTextArea
                              " cols="30" rows="10"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              3 Star or Less Thank You Message
                            </p>
                            <vue-editor v-model="communication.threeStarThankyouMessage" :editorToolbar="customToolbar"
                              class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              4-5 Star Leave Review Message
                            </p>
                            <vue-editor v-model="communication.FourFiveReviewMessage" :editorToolbar="customToolbar"
                              class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              4-5 Star Thank You Text Message
                            </p>
                            <vue-editor v-model="communication.thankyouMessage" :editorToolbar="customToolbar"
                              class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <p class="communicationFormLabel communicationFormLabelColor">
                              Review Request 4-5 Star Message
                            </p>
                            <vue-editor v-model="
                              communication.reviewRequestFourFiveStarMessage
                            " :editorToolbar="customToolbar" class="ql-editor"></vue-editor>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4 pb-2">
                          <div class="
                              form-outline form-white
                              d-flex
                              flex-row-reverse
                            ">
                            <button class="platFormCardButton" style="width: 150px" @click="saveCommunication()">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Communication Settings   -->

                <!--PlatForm Connections   -->
                <div v-if="showProfile == 'platform'">
                  <div class="card-group platFormCardGroup">
                    <div class="card platFormCard">
                      <img v-if="googlePlatform" @click="syncGoogleBusiness()" class="sync-svg"
                        src="resources/assets/sync_icon.svg" />
                      <img class="sync-svg" src="resources/assets/history-icon.svg" style="right:10px"
                        @click="businessHistory('google')" />
                      <div class=" card-body text-center">
                        <img src="resources/assets/googleLogo.png" />

                        <p>
                          <img v-for="index in 5" :key="index + 'yellow'" src="resources/assets/yellowStar.png" />
                        </p>
                        <a v-if="googlePlatform" class="d-flex justify-content-center"
                          @click.prevent="disconnectPlatform('google')">
                          <button class="
                              platFormCardButton
                              action_btn_color__disconnected
                            " style="width: 180px; margin-top: 43px" :class="{
                              platFormCardButtonColor: googlePlatform,
                              squareWizard: googleConnectIcon
                            }">
                            Disconnect
                          </button>
                        </a>
                        <a v-else @click.prevent="googleInit" class="d-flex justify-content-center">
                          <button class="platFormCardButton" style="width: 180px; margin-top: 43px"
                            :class="{ platFormCardButtonColor: googlePlatform, squareWizard: googleConnectIcon }">
                            Connect
                          </button>
                        </a>
                        <p v-if="googlePlatform" class="platform__connected justify-content-center">
                          {{ `Connected to ${googleName} on ${googleDate}` }}
                          <!-- Connected -->
                        </p>
                        <!-- Connect -->
                      </div>
                    </div>
                    <div class="card platFormCard">
                      <img v-if="facebookPlatform" @click="syncFacebookBusiness()" class="sync-svg"
                        src="resources/assets/sync_icon.svg" />
                      <img class="sync-svg" src="resources/assets/history-icon.svg" style="right:10px"
                        @click="businessHistory('facebook')" />
                      <div class="card-body text-center">
                        <img src="resources/assets/facebookLogo.png" />

                        <p>
                          <img v-for="index in 5" :key="index + 'fb'" src="resources/assets/fb-star.svg" />
                        </p>
                        <a v-if="facebookPlatform" class="d-flex justify-content-center"
                          @click.prevent="disconnectPlatform('facebook')">
                          <button class="
                              platFormCardButton
                              action_btn_color__disconnected
                            " style="width: 180px; margin-top: 43px" :class="{
                              platFormCardButtonColor: facebookPlatform, squareWizard: facebookConnectIcon
                            }">
                            Disconnect
                          </button>
                        </a>
                        <a v-else @click.prevent="checkLoginState" class="d-flex justify-content-center">
                          <button class="platFormCardButton" style="width: 180px; margin-top: 43px" :class="{
                            platFormCardButtonColor: facebookPlatform, squareWizard: facebookConnectIcon
                          }">
                            Connect
                          </button>
                        </a>
                        <p v-if="facebookPlatform" class="platform__connected justify-content-center">
                          {{ `Connected to ${fbName} on ${facebookDate}` }}
                        </p>
                      </div>
                    </div>
                    <div class="card platFormCard">
                      <img v-if="yelpPlatform" class="sync-svg" src="resources/assets/sync_icon.svg"
                        @click="syncYelpBusiness()" />
                      <img class="sync-svg" src="resources/assets/history-icon.svg" style="right:10px"
                        @click="businessHistory('yelp')" />
                      <div class="card-body text-center">
                        <img src="resources/assets/yellpLogo.png" />

                        <p>
                          <img v-for="index in 5" :key="index + 'yelp'" src="resources/assets/yelp-star.svg" />
                        </p>
                        <a v-if="yelpPlatform" class="d-flex justify-content-center"
                          @click.prevent="disconnectPlatform('yelp')">
                          <button class="
                              platFormCardButton
                              action_btn_color__disconnected
                            " style="width: 180px; margin-top: 43px"
                            :class="{ platFormCardButtonColor: yelpPlatform, squareWizard: yelpConnectIcon }">
                            Disconnect
                          </button>
                        </a>
                        <a class="d-flex justify-content-center" v-else>
                          <button class="platFormCardButton" style="width: 180px; margin-top: 43px"
                            @click="searchYelpBusiness()"
                            :class="{ platFormCardButtonColor: yelpPlatform, squareWizard: yelpConnectIcon }">
                            Connect
                          </button>
                        </a>
                        <p v-if="yelpPlatform" class="platform__connected justify-content-center">
                          {{ `Connected to ${yelpName} on ${yelpDate}` }}
                        </p>
                      </div>
                    </div>
                    <div class="card platFormCard">
                      <img @click="synczillowBusiness()" v-if="zillowPlatform" class="sync-svg"
                        src="resources/assets/sync_icon.svg" />
                      <img class="sync-svg" src="resources/assets/history-icon.svg" style="right:10px"
                        @click="businessHistory('zillow')" />
                      <div class="card-body text-center">
                        <img src="resources/assets/zillowLogo.png" />

                        <p>
                          <img v-for="index in 5" :key="index + 'zillow'" src="resources/assets/zillow-star.svg" />
                        </p>
                        <a v-if="zillowPlatform" class="d-flex justify-content-center"
                          @click.prevent="disconnectPlatform('zillow')">
                          <button class="
                              platFormCardButton
                              action_btn_color__disconnected
                            " style="width: 180px; margin-top: 43px"
                            :class="{ platFormCardButtonColor: zillowPlatform, squareWizard: zillowConnectIcon }">
                            Disconnect
                          </button>
                        </a>
                        <a v-else class="d-flex justify-content-center">
                          <button class="platFormCardButton" style="width: 180px; margin-top: 43px"
                            @click="openZillowModal()"
                            :class="{ platFormCardButtonColor: zillowPlatform, squareWizard: zillowConnectIcon }">
                            Connect
                          </button>
                        </a>
                        <p v-if="zillowPlatform" class="platform__connected justify-content-center">
                          {{ `Connected to ${zillowName} on ${zillowDate}` }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--PlatForm Connections   -->

                <div v-if="showProfile == 'basic'" style="background: #ffffff; margin-top: 70px" class="basic-card">
                  <img v-if="showProfile == 'basic' && adminDetails.profile" alt="..." :src="adminDetails.profile"
                    class="profilePic" />
                  <img v-else-if="showProfile == 'basic'" alt="..."
                    src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                    class="profilePic" />
                  <header class="
                      bg-surface-primary
                      border-bottom
                      pt-6
                      profile-text-wrap
                    " style="margin-right: 32px; padding-bottom: 50px">
                    <div>
                      <div class="mb-npx">
                        <div class="row align-items-center" style="padding: 20px">
                          <div class="col-md-11 col-6 mb-4 mb-sm-0">
                            <h3 class="mb-0 profileName">
                              Hi {{ adminDetails.firstName }}!
                            </h3>
                          </div>
                          <!-- Actions -->
                          <div class="
                              col-md-1 col-6
                              mb-4 mb-sm-0
                              d-flex
                              justify-content-end
                            " @click="editProfile()" :class="{ 'squareWizard': userEditIcon }">
                            <img src="resources/assets/editIcon.png" />
                            <p class="edit-text">Edit</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>

                  <div>
                    <header class="bg-surface-primary border-bottom pt-6" style="margin-right: 32px">
                      <div>
                        <div class="mb-npx">
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/userIcon.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle"> FULL NAME</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        adminDetails.firstName
                                          ? adminDetails.firstName
                                          : "NA"
                                    }}
                                    {{
                                        adminDetails.firstName &&
                                          adminDetails.lastName
                                          ? adminDetails.lastName
                                          : ""
                                    }}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/briefcaseIcon.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle"> COMAPNY NAME</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        adminDetails.organisation_name
                                          ? adminDetails.organisation_name
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/mailIcon.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle"> EMAIL </a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        adminDetails.email
                                          ? adminDetails.email
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>

                    <header class="bg-surface-primary border-bottom pt-6" style="margin-right: 32px">
                      <div>
                        <div class="mb-npx">
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/callIcon.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle">PHONE NUMBER </a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        adminDetails.phone
                                          ? adminDetails.phone
                                          : "NA"
                                    }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/mapIcon.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle"> ADDRESS </a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        adminDetails.billing_address
                                          ? adminDetails.billing_address
                                          : ""
                                    }}
                                    {{
                                        adminDetails.city ? adminDetails.city : ""
                                    }}
                                    {{
                                        adminDetails.state
                                          ? adminDetails.state
                                          : ""
                                    }}
                                    {{
                                        adminDetails.pincode
                                          ? adminDetails.pincode
                                          : ""
                                    }}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0" v-if="isFrontrunner">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                  <a @click="connectFrontrunner()">
                                    <button type="button" class="connectButton">
                                      Connect To Frontrunner
                                  </button>
                                  </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
                <div v-if="showProfile == 'billing'" style="background: #ffffff" class="basic-card">
                  <img v-if="showProfile == 'basic'" alt="..."
                    src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                    class="profilePic" />
                  <header class="bg-surface-primary pt-6" style="margin-right: 32px">
                    <div>
                      <div class="mb-npx">
                        <div class="row align-items-center">
                          <div class="col-md-11 col-6 mb-4 mb-sm-0">
                            <!-- Title -->
                          </div>
                          <!-- Actions -->
                          <div class="
                              col-md-1 col-6
                              mb-4 mb-sm-0
                              d-flex
                              justify-content-end
                            " @click="editBillingInfo()" style="cursor: pointer">
                            <img src="resources/assets/editIcon.png" />
                            <p class="edit-text">Edit</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>

                  <div>
                    <header class="bg-surface-primary border-bottom pt-6" style="margin-right: 32px">
                      <div>
                        <div class="mb-npx">
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/planType.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle">Plan Type</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    BUSINESS
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/mapIcon.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle">
                                    BILLING ADDRESS</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{
                                        adminDetails.b_billingAddress
                                          ? adminDetails.b_billingAddress
                                          : ""
                                    }}
                                    {{
                                        adminDetails.b_city
                                          ? adminDetails.b_city
                                          : ""
                                    }}
                                    {{
                                        adminDetails.b_state
                                          ? adminDetails.b_state
                                          : ""
                                    }}
                                    {{
                                        adminDetails.b_zip
                                          ? adminDetails.b_zip
                                          : ""
                                    }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0"></div>
                          </div>
                        </div>
                      </div>
                    </header>

                    <header class="bg-surface-primary border-bottom pt-6" style="margin-right: 32px">
                      <div>
                        <div class="mb-npx">
                          <div class="row align-items-center">
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons">
                                  <img src="resources/assets/creditCard.png" />
                                </div>
                                <div class="text-start">
                                  <a class="profileFormTitle"> CARD DETAILS</a>
                                  <br />
                                  <a class="profileFormTitleValue">
                                    {{ `XXXX XXXX XXXX ${adminDetails.b_cardNumber}` }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons"></div>
                                <div class="text-start">
                                  <a class="profileFormTitle"> EXP. DATE</a>
                                  <br />
                                  <a class="profileFormTitleValue"> {{ `${adminDetails.b_expDate}` }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 mb-4 mb-sm-0">
                              <div class="form-outline viewReviewInnerBox d-flex">
                                <div class="profileSettingIcons"></div>
                                <div class="text-start">
                                  <a class="profileFormTitle"> CCV</a>
                                  <br />
                                  <a class="profileFormTitleValue"> {{ `${adminDetails.b_cvv}` }} </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div v-if="show == 'help'">
          <Help />
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
<script>
import Vue from "vue";
import Vuex from "vuex";
import { mapActions, mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ActionBar from "./ActionBar";
import UsersModal from "./UsersModal";
import Feedback3 from "./Feedback3.vue";
import Feedback5 from "./Feedback5.vue";
import Reports from "./Reports.vue";
import Help from "./Help.vue";
import RequestReview from "./RequestReview";
import { VueEditor, Quill } from "vue2-editor";
import VueApexCharts from "vue-apexcharts";
Vue.use(Vuex);
export default {
  name: "Dashboard",
  components: {
    ActionBar,
    UsersModal,
    Feedback3,
    Feedback5,
    RequestReview,
    Datepicker,
    Reports,
    Help,
    VueEditor,
    Quill,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      vSteps: false,
      vStepsVideo: "",
      vStepsVideoList: [],
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        }
      },
      userSteps: [
        {
            target: '.v-step-0',  // We're using document.querySelector() under the hood
        },
        {
            target: '.v-step-1',
        },
        {
            target: '.v-step-2',
        },
        {
            target: '.v-step-3',
        },
        {
            target: '.v-step-4',
        },
      ],
      steps: [
        {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
        },
        {
            target: '#v-step-1',
        },
        {
            target: '#v-step-2',
        },
        {
            target: '#v-step-3',
        },
        {
            target: '#v-step-4',
        },
        {
            target: '#v-step-5',
        },
        {
            target: '#v-step-6',
        },
        {
            target: '#v-step-7',
        },
        {
            target: '#v-step-8',
        },
        {
            target: '#v-step-9',
        },
        {
            target: '#v-step-10',
        },
  ],
      myCallbacks: {
        onNextStep: this.myCustomNextStepCallback,
        onPreviousStep: this.myCustomPreviousStepCallback,
        onStop:this.myCustomStopCallback,
      },
      userCallbacks: {
        onNextStep: this.userCustomNextStepCallback,
        onPreviousStep: this.userCustomPreviousStepCallback,
        onStop:this.userCustomStopCallback,
      },
      tour: (localStorage.getItem("loginFirstTime") == 1 && localStorage.getItem("isAdmin") == 1)? true : false,
      userTour: (localStorage.getItem("loginFirstTime") == 1 && localStorage.getItem("isAdmin") == 0)? true : false,
      wizard: false,
      moment: moment,
      isActive: false,
      call: true,
      showProfile: "basic",
      show: "dashboard",
      userModalHeading: "Create New",
      adminDetails: {
        id: "",
        firstName: "",
        lastName: "",
        phone: "",
        billing_address: "",
        city: "",
        state: "",
        email: "",
        pincode: "",
        organisation_name: "",
        adminId: "",
        b_firstName: "",
        b_lastNAme: "",
        b_billingAddress: "",
        b_city: "",
        b_state: "",
        b_zip: "",
        b_cardNumber: "",
        b_expDate: "",
        b_cvv: "",
        fileSelected: null,
        profile: "",
      },

      userList: [],
      reviewUserList: [],
      reviewsList: [],
      fethedReviewsList: [],
      reviewStatus: "",
      feedbackList: [],
      feedbackStatus: "",
      feedbackViewData: [],
      reviewViewData: [],
      reviewsPageNumber: 1,
      reviewsLastPageNumber: "",
      currentReview: [],
      currentCustomerReview: [],
      userModal: false,
      reviewModal: false,
      userListData: false,
      feedbackUserList: false,
      feedbackModal: false,
      feedbackViewModal: false,
      customerFeedbackModal: false,
      customerFeedbackModalEdit: false,
      customerReviewModal: false,
      customerReviewModalEdit: false,
      adminProfileModal: false,
      adminBillingInfoModal: false,
      adminBusinessModal: false,
      buttonText: "Create",
      selectedUsers: [],
      filterOptions: [
        {
          platform: {
            title: "platform",
            data: ["Google", "Facebook", "Yelp", "Zillow"],
          },
          "By User": {
            title: "By User",
          },
          "By Ranking": {
            title: "By Ranking",
            data: ["1", "2", "3", "4", "5"],
          },
        },
      ],
      userForm: {
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      passwordFieldType: "password",
      passwordIcon: "bi bi-eye-slash",
      cPasswordFieldType: "password",
      cPasswordIcon: "bi bi-eye-slash",
      userLoginId: "",
      businessName: "",
      business_name: "",
      placeID: "",
      platForm: "",
      scrollToEmaill: false,
      feedbackId: "",
      userAssignId: "",
      followUpNotes: "",
      assignUserName: "",
      communication: {
        id: "",
        timezone: "",
        emailSendFromName: "",
        textSendFromName: "",
        replyEmail: "",
        // textNumberAreaCode: "",
        reviewRequestTextDefaultMessage: "",
        defaultThreeStarResponseEmail: "",
        defaultThreeStarResponseText: "",
        reviewRequestEmailDefaultMessage: "",
        threeStarThankyouMessage: "",
        FourFiveReviewMessage: "",
        thankyouMessage: "",
        thankyouEmail: "",
        communicationLogo: null,
        reviewRequestMainPageMessage: "",
        reviewRequestThreeStarMessage: "",
        reviewRequestFourFiveStarMessage: "",
      },
      googlePlatform: false,
      googleDate: "",
      facebookDate: "",
      yelpDate: "",
      zillowDate: "",
      facebookPlatform: false,
      yelpPlatform: false,
      zillowPlatform: false,
      showRefresh: false,
      timeZoneList: [],
      filterList: {
        platform: [],
        user: [],
        ranking: [],
        startDate: "",
        endDate: "",
        userName:[]
      },
      module: "",
      facebookCheck: false,
      reviewCurrentPage: 1,
      reviewPerPage: 20,
      reviewCount: 0,
      feedbackCurrentPage: 1,
      feedbackPerPage: 20,
      feedbackCount: 0,
      usersCurrentPage: 1,
      usersPerPage: 20,
      usersCount: 0,
      loading: false,
      yelpLoading: false,
      searchText: "",
      location: "",
      searchRange: 100,
      yelpList: [],
      yelpBusiness: "",
      yelpBusinessText: "",
      yelpBusinessModal: false,
      yelpBusinessName: "",
      locations: [],
      categories: [],
      category: "",
      zillowModal: false,
      disconnectModal: false,
      platformHistoryModal: false,
      screenName: "",
      countryCodelist: [],
      feedbackCode: "",
      reviewCode: "",
      feedbackCountryCode: "",
      reviewCountryCode: "",
      checkPermission: false,
      isFrontrunner: false,
      apiLoading: false,
      googleColor: false,
      fbColor: false,
      yelpColor: false,
      zillowColor: false,
      platfomeName: "",
      disconnectLoading: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      series: [
        {
          name: "Reviews",
          type: "column",
          data: [],
        },
      ],
      series_fb: [
        {
          name: "Reviews",
          type: "column",
          data: [],
        },
      ],
      series_yelp: [
        {
          name: "Reviews",
          type: "column",
          data: [],
        },
      ],
      series_zillow: [
        {
          name: "Reviews",
          type: "column",
          data: [],
        },
      ],
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: "12%",
            startingShape: "round",
            endingShape: "round",
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        colors: ["#FA5244", "#6A8FCD"],
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
        },
        stroke: {
          show: true,
          width: [0, 4],
          curve: "smooth",
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          distributed: false,
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#6A8FCD",
            opacity: 0.9,
          },
        },
        fill: {
          colors: ["#FA5144"],
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#FDD640"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: [
          {
            title: {
              text: "Reviews",
            },
          },
        ],
        markers: {
          size: 10,
          colors: "#6A8FCD",
          strokeColors: "#6A8FCD",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 4,
          offsetX: 0,
          offsetY: 0,
          onClick: function (e) {
            // do something on marker click
            console.log("e", e);
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          shared: false,
          intersect: true,
          enabled: true,
          marker: {
            show: true,
          },
        },
      },
      chartOptions_fb: {
        plotOptions: {
          bar: {
            columnWidth: "12%",
            startingShape: "round",
            endingShape: "round",
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        colors: ["#FA5244", "#6A8FCD"],
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
        },
        stroke: {
          show: true,
          width: [0, 4],
          curve: "smooth",
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          distributed: false,
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#6A8FCD",
            opacity: 0.9,
          },
        },
        fill: {
          colors: ["#FA5144"],
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#FDD640"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: [
          {
            title: {
              text: "Reviews",
            },
          },
        ],
        markers: {
          size: 10,
          colors: "#6A8FCD",
          strokeColors: "#6A8FCD",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 4,
          offsetX: 0,
          offsetY: 0,
          onClick: function (e) {
            // do something on marker click
            console.log("e", e);
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          shared: false,
          intersect: true,
          enabled: true,
          marker: {
            show: true,
          },
        },
      },
      chartOptions_yelp: {
        plotOptions: {
          bar: {
            columnWidth: "12%",
            startingShape: "round",
            endingShape: "round",
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        colors: ["#FA5244", "#6A8FCD"],
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
        },
        stroke: {
          show: true,
          width: [0, 4],
          curve: "smooth",
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          distributed: false,
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#6A8FCD",
            opacity: 0.9,
          },
        },
        fill: {
          colors: ["#FA5144"],
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#FDD640"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: [
          {
            title: {
              text: "Reviews",
            },
          },
        ],
        markers: {
          size: 10,
          colors: "#6A8FCD",
          strokeColors: "#6A8FCD",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 4,
          offsetX: 0,
          offsetY: 0,
          onClick: function (e) {
            // do something on marker click
            console.log("e", e);
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          shared: false,
          intersect: true,
          enabled: true,
          marker: {
            show: true,
          },
        },
      },
      chartOptions_zillow: {
        plotOptions: {
          bar: {
            columnWidth: "12%",
            startingShape: "round",
            endingShape: "round",
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        colors: ["#FA5244", "#6A8FCD"],
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
        },
        stroke: {
          show: true,
          width: [0, 4],
          curve: "smooth",
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          distributed: false,
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#6A8FCD",
            opacity: 0.9,
          },
        },
        fill: {
          colors: ["#FA5144"],
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#FDD640"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: [
          {
            title: {
              text: "Reviews",
            },
          },
        ],
        markers: {
          size: 10,
          colors: "#6A8FCD",
          strokeColors: "#6A8FCD",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 4,
          offsetX: 0,
          offsetY: 0,
          onClick: function (e) {
            // do something on marker click
            console.log("e", e);
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          shared: false,
          intersect: true,
          enabled: true,
          marker: {
            show: true,
          },
        },
      },
      googleAllReview: "",
      googleAllReviewRating: "",
      googleStarted: "",
      googleCurrentReview: "",
      googleCurrentReviewRating: "",
      googleRecentReview: [],
      googleAllTime: "",
      googleView: '',
      googleThisMonth: "",
      fbAllReview: "",
      fbAllReviewRating: "",
      fbStarted: "",
      fbCurrentReview: "",
      fbCurrentReviewRating: "",
      fbRecentReview: [],
      fbAllTime: "",
      fbThisMonth: "",
      fbView: "",
      yelpAllReview: "",
      yelpAllReviewRating: "",
      yelpStarted: "",
      yelpCurrentReview: "",
      yelpCurrentReviewRating: "",
      yelpRecentReview: [],
      yelpAllTime: "",
      yelpThisMonth: "",
      yelpView: "",
      zillowAllReview: "",
      zillowAllReviewRating: "",
      zillowStarted: "",
      zillowCurrentReview: "",
      zillowCurrentReviewRating: "",
      zillowRecentReview: [],
      zillowAllTime: "",
      zillowThisMonth: "",
      zillowView: "",
      communicationImg: "",
      googleName: "",
      fbName: "",
      yelpName: "",
      zillowName: "",
      filterPlatform: "",
      platformHistoryList: [],
      superAdminToken: "",
      userEditIcon: false,
      googleConnectIcon: false,
      facebookConnectIcon: false,
      yelpConnectIcon: false,
      zillowConnectIcon: false,
      userCreateIcon: false,
      isAdmin: false,
    };
  },

  methods: {
    ...mapActions({
      createNewUser: "users/createUser",
      userDelete: "users/deleteUser",
      userUpdate: "users/updateUser",
      multipleUserDelete: "users/deleteMultipleUser",
      getAllUserList: "users/getAllUserList",
      getAllReviewList: "reviews/getAllReviewList",
      deleteReviews: "reviews/deleteReview",
      getFeedbackLists: "feedbacks/getFeedbackList",
      feedbackDelete: "feedbacks/deleteFeedback",
      userProfileSettings: "settings/userProfileSettings",
      updateProfiles: "settings/updateProfile",
      billingInfo: "settings/billingInfo",
    }),

    getNameIntials(name) {
      return name
        .split(" ")
        .map((x) => x.charAt(0))
        .join("")
        .substr(0, 2)
        .toUpperCase();
    },
    searchYelpBusiness() {
      this.searchText = "";
      this.location = "";
      this.yelpBusinessModal = true;
    },

    openZillowModal() {
      this.screenName = "";
      this.zillowModal = true;
    },

    onChangeBusiness(e) {
      this.yelpBusinessText =
        e.target.options[e.target.options.selectedIndex].value;
    },

    async saveYelpBusiness() {
      let result = await this.$validator.validateAll();
      if (result && this.searchText) {
        let data = {
          userId: this.userLoginId,
          url: this.yelpBusinessText,
          term: this.searchText,
          location: this.location,
        };
        this.yelpLoading = true;
        this.call = false;
        this.$store
          .dispatch("yelpReview", data)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.yelpBusinessModal = false;
              this.yelpPlatform = true;
              this.checkConnectedPlatform();
            } else {
              this.$toastr.e(`${response.message}`);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => ((this.yelpLoading = false), (this.call = true)));
      }
    },
    async saveZillow() {
      let result = await this.$validator.validateAll();
      if(result && this.screenName){
        let data = {
          userId: this.userLoginId,
          screenname: this.screenName,
        };
        this.loading = true;
        this.call = false;
        this.$store
          .dispatch("zillowReview", data)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.zillowModal = false;
              this.zillowPlatform = true;
              this.checkConnectedPlatform();
            } else{
                this.$toastr.e(`${response.message}`);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => ((this.loading = false), (this.call = true)));
      }
    },

    statusChangeCallback(response) {
      // Called with the results from FB.getLoginStatus().
      if (response.status === "connected") {
        // Logged into your webpage and Facebook.
        this.testAPI();
      } else {
        // Not logged into your webpage or we are unable to tell.
        document.getElementById("status").innerHTML =
          "Please log " + "into this webpage.";
      }
    },

    checkLoginState() {
      let vm = this;
      FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          // Logged into your webpage and Facebook.
          response.user_id = vm.userLoginId;
          vm.$store.dispatch("facebookConnect", response).then((response) => {
            vm.checkConnectedPlatform();
            if (response.success) {
              vm.$toastr.s(`${response.message}`);
          } else {
            vm.$toastr.e(`${response.message}`);
          }
          });
        } else {
          FB.login(
            (response) => {
              if (response.status === "connected") {
                vm.facebookCheck = true;
                // Logged into your webpage and Facebook.
                response.user_id = vm.userLoginId;

                vm.$store
                  .dispatch("facebookConnect", response)
                  .then((response) => {
                    vm.checkConnectedPlatform();
                    if (response.success) {
                        vm.$toastr.s(`${response.message}`);
                      } else {
                        vm.$toastr.e(`${response.message}`);
                      }
                  });
              }
            },
            {
              scope:
                "email,pages_read_user_content,pages_show_list,pages_read_engagement",
              return_scopes: true,
            }
          );
        }
      });

      if (vm.facebookCheck) {
        response.user_id = vm.userLoginId;

        vm.$store.dispatch("facebookConnect", response).then((response) => {
          vm.checkConnectedPlatform();
          if (response.success) {
            vm.$toastr.s(`${response.message}`);
          } else {
            vm.$toastr.e(`${response.message}`);
          }
        });
      }
      // vm.checkConnectedPlatform();
    },
    fbLogout() {
      FB.logout(function (response) {
        console.log("response",response);
        // Person is now logged out
      });
      vm.checkConnectedPlatform();
    },

    changeScrollView() {
      this.scrollToEmaill = !this.scrollToEmaill;
    },
    getFeedbackId(data) {
      if (data.rating > 0 && data.rating <= 3) {
        this.showDiv("feedback3");
        this.myCustomStopCallback();
      }
      if (data.rating > 3 && data.rating <= 5) {
        this.showDiv("feedback5");
        this.myCustomStopCallback();
      }
      this.feedbackId = data.id;
    },
    initialize(e) {
      var self = this;
      let input = document.getElementById("autocomplete");
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        let place = autocomplete.getPlace();
        self.business_name = place.name;
        self.businessName = place.name;
        self.placeID = place.place_id;
        self.platForm = "google";
      });
    },
    initializeYelp(e) {
      var self = this;
      let input = document.getElementById("yelpComplete");
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        let place = autocomplete.getPlace();
        self.location = place.formatted_address;
      });
    },
    initializeBillingAddress(e) {
      var self = this;
      let input = document.getElementById("billing_address");
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        let place = autocomplete.getPlace();
        let addressComponent = place.address_components
        let city =addressComponent.find(d => {
          return d.types[0] === 'locality'}
        ); 

        let postal_code =addressComponent.find(d => {
          return d.types[0] === 'postal_code'}
        ); 
        let state =addressComponent.find(d => {
          return d.types[0] === 'administrative_area_level_1'}
        ); 

        self.adminDetails.billing_address = place.name
        self.adminDetails.city = city.long_name
        self.adminDetails.state =state.long_name
        self.adminDetails.pincode= postal_code.long_name
      });
    },
    initializeBillingInfo(e) {
      var self = this;
      let input = document.getElementById("b_billingAddress");
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        let place = autocomplete.getPlace();
        let addressComponent = place.address_components
        let city =addressComponent.find(d => {
          return d.types[0] === 'locality'}
        ); 
        let postal_code =addressComponent.find(d => {
          return d.types[0] === 'postal_code'}
        ); 
        let state =addressComponent.find(d => {
          return d.types[0] === 'administrative_area_level_1'}
        ); 

        self.adminDetails.b_billingAddress = place.name
        self.adminDetails.b_city = city.long_name
        self.adminDetails.b_state =state.long_name
        self.adminDetails.b_zip= postal_code.long_name
      });
    },

    async saveBusiness() {
      let result = await this.$validator.validateAll();
      if(result){
      let businessData = {
        user_id: this.userLoginId,
        platformName: this.platForm,
        business_name: this.business_name,
        placeID: this.placeID,
      };
      this.loading = true;
      this.call = false;
      this.$store
        .dispatch("businessAdd", businessData)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.adminBusinessModal = false;
            this.businessName = "";
            this.checkConnectedPlatform();
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => ((this.loading = false), (this.call = true)));
      }
    },

    async saveReviewFeedback(data) {
      let result = await this.$validator.validateAll();
      if (result) {
      this.apiLoading = true;
        let popupData = {
          id: data.id,
          followUpNotes: this.followUpNotes,
          userID: this.userAssignId,
          assignment: this.assignUserName,
        };
        this.$store.dispatch("createFeedback", popupData).then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.reviewModal = false;
            let reqParam = {
                page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
                userId: this.userLoginId,
                platformName: this.filterList.platform ? this.filterList.platform : "",
                reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
                assignedUser: this.filterList.user ? this.filterList.user : "",
                fromDate: this.filterList.startDate ? this.filterList.startDate : "",
                toDate: this.filterList.endDate ? this.filterList.endDate : "",
                module: this.module,
              };
            this.getReviewsList(reqParam);
          } else {
            this.$toastr.e("Error while assigning user");
            this.reviewModal = true;
          }
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      }
    },

    async respondNow(data) {
      let result = await this.$validator.validateAll();
      if (result) {
      this.apiLoading = true;
        let popupData = {
          id: data.id,
          followUpNotes: this.followUpNotes,
          userID: this.userAssignId,
          assignment: this.assignUserName,
        };
        this.$store.dispatch("createFeedback", popupData).then((response) => {
          if (response.success) {
            this.reviewModal = false;
            this.getFeedbackId(response.data);
            let reqParam = {
                page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
                userId: this.userLoginId,
                platformName: this.filterList.platform ? this.filterList.platform : "",
                reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
                assignedUser: this.filterList.user ? this.filterList.user : "",
                fromDate: this.filterList.startDate ? this.filterList.startDate : "",
                toDate: this.filterList.endDate ? this.filterList.endDate : "",
                module: this.module,
              };
            this.getReviewsList(reqParam);
            this.reviewModal = false;
          }else{
            this.$toastr.e(`${response.message}`);
            this.reviewModal = true;
          }
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      }
    },

    onAssignUser(e) {
      let assignName = e.target.options[e.target.options.selectedIndex].text;
      this.assignUserName = assignName;
    },

    async googleInit() {
      this.checkConnectedPlatform();
      if (this.adminBusinessModal) {
        this.businessName = "";
        this.adminBusinessModal = true;
      } else {
        const googleUser = await this.$gAuth.signIn();
        googleUser.userId = this.userLoginId ? this.userLoginId : "";
        this.$store
          .dispatch("googleConnect", googleUser)
          .then((response) => {
            if (response.success) {
              this.businessName = "";
              this.adminBusinessModal = true;
            }
          })
          .catch((err) => {
            console.log({ err: err });
          });
      }
    },
    disconnectPlatform(platform) {
      this.platfomeName = platform;
      this.disconnectModal = true;
    },

    disconnect() {
      let data = {
        data: {
          userId: this.userLoginId,
        },
        platform: this.platfomeName,
      };
      (this.disconnectLoading = true),
        this.$store
          .dispatch("platformDisconnect", data)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.checkConnectedPlatform();
              this.disconnectModal = false;
              if(this.platfomeName == 'facebook'){
                this.fbLogout();
              }
            } else {
              this.$toastr.e(`${response.message}`);
            }
          })
          .catch((err) => {
            console.log({ err: err });
          })
          .finally(() => {
            this.platfomeName = "";
            this.disconnectLoading = false;
          });
    },

    syncGoogleBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;

      this.$store
        .dispatch("googleSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    syncFacebookBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;

      this.$store
        .dispatch("fbSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },

    syncYelpBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;

      this.$store
        .dispatch("yelpSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    synczillowBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;
      this.$store
        .dispatch("zillowSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    businessHistory(platform){
      let data = {
        userId: this.userLoginId,
        platformName: platform
      };
      this.apiLoading = true;
      this.$store
        .dispatch("connectedHistory", data)
        .then((response) => {
          if (response.success) {
            this.platformHistoryList = response.data.data ? response.data.data : [];
          } else {
            this.platformHistoryList = []
          }
        this.platformHistoryModal = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    deletePlatform(platform){
      let data = {
        userId: this.userLoginId,
        platformName: platform.platformName,
        businessName: platform.businessName
      }
      this.apiLoading = false;

      this.$store
        .dispatch("platformHistoryDelete", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.businessHistory(platform.platformName);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    loadMoreReviews() {
      this.reviewsPageNumber = this.reviewsPageNumber + 1;
      let reqParam = {
        page: this.reviewsPageNumber,
        userId: this.userLoginId,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.getReviewsList(reqParam);
    },

    /**
     * @desc: Show Page
     * @param: page
     * @return: None
     * */
    showPage(page) {
      this.reviewCurrentPage = page;
      let reqParam = {
        page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
        userId: this.userLoginId,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.getReviewsList(reqParam);
    },

    /**
     * @desc: Show Page
     * @param: page
     * @return: None
     * */
    showFeedbackPage(page) {
      // this.currentPage = page;
      let reqParam = {
        page: page ? page : 1,
        userId: localStorage.getItem("isAdmin") == 0 ? this.userLoginId : "",
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
        parentUserID:
          localStorage.getItem("isAdmin") == 1 ? this.userLoginId : "",
      };
      this.getFeedbackList(reqParam);
    },

    /**
     * @desc: Show Page
     * @param: page
     * @return: None
     * */
    showUserPage(page) {
      this.usersCurrentPage = page;
      this.getUserList();
    },

    onChangeFile: function (event) {
      this.adminDetails.fileSelected = event.target.files[0];
    },
    settingMount(){
      let isAdmin = localStorage.getItem("isAdmin");
        let frontRunner = localStorage.getItem("frontRunner");
        if (isAdmin == true) {
          this.checkPermission = true;
        } else {
          this.checkPermission = false;
        }
        if (isAdmin == true && frontRunner == true) {
          this.isFrontrunner = true;
        } else {
          this.isFrontrunner = false;
        }
        this.getUserProfileSettings();
        this.checkConnectedPlatform();
        this.communication.communicationLogo = "";
        this.fetchCommunication();
        this.show = 'settings'
        this.showProfile = 'basic';
    },
    feebackMount(){
      this.feedbackCurrentPage = 1;
        this.scrollToEmaill = false;
        this.feedbackViewModal = false;
        this.customerFeedbackModal = false;
        this.getFeedbackList();
        this.countryCode();
    },
    reviewsMount(){
      this.reviewCurrentPage = 1;
        this.getReviewsList();
        this.reviewUserLists();
        this.checkConnectedPlatform();
        this.showRefresh =
          this.googlePlatform ||
          // this.facebookPlatform ||
          this.yelpPlatform ||
          this.zillowPlatform
            ? true
            : false;
        this.countryCode();
    },
    showDiv(data) {
      console.log(data);
      this.clearFilterData();
      this.newReviews();
      this.newFeedabck();
      this.selectedUsers = [];
      if (data === "settings") {
        this.settingMount();
        this.myCustomStopCallback();
      }
      if (data === "reviews") {
        this.reviewsMount();
        this.myCustomStopCallback();
      }
      if (data === "feedback") {
        this.feebackMount();
        this.myCustomStopCallback();
      }
      if (data === "users") {
        this.getUserList();
        this.userModal = false;
        this.myCustomStopCallback();
      }
      if (data === "dashboard") {
        this.dashboardApiGoogleData();
        this.dashboardApiFBData();
        this.dashboardApiYelpData();
        this.dashboardApizillowData();
        this.myCustomStopCallback();
      }
      if (data === "reports") {
        this.filterPlatform = "";
        this.myCustomStopCallback();
      }
      if (data === "request_reviews") {
        this.filterPlatform = "";
        this.myCustomStopCallback();
      }
      if (data === "help") {
        this.filterPlatform = "";
        this.myCustomStopCallback();
      }
      if (data === "setup") {
        this.filterPlatform = "";
        this.show = 'dashboard';
        this.$router.push('/login');
        localStorage.setItem("loginFirstTime",1)
        this.wizard = true;
        this.tour = true;
        this.userTour = true;
      }      
      this.show = data;
      this.isActive = !this.isActive;
      if(this.tour || this.userTour){
          this.wizardData();
        }
    },
    getUserProfileSettings() {
      // await this.userProfileSettings().then((response) => {
      this.$store.dispatch("userProfileSettings").then((response) => {
        let res = response.data[0];

        this.adminDetails.id = res.id;
        this.adminDetails.firstName = res.first_name;
        this.adminDetails.lastName = res.last_name;
        this.adminDetails.phone = res.phone;
        this.adminDetails.billing_address = res.billing_address;
        this.adminDetails.city = res.city;
        this.adminDetails.state = res.state;
        this.adminDetails.email = res.email;
        this.adminDetails.adminId = res.adminId;
        this.adminDetails.pincode = res.zip_code;
        this.adminDetails.organisation_name = res.organisation_name;
        this.adminDetails.b_firstName = res.b_first_name;
        this.adminDetails.b_lastNAme = res.b_last_name;
        this.adminDetails.b_billingAddress = res.b_billing_address;
        this.adminDetails.b_city = res.b_city;
        this.adminDetails.b_state = res.b_state;
        this.adminDetails.b_zip = res.b_zip_code;
        // this.adminDetails.b_cardNumber = res.b_cardNumber;
        // this.adminDetails.b_expDate = res.b_expDate;
        this.adminDetails.b_cvv = '***';
        this.adminDetails.b_cardNumber = res.last4;
        this.adminDetails.b_expDate = `${res.exp_month}/${res.exp_year}`;
        this.adminDetails.profile = res.file;
      });
    },
    checkConnectedPlatform() {
      let data = {
        userId: this.userLoginId,
      };
      this.$store.dispatch("platformCheck", data).then((response) => {
        let res = response.data ? response.data[0] : "";
        this.googleName = res.googleBusinessName ? res.googleBusinessName : "";
        this.fbName = res.facebookPages ? res.facebookPages[0] : "";
        this.yelpName = res.yelpTerm ? res.yelpTerm : "";
        this.zillowName = res.zillowscreenName
          ? res.zillowscreenName.substring(
              res.zillowscreenName.lastIndexOf("/") + 1,
              res.zillowscreenName.length
            )
          : "";
        res.google
          ? (this.googlePlatform = true)
          : (this.googlePlatform = false);
        res.facebook
          ? (this.facebookPlatform = true)
          : (this.facebookPlatform = false);
        res.yelp ? (this.yelpPlatform = true) : (this.yelpPlatform = false);
        res.zillow
          ? (this.zillowPlatform = true)
          : (this.zillowPlatform = false);

        this.googleDate = res.googleDate
          ? moment(String(res.googleDate)).format("MM-DD-YYYY")
          : "";
        this.facebookDate = res.facebookDate
          ? moment(String(res.facebookDate)).format("MM-DD-YYYY")
          : "";
        this.yelpDate = res.yelpDate
          ? moment(String(res.yelpDate)).format("MM-DD-YYYY")
          : "";
        this.zillowDate = res.zillowDate
          ? moment(String(res.zillowDate)).format("MM-DD-YYYY")
          : "";
      });
    },
    showProfiles(data) {
      console.log(data);
      console.log("I am clicked");
      if (data === "basic" || data === "billing") {
        this.getUserProfileSettings();
      }
      if (data === "communication") {
        this.fetchCommunication();
        this.communication.communicationLogo = "";
      }
      if(data === "platform"){
        this.checkConnectedPlatform();
      }

      this.showProfile = data;
    },
    cancel() {
      this.userModal = false;
      this.reviewModal = false;
      this.feedbackModal = false;
      this.feedbackViewModal = false;
      this.customerFeedbackModal = false;
      this.adminProfileModal = false;
      this.adminBillingInfoModal = false;
      this.adminBusinessModal = false;
      this.buttonText = "Create";
      this.userModalHeading = "Create New";
      this.userForm.userId = "";
      this.userForm.firstName = "";
      this.userForm.lastName = "";
      this.userForm.email = "";
      this.userForm.password = "";
      this.userForm.confirmPassword = "";
      this.yelpBusinessModal = false;
      this.zillowModal = false;
      this.disconnectModal = false;
      this.platformHistoryModal=false;
      this.platfomeName = "";
      this.customerReviewModal = "";
      this.vSteps = false;
    },
    getReviewsList(filterConditions) {
      let data;
      if (filterConditions) {
        data = filterConditions;
      } else {
        data = {
          page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
          userId: this.userLoginId,
        };
      }

      this.$store.dispatch("reviewsList", data).then((response) => {
        this.reviewsList = response.data;
        this.reviewCount = response.total;
      });
    },

    // All Methods Are Related To Feedback Starts Here
    getFeedbackList(filterConditions) {
      let data;
      if (filterConditions) {
        data = filterConditions;
      } else {
        data = {
          page: this.feedbackCurrentPage ? this.feedbackCurrentPage : 1,
          userId: localStorage.getItem("isAdmin") == 0 ? this.userLoginId : "",
          platformName: this.filterList.platform
            ? this.filterList.platform
            : "",
          reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
          assignedUser: this.filterList.user ? this.filterList.user : "",
          fromDate: this.filterList.startDate ? this.filterList.startDate : "",
          toDate: this.filterList.endDate ? this.filterList.endDate : "",
          module: this.module,
          parentUserID:
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : "",
        };
      }
      // this.getFeedbackLists().then((response) => {
      this.$store.dispatch("feedbackList", data).then((response) => {
        this.feedbackList = response.data;
        this.feedbackCount = response.total;
      });
    },

    deleteFeedback(id) {
      // await this.feedbackDelete(id).then((response) => {
      this.$store.dispatch("deleteFeedback", id).then((response) => {
        if (response.success) {
          let reqParam = {
            page: this.feedbackCurrentPage ? this.feedbackCurrentPage : 1,
            userId:
              localStorage.getItem("isAdmin") == 0 ? this.userLoginId : "",
            platformName: this.filterList.platform
              ? this.filterList.platform
              : "",
            reviewRating: this.filterList.ranking
              ? this.filterList.ranking
              : "",
            assignedUser: this.filterList.user ? this.filterList.user : "",
            fromDate: this.filterList.startDate
              ? this.filterList.startDate
              : "",
            toDate: this.filterList.endDate ? this.filterList.endDate : "",
            module: this.module,
            parentUserID:
              localStorage.getItem("isAdmin") == 1 ? this.userLoginId : "",
          };
          this.getFeedbackList(reqParam);
          this.newFeedabck();
          this.$toastr.s(`${response.message}`);
        } else {
          this.$toastr.e(`${response.message}`);
        }
      });
    },

    pushAllSelectedFeedbacks(e) {
      if (e.target.checked) {
        this.selectedUsers = this.feedbackList.map((x) => x.id);
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = true;
        });
      } else {
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = false;
        });
        this.selectedUsers = [];
      }
    },

    pushSelectedFeedbacks(e, id) {
      if (e.target.checked) {
        this.selectedUsers.push(id);
      } else {
        const index = this.selectedUsers.indexOf(id);
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        }
      }
    },

    viewFeedback(id,status) {
      if(status) {
        this.$store.dispatch("feedbackReadCount", id).then((response) => {
          if(response.success){
            this.getFeedbackList();
            this.newFeedabck();
          }
        });
      }
      this.feedbackViewData = [];
      this.$store.dispatch("viewFeedback", id).then((response) => {
        this.feedbackViewData = response.data[0];
        this.feedbackViewModal = true;
      });
    },

    feedbackModalOpen() {
      this.feedbackModal = true;
    },

    customerFeedbackModalOpen(id) {
      this.customerFeedbackModalEdit = false;
      this.feedbackViewData = [];
      this.$store.dispatch("viewFeedback", id).then((response) => {
        this.feedbackViewData = response.data[0];
        let countryData = this.countryCodelist.find(
          (element) => element.shortName == this.feedbackViewData.shortName
        );
        this.feedbackCode =
          countryData && countryData.shortName ? countryData.shortName : "";
        this.feedbackCountryCode =
          countryData && countryData.name && countryData.code
            ? `${countryData.name} (+${countryData.code}) `
            : "";
        this.customerFeedbackModal = true;
      });
    },
    editFeedback() {
      this.customerFeedbackModalEdit = true;
    },
    countryCode() {
      this.$store.dispatch("requestCountryCode").then((response) => {
        if (response.success) {
          this.countryCodelist = response.data;
        }
      });
    },
    async updateFeedback() {
      let result = await this.$validator.validateAll();
      if (result) {
        let countryCode = this.countryCodelist.find(
          (element) => element.shortName == this.feedbackCode
        );

        let data = {
          id: this.feedbackViewData.id,
          userID: this.userLoginId,
          email: this.feedbackViewData.email,
          phone: this.feedbackViewData.phoneNumber,
          code: countryCode && countryCode.code ? "+" + countryCode.code : "+1",
          shortName:
            countryCode && countryCode.shortName ? countryCode.shortName : "US",
        };
        this.$store
          .dispatch("feedbackUpdate", data)
          .then((response) => {
            if (response.success) {
              this.customerFeedbackModalEdit = false;
              this.feedbackCountryCode =
                countryCode && countryCode.name && countryCode.code
                  ? `${countryCode.name} (+${countryCode.code}) `
                  : "";

              this.$toastr.s(`${response.message}`);
            } else {
              if (response.data.email) {
                this.$toastr.e(response.data.email);
              }
              if (response.data.phone) {
                this.$toastr.e(response.data.phone);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // All Methods Are Related To Feedback Ends Here

    // All FMethods Are Related To Users Starts Here
    getUserList() {
      let data = {
        page: this.usersCurrentPage ? this.usersCurrentPage : 1,
      };
      this.$store.dispatch("userList", data).then((response) => {
        this.userList = response.data;
        this.usersCount = response.total;
      });
    },
    reviewUserLists() {
      this.$store.dispatch("allUser").then((response) => {
        if (response.data) {
          this.reviewUserList = response.data;
        } else {
          this.reviewUserList = [];
        }
      });
    },
    createUser() {
      if (
        this.userForm.firstName === "" ||
        this.userForm.lastName === "" ||
        this.userForm.email === "" ||
        this.userForm.password === "" ||
        this.userForm.confirmPassword === ""
      ) {
        this.$toastr.e("Please fill all the required fields");
      } else {
        this.apiLoading =true;
        // this.createNewUser(this.userForm).then((response)=> {
        this.$store.dispatch("createUser", this.userForm).then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.userModal = false;
            this.getUserList();
          } else {
            if (response.data.email) {
              this.$toastr.e(response.data.email);
            }
            if (response.data.password) {
              this.$toastr.e(response.data.password);
            }
            if (response.data.c_password) {
              this.$toastr.e(response.data.c_password);
            }
          }
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      }
    },
    deleteUser(id) {
      // await this.userDelete(id).then((response) => {
      this.$store.dispatch("deleteUser", id).then((response) => {
        if (response.success) {
          this.getUserList();
          this.$toastr.s(`${response.message}`);
        } else {
          this.$toastr.e("something went wrong, Please try again later");
        }
      });
    },
    editUser(userId) {
      this.userModal = true;
      const selectedUser = this.userList.find((x) => x.id === userId);
      this.userForm.userId = selectedUser.id;
      this.userForm.firstName = selectedUser.first_name;
      this.userForm.lastName = selectedUser.last_name;
      this.userForm.email = selectedUser.email;
      this.userForm.password = selectedUser.userToken;
      this.userForm.confirmPassword = selectedUser.userToken;
      this.buttonText = "Update";
      this.userModalHeading = "Update User";
    },
    updateUser() {
      if (
        this.userForm.firstName === "" ||
        this.userForm.lastName === "" ||
        this.userForm.email === "" ||
        this.userForm.password === "" ||
        this.userForm.confirmPassword === ""
      ) {
        this.$toastr.e("Please fill all the required fields");
      } else {
        this.apiLoading = true;
        // await this.userUpdate(this.userForm).then((response) => {
        this.$store.dispatch("updateUser", this.userForm).then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.userModal = false;
            this.getUserList();
          } else {
            if (response.data.email) {
              this.$toastr.e(response.data.email);
            }
            if (response.data.password) {
              this.$toastr.e(response.data.password);
            }
            if (response.data.c_password) {
              this.$toastr.e(response.data.c_password);
            }
          }
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      }
    },
    pushSelectedUsers(e, id) {
      if (e.target.checked) {
        this.selectedUsers.push(id);
      } else {
        const index = this.selectedUsers.indexOf(id);
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        }
      }
    },
    pushAllSelectedUsers(e) {
      if (e.target.checked) {
        this.selectedUsers = this.userList.map((x) => x.id);
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = true;
        });
      } else {
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = false;
        });
        this.selectedUsers = [];
      }
    },
    deleteMultipleUsers() {
      const selectedUsersString = this.selectedUsers.toString();
      // this.multipleUserDelete(selectedUsersString)
      this.$store
        .dispatch("deleteMultipleUser", selectedUsersString)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.userModal = false;
            this.getUserList();
          } else {
            this.$toastr.e(`${response.message}`);
          }
        });
    },
    // All Methods Related To User Ends Here

    // All Methods Related To Reviews Starts Here
    viewReview(id,status) {
      this.followUpNotes = "";
      this.userAssignId = "";
      this.currentReview = this.reviewsList.find((x) => x.id === id);
      this.yelpColor = this.currentReview.platformName == "yelp" ? true : false;
      this.googleColor =
        this.currentReview.platformName == "google" ? true : false;
      this.zillowColor =
        this.currentReview.platformName == "zillow" ? true : false;
      this.fbColor =
        this.currentReview.platformName == "facebook" ? true : false;

      this.userAssignId = this.currentReview.assignedUser;
      this.assignUserName = this.currentReview.assignedUserName;
      this.followUpNotes = this.currentReview.followUpNotes;
      this.reviewModal = true;

      if(status) {
      this.reviewViewData = [];
        this.$store.dispatch("reviewReadCount", id).then((response) => {
          if(response.success){
            this.reviewViewData = response.data[0];
            this.reviewViewData = true;
            this.getReviewsList();
            this.newReviews();
          }
        });
      }
    },

    customerReviewModalOpen(id) {
      this.customerReviewModalEdit = false;
      this.reviewViewData = [];

      this.$store.dispatch("viewReview", id).then((response) => {
        this.reviewViewData = response.data[0];

        let countryData = this.countryCodelist.find(
          (element) => element.shortName == this.reviewViewData.countryName
        );
        this.reviewCode =
          countryData && countryData.shortName ? countryData.shortName : "";
        this.reviewCountryCode =
          countryData && countryData.name && countryData.code
            ? `${countryData.name} (+${countryData.code}) `
            : "";
        this.customerReviewModal = true;
      });
    },
    editReview(){
      this.customerReviewModalEdit = true;
    },
    async updateCustomerData(){
      let result = await this.$validator.validateAll();
      if (result) {

        let countryCode = this.countryCodelist.find(
          (element) => element.shortName == this.reviewCode
        );

        let data = {
          id: this.reviewViewData.id,
          reviewAuthor: this.reviewViewData.reviewAuthor,
          userID: this.userLoginId,
          email: this.reviewViewData.email,
          phone: this.reviewViewData.phone,
          code: countryCode && countryCode.code ? "+" + countryCode.code : "+1",
          countryName:
            countryCode && countryCode.shortName ? countryCode.shortName : "US",
        };

        this.$store
          .dispatch("reviewUpdate", data)
          .then((response) => {
            if (response.success) {
              this.customerReviewModalEdit = false;
              this.reviewCountryCode =
                countryCode && countryCode.name && countryCode.code
                  ? `${countryCode.name} (+${countryCode.code}) `
                  : "";

              this.$toastr.s(`${response.message}`);
              let reqParam = {
                page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
                userId: this.userLoginId,
                platformName: this.filterList.platform ? this.filterList.platform : "",
                reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
                assignedUser: this.filterList.user ? this.filterList.user : "",
                fromDate: this.filterList.startDate ? this.filterList.startDate : "",
                toDate: this.filterList.endDate ? this.filterList.endDate : "",
                module: this.module,
              };
              this.getReviewsList(reqParam);
            } else {
              if (response.data.email) {
                this.$toastr.e(response.data.email);
              }
              if (response.data.phone) {
                this.$toastr.e(response.data.phone);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        }
    },
    pushAllSelectedReviews(e) {
      if (e.target.checked) {
        this.selectedUsers = this.reviewsList.map((x) => x.id);
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = true;
        });
      } else {
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = false;
        });
        this.selectedUsers = [];
      }
    },

    pushSelectedReviews(e, id) {
      if (e.target.checked) {
        this.selectedUsers.push(id);
      } else {
        const index = this.selectedUsers.indexOf(id);
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        }
      }
    },

    deleteReview(id) {
      // this.deleteReviews(id).then((response) => {
      this.$store.dispatch("deleteReview", id).then((response) => {
        if (response.success) {
          let reqParam = {
            page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
            userId: this.userLoginId,
            platformName: this.filterList.platform
              ? this.filterList.platform
              : "",
            reviewRating: this.filterList.ranking
              ? this.filterList.ranking
              : "",
            assignedUser: this.filterList.user ? this.filterList.user : "",
            fromDate: this.filterList.startDate
              ? this.filterList.startDate
              : "",
            toDate: this.filterList.endDate ? this.filterList.endDate : "",
            module: this.module,
          };
          this.getReviewsList(reqParam);
          this.$toastr.s(`${response.message}`);
          this.newReviews();
        } else {
          this.$toastr.e(`${response.message}`);
        }
      });
    },

    // All Methods Related To Reviews Ends Here

    // All Methods Related To Admin Profile Starts Here
    editProfile() {
      this.adminProfileModal = true;
    },
    editBillingInfo() {
      this.adminBillingInfoModal = true;
    },
    updateProfile() {
      if (
        this.adminDetails.firstName === "" ||
        this.adminDetails.lastName === "" ||
        this.adminDetails.email === "" ||
        this.adminDetails.phone === "" ||
        this.adminDetails.billing_address === "" ||
        this.adminDetails.city === "" ||
        this.adminDetails.state === "" ||
        this.adminDetails.pincode === "" ||
        this.adminDetails.organisation_name === ""
      ) {
        this.$toastr.e("Please fill all the required fields");
      } else {
      this.apiLoading = true;
        // await this.updateProfiles(this.adminDetails)
        this.$store
          .dispatch("updateProfile", this.adminDetails)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.getUserProfileSettings();
              this.adminProfileModal = false;
            } else {
              this.$toastr.e(`${response.message}`);
            }
          }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      }
    },
    updateBillingInfo() {
      if (
        this.adminDetails.b_firstName === "" ||
        this.adminDetails.b_lastNAme === "" ||
        this.adminDetails.b_billingAddress === "" ||
        this.adminDetails.b_city === "" ||
        this.adminDetails.b_state === "" ||
        this.adminDetails.b_zip === "" ||
        this.adminDetails.b_cardNumber === "" ||
        this.adminDetails.b_expDate === "" ||
        this.adminDetails.b_cvv === ""
      ) {
        this.$toastr.e("Please fill all the required fields");
      } else {
          this.apiLoading = true;
        // await this.billingInfo(this.adminDetails)
        this.$store
          .dispatch("billingInfo", this.adminDetails)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.adminBillingInfoModal = false;
            } else {
              this.$toastr.e("Invalid Details.");
            }
          }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      }
    },
    connectFrontrunner() {
      let data = {
        id: this.userLoginId,
      };
      this.apiLoading = true;
      this.$store
        .dispatch("frontrunnerConnect", data)
        .then((response) => {
          if (response.status) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    // All Methods Related To Admin Profile Ends Here
    logout() {
      localStorage.removeItem("token");
      localStorage.clear();
      //this.$router.push("/reviewzy/");
      this.$router.push("/login");
    },
    adminLogin(){
      if(localStorage.getItem("adminToken") && localStorage.getItem("change")){
        this.apiLoading = true;
        let data = {
                id: localStorage.getItem("change"),
            };
            this.$store
                .dispatch("clientLogin", data)
                .then((response) => {
                  if (response.success) {
                    this.$router.push("/admin/clients");
                  }else{
                    this.$toastr.e(`${response.message}`);
                  }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => (this.apiLoading = false));
        return;  
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordIcon === "bi bi-eye-slash"
          ? "bi bi-eye"
          : "bi bi-eye-slash";
    },
    cSwitchVisibility() {
      this.cPasswordFieldType =
        this.cPasswordFieldType === "password" ? "text" : "password";
      this.cPasswordIcon =
        this.cPasswordIcon === "bi bi-eye-slash"
          ? "bi bi-eye"
          : "bi bi-eye-slash";
    },
    userModalOpen() {
      this.clearUserModal();
      this.userModal = true;
    },
    clearUserModal() {
      this.userForm.firstName = "";
      this.userForm.lastName = "";
      this.userForm.email = "";
      this.userForm.password = "";
      this.userForm.confirmPassword = "";
    },
    timezoneList() {
      this.$store.dispatch("timezoneList").then((response) => {
        this.timeZoneList = response.data;
      });
    },

    onChangeCommunicationFile(e) {
      // this.communication.communicationLogo = e.target.files[0];
      this.createFile(e.target.files[0]);
    },
    dragCommunicationFile(e) {
      // this.communication.communicationLogo = e.dataTransfer.files[0];
      this.createFile(e.dataTransfer.files[0]);
    },
    createFile(file) {
      this.communication.communicationLogo = file;
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.communicationImg = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeFile() {
      this.communicationImg = "";
      this.removeLogo();
    },
    saveCommunication() {
      let data = {
        communicationId: this.communication.id ? this.communication.id : "",
        user_id: this.userLoginId,
        timezone: this.communication.timezone,
        emailSendFromName: this.communication.emailSendFromName,
        textSendFromName: this.communication.textSendFromName,
        replyEmail: this.communication.replyEmail,
        // textNumberAreaCode: this.communication.textNumberAreaCode,
        reviewRequestTextDefaultMessage:
          this.communication.reviewRequestTextDefaultMessage,
        defaultThreeStarResponseEmail:
          this.communication.defaultThreeStarResponseEmail,
        defaultThreeStarResponseText:
          this.communication.defaultThreeStarResponseText.replace(/<\/?[^>]+>/ig, ""),
        reviewRequestEmailDefaultMessage:
          this.communication.reviewRequestEmailDefaultMessage,
        threeStarThankyouMessage: this.communication.threeStarThankyouMessage,
        FourFiveReviewMessage: this.communication.FourFiveReviewMessage,
        reviewRequestMainPageMessage:
          this.communication.reviewRequestMainPageMessage,
        reviewRequestThreeStarMessage:
          this.communication.reviewRequestThreeStarMessage,
        reviewRequestFourFiveStarMessage:
          this.communication.reviewRequestFourFiveStarMessage,
        logo: this.communication.communicationLogo
          ? this.communication.communicationLogo
          : null,
        thankyouMessage: this.communication.thankyouMessage.replace(/<\/?[^>]+>/ig, ""),
        thankyouEmail: this.communication.thankyouEmail,
      };
      this.apiLoading = true;
      if (data.communicationId) {
        this.$store
          .dispatch("updateCommunicationSetting", data)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
            } else {
              this.$toastr.e(`${response.message}`);
            }
          }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      } else {
        this.$store
          .dispatch("saveCommunicationSetting", data)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              this.fetchCommunication();
            } else {
              this.$toastr.e(`${response.message}`);
            }
          }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
      }
    },
    fetchCommunication() {
      let data = {
        id: this.userLoginId,
      };
      this.$store
        .dispatch("fetchCommunicationSetting", data)
        .then((response) => {
          let res = response.data[0];
          (this.communication.id = res.id),
            (this.communication.timezone = res.timezone);
          this.communication.emailSendFromName = res.emailSendFromName && res.emailSendFromName != null? res.emailSendFromName : "";
          this.communication.textSendFromName = res.textSendFromName && res.textSendFromName != null ? res.textSendFromName : "";
          this.communication.replyEmail = res.replyEmail ? res.replyEmail : "";
          // this.communication.textNumberAreaCode = res.textNumberAreaCode;
          this.communication.reviewRequestTextDefaultMessage =
            res.reviewRequestTextDefaultMessage ? res.reviewRequestTextDefaultMessage : "";
          this.communication.defaultThreeStarResponseEmail =
            res.defaultThreeStarResponseEmail ? res.defaultThreeStarResponseEmail : "";
          this.communication.defaultThreeStarResponseText =
            res.defaultThreeStarResponseText ? res.defaultThreeStarResponseText : "";
          this.communication.reviewRequestEmailDefaultMessage =
            res.reviewRequestEmailDefaultMessage ? res.reviewRequestEmailDefaultMessage : "";
          this.communication.threeStarThankyouMessage =
            res.threeStarThankyouMessage ? res.threeStarThankyouMessage : "";
          this.communication.FourFiveReviewMessage = res.FourFiveReviewMessage ? res.FourFiveReviewMessage : "";
          (this.communication.reviewRequestMainPageMessage =
            res.reviewRequestMainPageMessage ? res.reviewRequestMainPageMessage : ""),
            (this.communication.reviewRequestThreeStarMessage =
              res.reviewRequestThreeStarMessage ? res.reviewRequestThreeStarMessage : ""),
            (this.communication.reviewRequestFourFiveStarMessage =
              res.reviewRequestFourFiveStarMessage ? res.reviewRequestFourFiveStarMessage : ""),
            (this.communication.thankyouMessage = res.thankyouMessage ? res.thankyouMessage : "");
          this.communication.thankyouEmail = res.thankyouEmail ? res.thankyouEmail : "";
          // this.communication.communicationLogo = res.logo ? res.logo : "";
          this.communicationImg = res.logourl ? res.logourl : "";
        });
    },
    removeLogo() {
      let data = {
        userID: this.userLoginId,
      };
      this.$store
        .dispatch("removeCommunicationSettingLogo", data)
        .then((response) => {
          this.communication.communicationLogo = "";
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.fetchCommunication();
          } else {
            this.$toastr.e(`${response.message}`);
          }
        });
    },

    ChangeT(data) {
      let title = data.title;
      let singleData = data.data;
      let modal = data && data.modal ? data.modal : "";
      this.module = data.actionType;

      if (title == "platform") {
        !this.filterList.platform.includes(singleData)
          ? (this.filterList.platform = [
              ...this.filterList.platform,
              singleData,
            ])
          : "";
      }
      if (title == "By Ranking") {
        !this.filterList.ranking.includes(singleData)
          ? (this.filterList.ranking = [...this.filterList.ranking, singleData])
          : "";
      }

      if (title == "By User" && this.module == "reviews") {
        this.userListData = true;
      }

      if (title == "By User" && this.module == "feedback") {
        this.feedbackUserList = true;
      }
      if (singleData != null && modal == "usersFilter") {
        this.filterList.user = singleData.data;
        this.filterList.userName = singleData.userName;
        this.feedbackUserList = false;
        this.userListData = false;
      }

      if (title == "Select Data Range") {
        let startDate = moment(singleData.startDate).format("YYYY-MM-DD");
        let endDate = moment(singleData.endDate).format("YYYY-MM-DD");
        this.filterList.startDate = startDate;
        this.filterList.endDate = endDate;
      }

      if (title != "By User") {
        this.reviewCurrentPage = this.reviewsPageNumber 
        let reqParam = {
          page: this.reviewsPageNumber,
          platformName: this.filterList.platform
            ? this.filterList.platform
            : "",
          reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
          assignedUser: this.filterList.user ? this.filterList.user : "",
          fromDate: this.filterList.startDate ? this.filterList.startDate : "",
          toDate: this.filterList.endDate ? this.filterList.endDate : "",
          module: this.module,
        };
        this.callFilterApis(reqParam);
      }
    },

    updateListing(data) {
      let reqParam = {
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
      };
      if (data.actionType == "reviews") {
        reqParam.page = this.reviewCurrentPage ? this.reviewCurrentPage : 1;
        reqParam.userId = this.userLoginId;
        reqParam.module = data.actionType;
        this.getReviewsList(reqParam);
      }
      if (data.actionType == "users") {
        (reqParam.module = data.actionType), this.getUserList();
      }
      if (data.actionType == "feedback") {
        reqParam.page = this.feedbackCurrentPage ? this.feedbackCurrentPage : 1;
        (reqParam.userId =
          localStorage.getItem("isAdmin") == 0 ? this.userLoginId : ""),
          (reqParam.parentUserID =
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : ""),
          (reqParam.module = data.actionType),
          this.getFeedbackList(reqParam);
      }
      if (data.actionType == "request_reviews") {
      }
    },
    removeFilter(data) {
      let platform = this.filterList.platform.indexOf(data);
      if (platform > -1) {
        this.filterList.platform.splice(platform, 1);
      }
      let ranking = this.filterList.ranking.indexOf(data);
      if (ranking > -1) {
        this.filterList.ranking.splice(ranking, 1);
      }
      let user;
      data && data.id ? user = this.filterList.user.indexOf(data.id) : "";
      if (user > -1) {
        this.filterList.user.splice(user, 1);
      }

      let userName = this.filterList.userName.findIndex(function(userName, index) {
                  if(userName.id == data.id)
                    return true;
                });
      if (userName > -1) {
        this.filterList.userName.splice(userName, 1);
      }

      let rangeStart;
      let rangeEnd;
      if( (data == this.filterList.startDate) || (data == this.filterList.endDate)){
        let dateRangeStart = this.filterList.startDate.split(": ");
        let dateRangeEnd = this.filterList.endDate.split(": ");
        rangeStart = this.filterList.startDate.indexOf(dateRangeStart[0]);
        rangeEnd = this.filterList.endDate.indexOf(dateRangeEnd[0]);
      }      

      if (rangeStart > -1 || rangeEnd > -1) {
        this.filterList.startDate = "";
        this.filterList.endDate = "";
      }

      let reqParam = {
        page: 1,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.callFilterApis(reqParam);
    },
    callFilterApis(data) {
      if (data.module == "reviews") {
        this.reviewCurrentPage = 1;
        (data.userId = this.userLoginId), this.getReviewsList(data);
      }

      if (data.module == "feedback") {
        (data.userId =
          localStorage.getItem("isAdmin") == 0 ? this.userLoginId : ""),
          (data.parentUserID =
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : ""),
          this.getFeedbackList(data);
      }
    },
    clearFilterData() {
      this.filterList.platform = [];
      this.filterList.user = [];
      this.filterList.userName = [];
      this.filterList.ranking = [];
      this.filterList.startDate = "";
      this.filterList.endDate = "";
    },
    syncPlatform(data) {
      if (this.filterList.platform.length === 0) {
        this.googlePlatform ? this.syncGoogleBusiness() : "";
        this.facebookPlatform ? this.syncFacebookBusiness() : "";
        this.yelpPlatform ? this.syncYelpBusiness() : "";
        this.zillowPlatform ? this.synczillowBusiness() : "";
      } else {
        this.filterList.platform.includes("Google") && this.googlePlatform
          ? this.syncGoogleBusiness()
          : "";

          this.filterList.platform.includes("Facebook") && this.facebookPlatform
          ? this.syncFacebookBusiness()
          : "";

        this.filterList.platform.includes("Yelp") && this.yelpPlatform
          ? this.syncYelpBusiness()
          : "";

        this.filterList.platform.includes("Zillow") && this.zillowPlatform
          ? this.synczillowBusiness()
          : "";
      }
      let reqParam = {
        page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
        userId: this.userLoginId,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.getReviewsList(reqParam);
    },
    showReport(data){
      this.filterPlatform = data ? data : "";
      this.show = "reports";
    },
    dashboardApiGoogleData() {
      let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;
      let data = {
        platformName: "google",
        userID: userID,
        // userID: this.userLoginId,
        Number: 2,
      };

      this.$store.dispatch("dashboardApi", data).then((response) => {
        let totalReivew = response.TotalCount;
        let currentReview = response.yearly;
        let LastThreeMonths = response.LastThreeMonths;
        let current = response.CurrentMonth;

        this.googleAllReview = totalReivew.count ? totalReivew.count : 0;
        this.googleAllReviewRating = (
          totalReivew.rating / totalReivew.count
        ).toFixed(1);

        this.googleStarted = response.date;

        this.googleCurrentReview = current.monthReviews
          ? current.monthReviews
          : "0";
        this.googleCurrentReviewRating = current.monthAverageRating
          ? current.monthAverageRating
          : "0";

        this.googleAllTime = current.OverPercentageGrowth
          ? `${current.OverPercentageGrowth}%`
          : `0%`;
        this.googleThisMonth = current.thisMonthpPercentageGrowth
          ? `${current.thisMonthpPercentageGrowth}%`
          : `0%`;

        let categoriesData = LastThreeMonths.map((categories) => {
          return categories.Month;
        });
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: [...categoriesData],
            },
          },
        };
        this.series[0].data = LastThreeMonths.map((categories) => {
          return categories.count ? categories.count : 0;
        });

        this.googleRecentReview =
          response.reviews.length > 0 ? response.reviews : [];
          this.googleView  = response.url && response.url.googlePlaceID ? response.url.googlePlaceID : ""
      });
    },

    dashboardApiFBData() {
      let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;

      let data = {
        platformName: "facebook",
        userID: userID,
        Number: 2,
      };

      this.$store.dispatch("dashboardApi", data).then((response) => {
        let totalReivew = response.TotalCount;
        let currentReview = response.yearly;
        let LastThreeMonths = response.LastThreeMonths;
        let current = response.CurrentMonth;

        this.fbAllReview = totalReivew.count ? totalReivew.count : 0;
        this.fbAllReviewRating = totalReivew.rating
          ? (totalReivew.rating / totalReivew.count).toFixed(1)
          : 0;

        this.fbStarted = response.date;

        this.fbCurrentReview = currentReview.count;
        this.fbCurrentReviewRating = currentReview.rating
          ? (currentReview.rating / currentReview.count).toFixed(1)
          : 0;

        this.fbAllTime = current.OverPercentageGrowth
          ? `${current.OverPercentageGrowth}%`
          : `0%`;
        this.fbThisMonth = current.thisMonthpPercentageGrowth
          ? `${current.thisMonthpPercentageGrowth}%`
          : `0%`;

        let categoriesData = LastThreeMonths.map((categories) => {
          return categories.Month;
        });
        this.chartOptions_fb = {
          ...this.chartOptions_fb,
          ...{
            xaxis: {
              categories: [...categoriesData],
            },
          },
        };

        this.chartOptions_fb.xaxis.categories = LastThreeMonths.map(
          (categories) => {
            return categories.Month;
          }
        );
        this.series_fb[0].data = LastThreeMonths.map((categories) => {
          return categories.count ? categories.count : 0;
        });

        this.fbRecentReview =
          response.reviews.length > 0 ? response.reviews : [];
          this.fbView  = response.url && response.url.facebook ? response.url.facebook : ""

      });
    },

    dashboardApiYelpData() {
      let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;
      let data = {
        platformName: "yelp",
        userID: userID,
        Number: 2,
      };

      this.$store.dispatch("dashboardApi", data).then((response) => {
        let totalReivew = response.TotalCount;
        let currentReview = response.yearly;
        let LastThreeMonths = response.LastThreeMonths;
        let current = response.CurrentMonth;

        this.yelpAllReview = totalReivew.count ? totalReivew.count : 0;
        this.yelpAllReviewRating = (
          totalReivew.rating / totalReivew.count
        ).toFixed(1);

        this.yelpStarted = response.date;

        this.yelpCurrentReview = current.monthReviews
          ? current.monthReviews
          : "0";
        this.yelpCurrentReviewRating = current.monthAverageRating
          ? current.monthAverageRating
          : "0";

        this.yelpAllTime = current.OverPercentageGrowth
          ? `${current.OverPercentageGrowth}%`
          : `0%`;
        this.yelpThisMonth = current.thisMonthpPercentageGrowth
          ? `${current.thisMonthpPercentageGrowth}%`
          : `0%`;

        let categoriesData = LastThreeMonths.map((categories) => {
          return categories.Month;
        });
        this.chartOptions_yelp = {
          ...this.chartOptions_yelp,
          ...{
            xaxis: {
              categories: [...categoriesData],
            },
          },
        };

        this.series_yelp[0].data = LastThreeMonths.map((categories) => {
          return categories.count ? categories.count : 0;
        });

        this.yelpRecentReview =
          response.reviews.length > 0 ? response.reviews : [];
          this.yelpView  = response.url && response.url.yelpBusinessesId ? response.url.yelpBusinessesId : ""

      });
    },

    dashboardApizillowData() {
      let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;
      let data = {
        platformName: "zillow",
        userID: userID,
        Number: 2,
      };

      this.$store.dispatch("dashboardApi", data).then((response) => {
        let totalReivew = response.TotalCount;
        let currentReview = response.yearly;
        let LastThreeMonths = response.LastThreeMonths;
        let current = response.CurrentMonth;

        this.zillowAllReview = totalReivew.count ? totalReivew.count : 0;
        this.zillowAllReviewRating = (
          totalReivew.rating / totalReivew.count
        ).toFixed(1);

        this.zillowStarted = response.date;

        // this.zillowCurrentReview = currentReview.count;
        // this.zillowCurrentReviewRating = (
        //   currentReview.rating / currentReview.count
        // ).toFixed(1);

        this.zillowCurrentReview = current.monthReviews
          ? current.monthReviews
          : "0";
        this.zillowCurrentReviewRating = current.monthAverageRating
          ? current.monthAverageRating
          : "0";

        this.zillowAllTime = current.OverPercentageGrowth
          ? `${current.OverPercentageGrowth}%`
          : `0%`;
        this.zillowThisMonth = current.thisMonthpPercentageGrowth
          ? `${current.thisMonthpPercentageGrowth}%`
          : `0%`;

        let categoriesData = LastThreeMonths.map((categories) => {
          return categories.Month;
        });

        this.chartOptions_zillow = {
          ...this.chartOptions_zillow,
          ...{
            xaxis: {
              categories: [...categoriesData],
            },
          },
        };

        this.series_zillow[0].data = LastThreeMonths.map((categories) => {
          return categories.count ? categories.count : 0;
        });

        this.zillowRecentReview =
          response.reviews.length > 0 ? response.reviews : [];
          this.zillowView  = response.url && response.url.zillowReviewRequestURL ? response.url.zillowReviewRequestURL : ""

      });
    },
    closeM(data) {
      this.userListData = false;
      this.feedbackUserList = false;
    },
    newReviews() {
      let data = {
        userID: this.userLoginId,
      };
      this.$store.dispatch("reviewsNewCount", data).then((response) => {
        this.reviewStatus =
          response.data && response.data.reviewStatus
            ? response.data.reviewStatus
            : "";
      });
    },
    newFeedabck() {
      let data = {
        userID: this.userLoginId,
      };
      this.$store.dispatch("feedbackNewCount", data).then((response) => {
        this.feedbackStatus =
          response.data && response.data.feedbackStatus && response.data.feedbackStatus != 0
            ? response.data.feedbackStatus
            : "";
      });
    },
    myCustomPreviousStepCallback(currentStep){
      this.wizardClear();
      this.vSteps = false;
      if(currentStep == 2){
        this.show = 'settings'
        this.showProfile = 'basic';
        this.userEditIcon = true;
        this.settingMount();
      }
      if(currentStep == 3){
        this.googleConnectIcon = true;
      }
      if(currentStep == 4){
        this.facebookConnectIcon = true;
      }
      if(currentStep == 5){
        this.yelpConnectIcon = true;
      }
      else if(currentStep == 6){
        this.showProfile = 'platform';
        this.adminProfileModal = false;
        this.zillowConnectIcon = true;
      }
      else if(currentStep == 7){
        this.show = 'settings'
        this.showProfile = 'communication';
      }
      else if(currentStep == 8){
        this.show = 'users';
        this.userCreateIcon = true;
        this.getUserList();
      }
      else if(currentStep == 9){
        this.show = 'request_reviews';
      }
      else if(currentStep == 10){
        this.show = 'feedback';
        this.feebackMount();
      }
    },
    myCustomNextStepCallback(currentStep){
      this.wizardClear();
      this.vSteps = false;
      if(currentStep == 0){
        this.show = 'settings'
        this.showProfile = 'basic';
        this.userEditIcon = true;
        this.settingMount();
      }
      else if(currentStep == 1){
        this.showProfile = 'platform';
        this.googleConnectIcon = true;
        this.adminProfileModal = false;
      }
      if(currentStep == 2){
        this.facebookConnectIcon = true;
      }
      if(currentStep == 3){
        this.yelpConnectIcon = true;
      }
      if(currentStep == 4){
        this.zillowConnectIcon = true;
      }
      else if(currentStep == 5){
        this.showProfile = 'communication';
      }
      else if(currentStep == 6){
        this.show = 'users';
        this.getUserList();
        this.userCreateIcon = true;
      }
      else if(currentStep == 7){
        this.show = 'request_reviews';
      }
      else if(currentStep == 8){
        this.show = 'feedback';
        this.feebackMount();
      }
      else if(currentStep == 9){
        this.show = 'reviews';
        this.reviewsMount();
      }
    },
    myCustomStopCallback(){
      this.wizardClear();
      this.tour = false;
      localStorage.setItem("loginFirstTime",0)
    },
    userCustomPreviousStepCallback(currentStep){
      if(currentStep == 2){
        this.show = 'settings'
        this.showProfile = 'basic';
        this.userEditIcon = true;
        this.settingMount();
      }
      else if(currentStep == 3){
        this.show = 'request_reviews';
      }
      else if(currentStep == 4){
        this.show = 'feedback';
        this.feebackMount();
      }
    },
    userCustomNextStepCallback(currentStep){ 
      if(currentStep == 0){
        this.show = 'settings'
        this.showProfile = 'basic';
        this.userEditIcon = true;
        this.settingMount();
      }
      if(currentStep == 1){
        this.show = 'request_reviews';
      }
      else if(currentStep == 2){
        this.show = 'feedback';
        this.feebackMount();
      }
      else if(currentStep == 3){
        this.show = 'reviews';
        this.reviewsMount();
      }},
    userCustomStopCallback(){
      this.userTour = false;
      localStorage.setItem("loginFirstTime",0)
    },
    tutorialVideo(data){
      if(this.vStepsVideoList[data-1].tutorialLink){
        this.vSteps = true;
        this.vStepsVideo = `${this.vStepsVideoList[data-1].tutorialLink}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&output=embed`
      }
    },
    wizardData() {
      this.apiLoading = true;
      let data = {
        page: 1,
      };
      this.$store
        .dispatch("wizardList", data)
        .then((response) => {
            if (response.success) {
                this.vStepsVideoList = response.data.data;
            }
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => (this.apiLoading = false));
    },
    stopWizard(){
      this.vSteps = false;
      let data = {
        id: this.userLoginId
      };
      this.$store
        .dispatch("wizardFlag", data)
        .then((response) => {
          if (response.success) {
            localStorage.setItem("loginFirstTime",0)
            localStorage.setItem("wizard",0)
            this.tour = false;
            this.userTour = false;
            this.wizard = true;
            }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    wizardClear(){
      this.userEditIcon = false;
      this.googleConnectIcon =  false;
      this.facebookConnectIcon =  false;
      this.yelpConnectIcon =  false;
      this.zillowConnectIcon =  false;
      this.userCreateIcon = false;
    }
  },
  mounted() {
    this.wizard = localStorage.getItem("wizard") == 0 ? true : false;
    this.tour ? this.$tours['myTour'].start() : "";
    this.userTour ? this.$tours['userTour'].start() : "";
    let token = localStorage.getItem("token");
    this.superAdminToken = localStorage.getItem("adminToken") && localStorage.getItem("change") ? localStorage.getItem("adminToken") : "";
    if (token == "" || token == undefined) {
      this.$router.push("/login");
    }
    this.userLoginId = localStorage.getItem("id");
    // let isAdmin = localStorage.getItem("isAdmin");
    this.isAdmin = localStorage.getItem("isAdmin");
    if (this.isAdmin == true) {
      this.checkPermission = true;
    } else {
      this.checkPermission = false;
    }
    this.dashboardApiGoogleData();
    this.dashboardApiFBData();
    this.dashboardApiYelpData();
    this.dashboardApizillowData();
    this.newReviews();
    this.newFeedabck();
    this.timezoneList();
    this.getUserProfileSettings();
    this.checkConnectedPlatform();
    this.wizardData();
    console.clear();
    window.dispatchEvent(new Event("resize"));
  },
  created: function () {
    console.log("created main");
    window.fbAsyncInit = function () {
      FB.init({
        appId: "614634686913225",
        cookie: true,
        xfbml: true,
        version: "v14.0",
      });

      //This function should be here, inside window.fbAsyncInit
      FB.getLoginStatus(function (response) {
        console.log(response);
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },

  computed: {},
};
</script>
<style scoped>
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

.leftPanel {
  background: #27293b;
  position: static;
}

.sideBar {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  height: 100vh !important;
  margin-bottom: 0px;
  border-bottom-width: 0px !important;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
}

.modal-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.youtube-video {
  width: 600px;
  height: 300px;
}

.feedback-modal-container {
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 600px;
}

.business-modal-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  width: 500px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business-modal-history-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  width: 800px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business-input {
  height: 45px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  margin: 10px 0;
}

.yelp-form-control1 {
  width: 90%;
  display: inline-block !important;
}

.business-btn {
  margin-top: 4px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 45px;
  background: #00053c;
  border-radius: 15px;
  color: white
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.loginFormHeading {
  margin-bottom: 50px;
  top: 294px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 7px;
  color: #323b4b;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container .modal-container-youtube {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-item active {
  background: #00b0ac;
  background-color: #00b0ac;
}

.navbar-light .navbar-nav .nav-link {
  margin-left: 1vw;
  margin-top: 2vh;
  font-size: 14px;
  color: #9194b3;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active {
  color: #fdd640;
  font-size: 14px;
  background-color: #27293b;
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
  margin-left: 1vw;
  margin-top: 2vh;
}

.searchBox {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  color: #9194b3;
  padding: 0px 20px;
  height: 46px;
  background: #f4f5fa;
  border-radius: 15px;
  min-width: 350px;
  max-width: 350px;
  border: none;
  box-shadow: none;
}

.text-heading {
  font-weight: 500 !important;
  text-transform: capitalize;
  color: #00053c;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  align-items: center;
  white-space: initial;
}

.formtable {
  text-align: center;
  border-collapse: inherit;
}

.fontWeightBolder {
  font-weight: bolder;
}

.createButton {
  font-family: "Inter", sans-serif;
  background: #00053c;
  color: white;
  font-size: 18px;
  border-radius: 15px;
  width: 170px;
  height: 51px;
}

.heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00053c !important;
  font-weight: 600;
  align-items: center;
}

.loginInputsEmail {
  width: 100%;
  width: 100%;
  height: 45px;
  padding: 10px;
  background: #f4f5fa;
  /* BG 2 */

  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;

  color: #9194b3;
}

.loginInputsPassword {
  margin-top: 50px;
  width: 100%;
  padding: 20px;
  height: 58px;

  top: 555px;
  /* BG 4 */

  background: #ffffff;
  /* BG 2 */

  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.userForm {
  height: 50px;
  /* height: 58px; */
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* height: 50px; */
}

.callButton {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0px
}

.loginButton {
  margin-top: 20px;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
  transition: 0.2s ease;
}

.yellowButton {
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 25%;
  height: 50px;
  border-radius: 15px;
  padding: 10px;
  background: #fdd640;
  margin: 0px 12px 12px;
  transition: 0.2s ease;
}

.yellowButton:hover {
  background: #00053c;
}

.row {
  margin-right: 0px;
}

.reviewModalName {
  margin: 0px 20px;
  /* margin: 0px 7px; */
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  align-items: center;

  color: #1778f2 !important;
}

.searchBoxDiv {
  max-width: 281px;
  min-width: 281px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.table>tbody>tr>td {
  padding: 30px 15px;
}

/* Review Css  Start*/
.ratingSpan {
  color: white;
  /* padding: 10px 10px; */
  padding: 8px 10px 5px;
  /* background: #1778f2; */
  border-radius: 26px;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
}

.yelpStarColor {
  background: #bf2519;
}

.googleStarColor {
  background: #fdd640;
}

.fbStarColor {
  background: #39559a;
}

.zillowStarColor {
  background: #38cb89;
}

.fbRatingStar {
  color: white;
  padding: 10px 10px;
  padding: 8px 10px 5px;
  background: #1778f2;
  border-radius: 26px;
  background: #1778f2;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
  white-space: nowrap;
}

.ratingSpan>img {
  padding: 0 5px;
  width: 30px;
  margin-top: -4px;
}

/* vue2-editior style start */
.form-white>div {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: #f4f5fa;
  border-radius: 15px;
  font-weight: 500;
  font-size: 20px;
  color: #9194b3;
}

::v-deep .ql-toolbar.ql-snow {
  border: 0;
  background: rgba(145, 148, 179, 0.1);
  border-top: 1px solid rgba(145, 148, 179, 0.2);
}

::v-deep .ql-container.ql-snow {
  border: 0;
  height: 150px;
  overflow: scroll;
  color: black !important;
}

::v-deep .quillWrapper.ql-editor {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  /* flex-direction: column-reverse; */
}

::v-deep .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  color: #9194b3 !important;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg {
  width: 16px;
  height: 16px;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg line,
.ql-toolbar.ql-snow span.ql-formats svg path {
  stroke: #9194b3;
}

::v-deep .ql-snow .ql-picker {
  color: #9194b3;
}

/* vue2-editior style ends */
.loginName {
  margin-left: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  color: #00053c;
}

.loginPic {
  width: 47px;
  height: 47px;
}

.viewReviewInnerBox {
  background-color: white;
  margin-bottom: 20px;
}

.viewReviewInnerBoxImg {
  margin: 20px 20px;
}

.viewReviewInnerBoxPara {
  word-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9194b3;
}

.viewReviewInnerBoxAnchor {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #4285f4;
}

.viewReviewSecondPartTopText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.viewReviewSecondPartTopName {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #00053c;
}

.viewReviewSecondPartTextArea {
  margin: 15px 0px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  display: flex;
  align-items: center;

  color: #9194b3;

  width: 451px;
  height: 150px;
  left: 889px;
  top: 491px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.viewReviewSecondPartdropdown {
  margin: 15px 0px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.viewReviewSecondPartdropdownText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  /* font-size: 16px !important; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.filterText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  display: flex;
  color: #9194b3;
}

.profilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  position: absolute;
  margin: -80px 50px;
}

/* Review Css End */
.profileFormTitle {
  text-decoration-line: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9194b3 !important;
}

.profileFormTitleValue {
  text-decoration-line: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* font-size: 12px; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c !important;
}

.profileName {
  margin-left: 226px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.profileSettingSwitch {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  display: block;
  text-align: center;
  color: #9194b3;
  text-decoration-line: none;
  padding-bottom: 15px;
}

.profileSettingSwitch.active {
  color: #ffab00;
}

.profileSettingIcons {
  padding-right: 15px;
  margin-top: 5px;
  height: 33px;
}

.platFormCard {
  padding: 60px 0px 10px 0;
  border-radius: 15px !important;
}

.platFormCardGroup {
  column-gap: 20px;
  margin: 30px 20px;
}

.tourButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  background: #00053c;
  color: #ffffff;
  width: 125px;
  height: 40px;
  border-radius: 15px;
}

.platFormCardButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  background: #00053c;
  color: #ffffff;
  width: 180px;
  height: 51px;
  border-radius: 15px;
}

.platFormCardButtonColor {
  background: #38cb89;
}

.profileSettingHeading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin-top: 24px;
  margin-left: 24px;
}

.communicationFormLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* or 207% */

  display: flex;
  align-items: center;
  letter-spacing: 1px;

  color: #9194b3;
}

.communicationFormLabelColor {
  color: #000;
  font-size: 16px;
}

.choose-files {
  align-self: flex-end;
  z-index: 10;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button-wrapper.btn:hover {
  background-color: #4e5056;
}

.label {
  margin-bottom: 20px;
  color: #4e5056;
  font-size: 18px;
}

.button-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn {
  border: none;
  padding: 10px;
  /* padding: 10px 20px; */
  border-radius: 50px;
  background-color: #f4f5fa;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}

.drop-it-hot {
  margin: 34px 0px;
  border-radius: 15px;
  /* height: 485px; */
  height: 375px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px 0 rgba(black, 0.1);
  overflow: hidden;
  color: #f4f5fa;
  border: 1px dashed rgba(145, 148, 179, 0.5);
  box-sizing: border-box;
}

.communicationImg {
  margin: 34px 0px;
  border-radius: 15px;
  /* height: 485px; */
  height: 375px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px 0 rgba(black, 0.1);
  overflow: hidden;
  color: #f4f5fa;
}

.drop-it-hot:after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 2px dashed #e6e7f0;
  border-radius: 5px;
  z-index: 0;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  color: #f4f5fa;
  background-color: white;
  /* border: 3px solid black; */
  width: 100px;
  height: 100px;
}

.circle {
  transition: transform 150ms ease-in;
  z-index: 10;
}

svg {
  width: 40px;
  height: 40px;
}

.circle:before {
  content: "";
  background-color: #f4f5fa;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  transition: transform 250ms ease-in, opacity 200ms ease-in;
  z-index: 0;
}

.circle:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.circle:hover {
  transform: scale(1.2);
  opacity: 0.9;
}

.circle:before {
  transform: scale(8);
  opacity: 1;
}

.circle:after {
  border: 3px solid white;
}

svg {
  color: white;
  z-index: 1;
}

.loginInputsTextArea {
  width: 100%;
  height: 150px;

  background: #f4f5fa;
  border-radius: 15px;
  border-style: none;
}

.assignFollowUp {
  height: 50px;
  left: 889px;
  top: 413px;
  /* background: #ffffff; */
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
}

.respondButton {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
}

textarea {
  resize: none;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: 0 0 2px #ff8c00;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.form-control1 {
  display: inline-block !important;
}

.navOutDiv {
  position: fixed;
  width: 100%;
}

.dashScroll {
  margin-right: 0 !important;
  padding-right: 15px;
}

.reviewDropDown {
  width: 96%;
  border-radius: 6px;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(9px, 233px);
}

.outer-circle-new {
  background: rgba(137, 176, 240, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin: 0;
}

.outer-circle-pending {
  background: rgba(255, 171, 0, 0.12);
  border-radius: 15px;
  padding: 10px;
}

.outer-circle-resolved {
  background: #e5f7ef;
  border-radius: 15px;
  padding: 10px;
}

.inner-new {
  color: #89b0f0;
}

.inner-pending {
  color: #ffab00;
}

.inner-resolved {
  color: #38cb89;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-button {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 25%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
  padding: 10px;
  transition: 0.2s ease;
}

.view-button:hover {
  background: #00053c;
}

.filter-bg-color {
  background-color: #f4f5fa;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 0 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.assignFilter {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  justify-content: flex-end;
  margin: 0 0 10px;
}

.basic-info {
  margin: 20px;
  border-radius: 20px;
  background: rgb(244, 245, 250);
  padding: 20px;
}

.edit-text {
  width: 33px;
  height: 16px;
  left: 1302px;
  top: 703px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
  padding: 10px 10px 0px;
}

.basic-card {
  background: rgb(255, 255, 255);
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.communication-setting {
  padding: 36px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.role-table-pagination .pagination {
  justify-content: center;
}

.admin-new-pagination ul.pagination {
  background: none;
}

.admin-new-pagination ul li {
  flex-grow: initial !important;
  box-shadow: 0px 2px 6px #0000000a;
}

.admin-new-pagination {
  padding: 35px 0;
  background: #fff;
  overflow: auto;
}

.admin-new-pagination .pagination {
  /* justify-content: flex-end; */
  height: auto;
  box-shadow: none;
}

.admin-new-pagination .pagination li.active a.page-link {
  background: #623cea;
  border-radius: 0px;
  border-color: #623cea;
  color: #fff;
}

.admin-new-pagination .pagination li.active a.page-link:focus {
  box-shadow: none;
}

.admin-new-pagination .pagination a.page-link {
  color: #a4afb7;
  background: #fff;
}

.admin-new-pagination ul li span,
.admin-new-pagination ul li a,
.admin-new-pagination ul li button {
  padding: 10px 15px !important;
  font-size: 13px !important;
}

.admin-new-pagination .pagination a.page-link:focus {
  box-shadow: none;
}

.dark-theme .admin-new-pagination .pagination li.active a.page-link {
  background: #1a73e8;
  border-color: #1a73e8;
}

.spinner-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.isDisabled {
  pointer-events: none;
  color: red;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.logo-center {
  justify-content: center;
  margin-left: -15px !important;
}

.profile-dropdown {
  margin-left: 10px;
  /* margin-bottom: 10px; */
}

.table-align {
  margin-top: 15px;
  border-radius: 36px;
  margin-left: 10px;
}

table th:first-child {
  border-radius: 15px 0 0 15px;
}

table th:last-child {
  border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
  /* border: 0 !important; */
  border-top: none;
}

.header th {
  height: 10px;
  /* line-height: 2px; 
}
.user-modal {
 /*  margin-left: 600px !important;
  margin-right: 600px !important; */
  max-width: 600px;
  margin: 100px auto;
}

.user-modal-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #323b4b;
}

.profileSettingSwitch.active {
  border-bottom: 2px solid #ffab00;
  display: block;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 8px;
  padding-left: 6px;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
}

table th {
  background-color: #f4f5fa !important;
  height: 51px !important;
  padding: 15px !important;
  text-align: center !important;
}

.file-upload-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #9194b3;
}

.account-outline {
  outline: currentcolor none medium !important;
  border: none;
  box-shadow: none;
}

.initialletter {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: #41c7db;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 110px;
  margin: 20px 0;
}

/* style start */
.inner-white-wrap {
  border-radius: 36px;
  background: #fff;
  /* overflow: hidden; */
  height: 100%;
  padding: 15px;
}

.table-outer-wrap {
  padding: 30px;
  background: rgb(244, 245, 250);
  border-radius: 36px;
}

a.navbar-brand {
  padding: 30px 0 0 50px !important;
}

#sidebarCollapse {
  padding: 0;
  margin-top: 40px;
}

#sidebarCollapse li.nav-item a {
  position: relative;
}

#sidebarCollapse li.nav-item a::before {
  position: absolute;
  content: "";
  width: 46px;
  height: 46px;
  left: -36px;
  top: -6px;
  background: #fdd640;
  box-shadow: 0px 0px 17px 5px rgba(253, 214, 64, 0.12);
  border-radius: 15px;
  transform: rotate(45deg);
  opacity: 0;
  transition: 0.3s ease all;
}

#sidebarCollapse li.nav-item a {
  margin: 0;
  padding-left: 30px;
  margin-top: 10px;
}

#sidebarCollapse li.nav-item a.active::before {
  opacity: 1;
}

#sidebarCollapse li.nav-item a:hover::before {
  opacity: 1;
}

#sidebarCollapse li.nav-item a:hover {
  background: transparent;
}

.card-header h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px;
  color: #00053c;
}

.loginButton:hover {
  background: #fdd640 !important;
}

.loginButton:hover p.loginButtonText {
  color: #fff !important;
}

.action_btn-platform .loginButtonText {
  height: auto !important;
}

.table-outer-wrap .pagination button,
.table-outer-wrap .pagination span {
  margin: 0 3px;
}

table td h1 {
  font-size: 16px;
}

.modal-container-view {
  width: 800px;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

/* file upload style */
input#file-upload {
  padding-left: 76px;
  font-size: 13px;
}

input#file-upload::after {
  content: "Upload Profile Image";
  position: absolute;
  top: 7px;
  left: 20px;
  background: white;
  width: 150px;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  color: #68696a;
  border-radius: 10px 0 0 10px;
}

.rating-wrap {
  display: inline-block;
}

.star-wrapper {
  display: inline-flex;
  align-items: center;
  margin: 10px !important;
  padding: 0 !important;
}

.star-wrapper+p {
  display: flex;
  align-items: center;
}

button p {
  /* margin: 5px 0; */
}

.profile-img {
  display: inline-flex;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  object-fit: cover;
  align-items: center;
  justify-content: center;
}

.profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #f7f7f7;
  object-fit: cover;
}

.profile-inner {
  display: inline-flex !important;
  align-items: center;
  padding: 5px;
  background: #f7f7f7;
  border-radius: 30px;
  white-space: nowrap;
}

.profile-inner .loginName {
  margin-bottom: 0;
}

.new-color {
  text-decoration: none;
  color: #ff8c00 !important;
}

.modal-container.modal-sm {
  width: 100% !important;
  max-width: 500px;
}

.modal-container.modal-video {
  width: 100% !important;
  max-width: 600px;
}

@media (min-width: 1400px) {
  .modal-container {
    max-width: 1000px;
    width: 100% !important;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-height: calc(100vh - 0px);
    overflow-y: auto;
  }

  .modal-container-youtube {
    max-width: 1000px;
    width: 100% !important;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-height: calc(100vh - 0px);
    overflow-y: auto;
  }

  .formInput {
    padding-top: 20px;
  }
}

@media (max-width: 1599.98px) {
  .table>tbody>tr>td {
    padding: 10px;
  }

  table .btn {
    padding: 0 5px !important;
  }
}

@media (max-width: 1299.98px) {
  .modal-container {
    max-width: 85% !important;
  }

  .modal-container-youtube {
    max-width: 85% !important;

  }

  .text-width {
    width: auto !important;
  }

  .platFormCardGroup {
    margin: 0;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }

  .platFormCard {
    padding: 50px 0 5px;
    height: auto;
  }
}

@media (max-width: 1199.98px) {
  .platFormCardGroup {
    margin: 0;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }

  .platFormCard {
    padding: 30px 0 5px;
    height: auto;
  }

  .profileSettingHeading {
    margin: 0;
  }

  .com-set-wrap {
    margin: 0 !important;
  }

  .youtube-video {
    width: 300px;
    height: 150px;
    margin: 150px auto;
  }
}

@media (max-width: 991.98px) {

  .login-wrap .formInput {
    margin-top: 0 !important;
  }

  .fbStarWidth {
    width: 100%;
  }

  .viewReviewInnerBoxImg {
    display: block;
  }

  .text-width {
    margin: 10px !important;
  }

  .feedback-modal-container {
    top: 0;
    transform: translate(-50%, 50%);
  }

  /* responsive sidebar */
  .navbar-collapse.collapse {
    display: none !important;
    background: #27293b;
    width: 100%;
    height: auto;
    padding: 10px 20px !important;
    margin: 0;
  }

  .sideBar {
    height: auto !important;
  }

  a.navbar-brand {
    padding: 0 !important;
  }

  .profile-wrap {
    display: none !important;
  }

  #sidebarCollapse {
    margin-top: 0;
  }

  .btn-logo-wrap button.navbar-toggler {
    position: absolute;
    right: 30px;
    background: #fff !important;
  }

  .container-fluid.leftPanel {
    position: relative;
  }

  .btn-logo-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 30px;
  }

  .navbar-collapse.collapse.show {
    display: block !important;
  }

  .navOutDiv {
    position: unset;
  }

  #sidebarCollapse ul.navbar-nav {
    width: 100%;
  }

  .dashboard-view-card {
    width: 100%;
  }

  .relative-pos {
    width: 100%;
  }

  .dashboard-graph-card {
    width: 100%;
    height: 350px;
    height: auto;
  }

  .dashboard-review-card {
    width: 100%;
    height: 350px;
    height: 100%;
  }

  .modal-container-view {
    top: 0;
    transform: translate(-50%, 50%);
    width: 90%;
  }

  .youtube-video {
    width: 300px;
    height: 150px;
    margin: 150px auto;
  }
}

@media (max-width: 767.98px) {
  .youtube-video {
    width: 300px;
    height: 150px;
    margin: 150px auto;
  }

  .fbStarWidth {
    width: 100%;
  }

  .table-align>.card {
    margin: 12px !important;
  }

  .card.border-0.mb-7.inner-feedback-action {
    margin: 0 10px;
  }

  .card.border-0.feeback-action.col-9 {
    width: 100% !important;
  }

  .detail-wrap {
    margin-left: 0 !important;
  }

  .modal-container {
    /* margin-left: 20px;
    margin-right: 20px; */
  }

  .feedback-modal-container {
    width: 90%;
  }

  .navbar-collapse.collapse {
    padding: 10px 0px !important;
  }

  main .container {
    max-width: 100%;
  }

  table td h1 {
    font-size: 15px;
  }

  .card-header h2 {
    font-size: 26px;
  }

  .requestFrom {
    margin: 20px;
  }

  ul.navbar-nav {
    margin: 0 !important;
  }

  #sidebarCollapse li.nav-item a::before {
    left: -26px;
    width: 36px;
    height: 36px;
    top: 0;
  }

  .searchBox {
    min-width: auto;
    max-width: unset;
    width: 100%;
  }

  .table-responsive {
    border: 0;
  }

  .table-responsive .table th {
    border: 0;
  }

  .modal-wrapper a {
    cursor: pointer;
  }

  .table-outer-wrap {
    padding: 12px;
  }

  div#userForDiv {
    margin: 0;
    margin-top: 10px;
  }

  .profileName {
    margin-left: 0;
    margin-top: 0;
    font-size: 16px;
  }

  .tab-wrap {
    flex-wrap: wrap;
    width: 100% !important;
  }

  .tab-wrap>.col-12 {
    width: 50%;
  }

  .tabs-outer {
    margin: 0 !important;
  }

  .basic-info {
    margin: 10px 0 0 0;
  }

  .basic-card {
    margin: 0;
  }

  .profilePic {
    margin: -80px 30px;
  }

  .profile-text-wrap {
    padding: 0 !important;
    margin-top: 60px;
  }

  .com-set-wrap {
    margin: 0 !important;
  }

  .communication-setting {
    padding: 10px 5px 0px 20px;
  }

  .dashboard-view-card {
    width: 100%;
  }

  .relative-pos {
    width: 100%;
  }

  .dashboard-graph-card {
    width: 100%;
    height: 350px;
    height: auto;
  }

  .dashboard-review-card {
    width: 100%;
    height: 350px;
    height: 100%;
  }

  .business-modal-container {
    width: 90%;
  }

  .business-modal-history-container {
    width: 90%;
  }

  div#drop-area {
    margin-top: 0;
  }

  .connectButton {
    width: 50%;
    height: 75px;
  }

  .frontrunnerText {
    font-size: 12px;
    margin: 0 auto;
  }
}

.connectButton {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  width: auto;
  height: 40px;
  font-weight: 600;
}

.frontrunnerText {
  font-size: 14px;
  background: white;
  color: #00053c;
  margin: 0 auto;
}

.sync-svg {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 40px;
  top: 10px;
  cursor: pointer;
}

/* Dashboard CSS */
.dashboard-card {
  position: static;
  width: auto;
  height: auto;
  background: #f4f5fa;
  border-radius: 20px;
}

.relative-pos {
  position: relative;
}

.review-journey {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.mt-35 {
  margin-top: 35px;
}

.dashboard-view-card {
  width: 239px;
  height: 137px;

  background: #ffffff;
  border-radius: 15px;
  float: left;
  padding: 10px;
  margin: 10px;
}

.dashboard-per-card {
  height: 116px;
}

.dashboard-graph-card {
  width: 500px;
  height: auto;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px;
  float: left;
  margin: 10px;
}

.dashboard-review-count {
  color: #00053c;
}

.dashboard-review-text {
  /* width: 89.97px; */
  height: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
  margin: 15px;
}

.date-color {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
  margin-left: 10px;
}

.dashboard-review-star {
  width: 94px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin: 10px;
}

.dashboard-all-perc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #38cb89;
  margin: 10px;
}

.dashboard-month-perc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #4285f4;
  margin: 10px;
}

.dashboard-recent-reviews {
  width: 193px;
  height: 14px;
  left: 883px;
  top: 362px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin: 10px;
}

.customer-name {
  margin: 10px;
}

.review-star {
  position: absolute;
  right: 10px;
}

.dashboard-customer-review-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.review-time {
  position: absolute;
  top: 25px;
  right: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.dashboard-owner-response {
  position: absolute;
  left: 18%;
  right: 12%;
  top: 41.71%;
  bottom: 30.17%;
  background: #f4f5fa;
  border-radius: 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .fbStarWidth {
    width: 100%;
  }

  .dashboard-view-card {
    width: 100%;
  }

  .dashboard-graph-card {
    width: 100%;
    height: 350px;
    height: auto;
  }

  .dashboard-review-card {
    width: 100%;
    height: 350px;
    height: 100%;
  }

  .relative-pos {
    width: 100%;
  }

  .form-white.inner-white {
    margin-left: 0 !important;
  }

  .form-outline.form-white.inner-white {
    margin-left: 10px !important;
  }

  .inner-white {
    padding: 10px 0;
  }

  .action_btn-platform-wrap {
    justify-content: center;
  }

  .action_btn-platform {
    flex: 0 0 100%;
  }
}

.inner-head-wrap {
  display: flex;
  display: -ms-flexbox;
  align-items: baseline;
}

.inner-head-wrap .data-h1 {
  padding-right: 10px;
}

.data-h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.buttons-platform {
  width: 200px;
  margin: 0 auto;
  display: inline;
}

.action_btn-platform-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.action_btn-platform {
  /* width: 200px; */
  /* margin: 20px auto 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  height: 40px;
  border-radius: 10px;
  margin: 10px 0;
}

.action_btn_color__connected {
  background: #38cb89;
}

.action_btn_color__disconnected {
  background: #ff8c00;
}

.platform__connected {
  color: #38cb89;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
}

#editorid .ql-editor {
  width: 100%;
  height: 150px;
  background: #f4f5fa;
  border-radius: 15px;
}

.feeback-action {
  border-radius: 20px;
  background: #f4f5fa;
}

.dashboard-review-card {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
}

.view-more-btn {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 50%;
  /* height: 50px; */
  font-weight: 600;
  margin: 5px auto;
}

.business-modal-container img {
  word-break: break-all;
  word-wrap: break-word;
}

.f-start {
  align-self: flex-start;
}

.fbStarWidth {
  width: 100px;
  margin: 0;
}

.imgBtnCenter {
  margin: 0 auto;
}

:deep(.v-step) {
  background: #D7E9F7 !important;
  color: #000 !important;
  box-shadow: none !important;
  /* max-width: none !important; */
  width: 450px !important;
  max-width: 500px !important;
  border-radius: 15px !important;
  z-index: 1 !important;
}

:deep(.v-step__button) {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 29px !important;
  background: #00053c !important;
  color: #ffffff !important;
  width: 125px !important;
  height: 40px !important;
  border-radius: 15px !important;
}

.squareWizard {
  cursor: pointer;
  border: 4px solid red;
}

:deep(.vue-para) {
  font-size: 16px
}

.pac-container {
  z-index: 1050 !important;
}

.hiddenimg {
  display: none;
}

.hiddentxt {
  font-weight: bold;
  color: #F00;
  z-index: 99;
}

.hiddentxt a {
  color: #F00;
  text-decoration: none;
  z-index: 99;
}

.hiddenclick {
  font-weight: bold;
  color: #F00;
  text-decoration: none;
  cursor: pointer;
}

.hiddenclick a {
  color: #F00;
  text-decoration: none;
}

.hiddenclick a:visited {
  color: #F00;
  text-decoration: none;
}

.hiddentxt:hover~.hiddenimg {
  display: block;
  position: absolute;
  z-index: 2
}

.hiddenclickimg {
  display: none;

}

.formInput {
  margin-right: 10px;
  position: absolute;
  padding: 15px;
  min-width: 40px;
  text-align: center;
}

.connectMessage {
  height: 80vh;
  text-align: center;
  color: #9194b3;
  align-items: center;
  justify-content: center;
}
</style>
