<template>
  <div>
    <div v-if="apiLoading" class="loading">
      Loading&#8230;
    </div>
    <div class="login-wrap">
      <div class="row">
        <div class="col-12 col-lg-6 p-0">
          <div class="left-wrap">
            <div class="logo-wrap">
              <img src="resources/assets/site_logo.svg" alt="logo" />
            </div>
            <div class="form-wrapper">
              <form>
                <p class="loginFormHeading">Log In</p>

                <!-- Email input -->

                <div class="input-container">
                  <span style="align-items: center" class="d-flex">
                    <img class="formInput" src="resources/assets/login-username.svg" />
                  </span>
                  <input v-model="loginForm.userName" type="email" id="form3Example3"
                    class="loginInputsEmail inputOrange" placeholder="Email Address" />
                </div>

                <!-- </div> -->

                <!-- Password input -->

                <div class="input-container paswrd">
                  <span style="align-items: center" class="d-flex">
                    <img class="formInput" src="resources/assets/lock.svg" style="margin-top: 30px" />
                  </span>
                  <input v-model="loginForm.password" :type="passwordFieldType" id="form3Example4"
                    class="loginInputsPassword inputOrange" placeholder="Password" @keyup.enter="login"
                    @keydown.space.prevent />
                  <span @click="switchVisibility()" style="align-items: end" class="d-flex">
                    <i :class="passwordIcon" style="
                      font-size: 18px;
                      align-items: center;
                      margin-bottom: 15px;
                    "></i>
                  </span>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                  <!-- Checkbox -->
                  <div class="form-check mb-0"></div>
                  <a style="color: #00053c; cursor: pointer" class="forgotPasswordText" @click="forgotPassword">Forgot
                    password?</a>
                </div>

                <button type="button" class="loginButton" @click="login">
                  <p class="loginButtonText">Log In</p>
                </button>
                <p class="signUpHeading">
                  Don't have an account?&nbsp;
                  <a style="color: #00053c; cursor: pointer" @click="registerHere">Sign Up</a>
                </p>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-0" style="background: #27293b">
          <div class="dark-bg-wrap">
            <!-- src="resources/assets/Like_Startup_1login.png" -->
            <img src="resources/assets/Like_Startup 1login.svg" class="position-absolute" />
            <img style="margin-top: 34vh" src="resources/assets/Rectangle_568.png" class="position-absolute" />
          </div>
        </div>
      </div>
      <transition name="modal" v-if="forgotMail">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div>
                <form class="d-block">
                  <div class="d-flex justify-content-between align-items-center mb-5">
                    <h1 class=""></h1>

                    <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                  </div>
                  <div class="mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input v-model="email" type="email" id="form3Examplea2"
                        class="form-control userForm form-control-lg inputOrange" placeholder="Email"
                        v-validate="'required'" v-bind:name="'email'" v-bind:ref="'email'"
                        :class="{ 'is-invalid': errors.has('email') }" autocomplete="off" />
                    </div>
                  </div>

                  <button type="button" class="loginButton spinner-center" @click="sendMail()"
                    :disabled="apiLoading == 1">
                    <b-spinner v-if="apiLoading" class="spinner-center" variant="primary" label="Spinning"></b-spinner>
                    <p v-else class="loginButtonText">Send Mail</p>
                  </button>

                  <!-- <button type="button" class="loginButton" @click="sendMail()">
                  <p class="loginButtonText">Send Mail</p>
                </button> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- <router-view></router-view> -->
    </div>
  </div>
  <!-- <router-view></router-view> -->
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      loginForm: {
        userName: "",
        password: "",
      },
      passwordFieldType: "password",
      passwordIcon: "bi bi-eye-slash",
      forgotMail: false,
      email: "",
      apiLoading: false,
    };
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordIcon === "bi bi-eye-slash"
          ? "bi bi-eye"
          : "bi bi-eye-slash";
    },
    cancel() {
      this.forgotMail = false;
    },
    registerHere() {
      //  this.$router.push({name:'SignUp'});
      this.$router.push("/signup");
    },
    login() {
      if (this.loginForm.userName === "" || this.loginForm.password === "") {
        this.$toastr.e("Please fill the required fields");
      } else {
        this.apiLoading = true;

        this.$store.dispatch("login", this.loginForm).then((response) => {
          if (response.success) {
            this.$router.push("/Dashboard");
          } else {
            this.$toastr.e(`${response.data.message}`);
          }
        }).catch((err) => {
          console.log(err);
        })
          .finally(() => (this.apiLoading = false));
      }
    },
    forgotPassword() {
      this.forgotMail = true;
    },
    async sendMail() {
      let result = await this.$validator.validateAll();
      if (result && this.email) {
        this.apiLoading = true;
        let data = {
          email: this.email,
        };
        this.$store
          .dispatch("forgotPassword", data)
          .then((response) => {
            console.log("response", response);
            if (response.status) {
              localStorage.setItem("email", response.data);
              this.$toastr.s(`${response.message}`);
              this.forgotMail = false;
              this.$router.push("/password/reset");
            } else {
              this.$toastr.e(`${response.message}`);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.apiLoading = false));
      }
    },
  },
  mounted() {
    console.log("Reachdd on Mounted");
    let token = localStorage.getItem("token");
    if (token != undefined && token != null) {
      this.$router.push("/Dashboard");
      // this.$router.push("/reviewzy/Dashboard");
    } else {
      // vm.$forceUpdate();
      //or in file components
      this.$forceUpdate();
    }
    this.passwordFieldType = "password";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css");

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #27293b;
  height: 100vh;
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

.inputFields {
  margin-bottom: 50px;
}

.signUp {
  text-align: center;
}

.Rectangle_521 {
  position: absolute;
  width: 720px;
  height: 1024px;
  left: 720px;
  top: 0px;

  background: #27293b;
}

.Like_Startup_1login {
  position: absolute;
  width: 658px;
  height: 468px;
  left: 751px;
  top: 278px;
}

.Rectangle_568 {
  position: absolute;
  left: 751px;
  top: 534px;

  background: rgba(145, 148, 179, 0.1);
}

.loginFormHeading {
  margin-bottom: 50px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  color: #00053c;
}

.loginInputsEmail {
  width: 100%;
  height: 58px;
  top: 467px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  padding-left: 50px;
  outline: none;
  font-size: 16px;
  color: #9194b3;
  font-weight: 600;
}

::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;

  color: #9194b3;
}

.loginInputsPassword {
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  height: 58px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  padding-left: 50px;
  outline: none;
  font-size: 16px;
  color: #9194b3;
  font-weight: 600;
}

.forgotPasswordText {
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  text-align: right;

  color: #00053c;
}

.loginButton {
  margin-top: 20px;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
  transition: 0.2s ease;
}

.signUpHeading {
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #9194b3;
  text-align: center;
}

.firstPart {
  position: absolute;
  width: 720px;
  height: 1024px;
  left: 720px;
  top: 0px;
  background: #27293b;
}

.loginButtonText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  /* box-shadow: 0 0 2px #719ECE; */
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
  /* display: table-cell; */
  /* vertical-align: middle; */
}

.userForm {
  height: 58px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.formInput {
  margin-right: 10px;
  position: absolute;
  padding: 15px;
  min-width: 40px;
  text-align: center;
}

.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.spinner-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

/* custom-style start */
.dark-bg-wrap {
  background: #27293b;
  width: 100%;
  height: 100vh;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.login-wrap {
  height: 100vh;
  padding: 0 8px;
}

.login-wrap .left-wrap {
  padding: 40px;
  height: 100%;
}

.form-wrapper {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  height: calc(100% - 60px);
  padding: 0 10px;
}

.form-wrapper form {
  width: 100%;
}

.logo-wrap img {
  width: 200px;
}

.dark-bg-wrap img {
  max-width: 100%;
}

/* dialog style start */
.modal-wrapper {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  padding: 20px 30px 30px;
}

.modal-mask form {
  margin: 0px !important;
}

/* responsive start */
@media (max-width: 1600px) {
  .loginFormHeading {
    font-size: 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 1400px) {
  .login-wrap>.row {
    align-items: unset !important;
  }

  .loginFormHeading {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .form-wrapper .inputOrange {
    margin-top: 10px;
    height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .forgotPasswordText {
    margin-top: 0;
  }

  .formInput {
    padding-top: 20px;
  }

  form i {
    padding-top: 18px;
  }

  .input-container.paswrd img.formInput {
    margin-top: 0px !important;
  }

  .forgotPasswordText {
    font-size: 14px;
  }

  .signUpHeading {
    font-size: 14px;
  }

  button.loginButton {
    height: auto;
    padding: 10px;
    font-size: 14px;
  }

  .logo-wrap img {
    width: 150px;
  }
}

@media (max-width: 991.98px) {
  .login-wrap .signUpHeading {
    margin-left: 0;
    font-size: 14px;
  }

  .login-wrap .loginFormHeading {
    font-size: 35px;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .login-wrap .loginInputsPassword {
    margin-top: 10px;
  }

  .login-wrap .formInput {
    margin-top: 0 !important;
  }
}

@media (max-width: 767.98px) {
  .login-wrap .loginFormHeading {
    font-size: 24px;
    margin-bottom: 0;
  }

  .login-wrap .forgotPasswordText {
    font-size: 14px;
  }

  .login-wrap .left-wrap {
    padding: 30px 15px;
  }

  .dark-bg-wrap {
    height: 600px;
    padding-bottom: 40px;
  }

  .dark-bg-wrap img:nth-child(2) {
    margin-left: 0 !important;
    margin-top: 22vh !important;
  }

  .modal-wrapper {
    width: 90%;
  }
}

</style>

