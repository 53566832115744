<template>
  <main class="col-md-12 d-flex justify-content-center" style="height: 100vh">
    <form class="d-block m-2" style="margin: 20px">
      <div class="d-flex justify-content-between align-items-center mb-5"></div>

      <div class="logo-text-wrap">
        <div style="text-align: center">
          <img alt="Company Logo" width="600px" height="200px" :src="feedbackData.logo" />
        </div>

        <div v-if="!reviewSubmitted">
          <div class="form-outline form-white">
            <p class="userForm" style="text-align: center">
              <span v-html="reviewRequestMainPageMessage"></span>
            </p>
          </div>

          <div class="mb-4 pb-2 pt-4">
            <div class="form-outline form-white">
              <p class="userForm" style="text-align: center">
                Click 1-5 with 5 being the best.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <star-rating :show-rating="false" :rounded-corners="true" :star-points="[
              23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
              19, 31, 17,
            ]" v-model="rating" @rating-selected="openFeedbackModal()" class="starDes"></star-rating>
          </div>
        </div>
        <div v-else>
          <div class="form-outline form-white">
            <p class="userForm" style="text-align: center">
              <span> Thank You For Your Response</span>
            </p>
          </div>
        </div>
      </div>
      <div id="footer">
        <p class="poweredBy">Powered By:</p>
        <img class="logo-site" width="75px" height="100px" src="/resources/assets/site_logo.svg" />
      </div>
    </form>
    <transition name="modal" v-if="positiveModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div>
              <form class="d-block">
                <div class="d-flex justify-content-between mb-5">
                  <h1 class=""></h1>
                  <div class="align-items-center">
                    <img alt="Company Logo" width="300px" height="100px" :src="feedbackData.logo" />
                  </div>
                  <a class="close-btn" @click="cancel()"><img src="/resources/assets/crossicon.png" /></a>
                </div>
                <div class="
                    d-flex
                    justify-content-between
                    align-items-center
                    mb-4
                    pb-2
                  " style="text-align: center">
                  <div class="form-outline form-white">
                    <p class="modal-text" style="text-align: center">
                      <span v-html="reviewRequestFourFiveStarMessage"></span>
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="column" v-if="redirectList.googlePlaceID">
                    <div class="platform-card d-flex">
                      <a :href="`${redirectList.googlePlaceID
                        ? redirectList.googlePlaceID
                        : ''
                      }`">
                        <img class="platform-logo" src="/resources/assets/demo-google.svg" alt="Google" />
                      </a>
                    </div>
                  </div>
                  <div class="column" v-if="redirectList.yelpBusinessesId">
                    <div class="platform-card d-flex">
                      <a :href="`${redirectList.yelpBusinessesId
                        ? redirectList.yelpBusinessesId
                        : ''
                      }`">
                        <div class="platform-card d-flex">
                          <img class="platform-logo" src="/resources/assets/demo-yelp.svg" alt="Yelp" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="column" v-if="redirectList.facebook">
                    <a :href="`${redirectList.facebook[0] ? redirectList.facebook[0] : ''
                    }`">
                      <div class="platform-card d-flex">
                        <img class="platform-logo" src="/resources/assets/demo-fb.svg" alt="Facecbook" />
                      </div>
                    </a>
                  </div>
                  <div class="column" v-if="redirectList.zillowReviewRequestURL">
                    <a :href="`${redirectList.zillowReviewRequestURL
                      ? redirectList.zillowReviewRequestURL
                      : ''
                    }`">
                      <div class="platform-card d-flex">
                        <img class="platform-logo" src="/resources/assets/demo-zillow.svg" alt="Zillow" />
                      </div>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" v-if="negativeModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div>
              <form class="d-block">
                <div class="d-flex justify-content-between mb-5">
                  <h1 class=""></h1>
                  <div class="align-items-center">
                    <img alt="Company Logo" width="300px" height="100px" :src="feedbackData.logo" />
                  </div>
                  <a class="close-btn" @click="cancel()"><img src="/resources/assets/crossicon.png" /></a>
                </div>
                <div class="
                    d-flex
                    justify-content-between
                    align-items-center
                    mb-4
                    pb-2
                  " style="text-align: center">
                  <div class="form-outline form-white">
                    <p class="modal-text" style="text-align: center">
                      <span v-html="reviewRequestThreeStarMessage"></span>
                    </p>
                  </div>
                </div>
                <div class="d-flex">
                  <textarea placeholder="Write your experience..." class="viewReviewSecondPartTextArea"
                    style="width: 100%; padding: 15px" v-model="feedbackTest" v-validate="'required'"
                    v-bind:name="'feedbackTest'" v-bind:ref="'feedbackTest'"
                    :class="{ 'is-invalid': errors.has('feedbackTest') }" autocomplete="off"></textarea>
                </div>

                <button type="button" class="loginButton" style="height: 45px" @click="threeStarFeedback()">
                  <p style="height: 40px" class="loginButtonText">Submit</p>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  name: "MailFeedback",
  components: {
    StarRating,
  },
  data() {
    return {
      id: "",
      feedbackData: {
        logo: "",
      },
      positiveModal: false,
      negativeModal: false,
      feedbackTest: "",
      feedbackRating: "",
      rating: 0,
      redirectList: [],
      reviewRequestMainPageMessage: "",
      reviewRequestThreeStarMessage: "",
      reviewRequestFourFiveStarMessage: "",
      reviewSubmitted: false,
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getFeedback(this.id);
    this.platformRedirect(this.id);
    this.sendMailClick(this.id);
  },
  methods: {
    getFeedback(id) {
      let data = {
        key: id,
      };
      this.$store.dispatch("mailFeebackData", data).then((response) => {
        let res = response.data;

        localStorage.setItem("logo", res.logo);
        this.feedbackData.logo = localStorage.getItem("logo", res.logo);
        this.reviewSubmitted = res.ReviewSubmitted;
        this.reviewRequestMainPageMessage = res.reviewRequestMainPageMessage;
        this.reviewRequestThreeStarMessage = res.reviewRequestThreeStarMessage;
        this.reviewRequestFourFiveStarMessage =
          res.reviewRequestFourFiveStarMessage;
      });
    },
    sendMailClick(id) {
      let data = {
        key: id,
      };
      this.$store.dispatch("mailClick", data).then((response) => {
        console.log("response", response);
      });
    },

    openPositiveModal(rating) {
      this.positiveModal = true;
      this.feedbackRating = rating;
    },
    openNegativeModal(rating) {
      this.negativeModal = true;
      this.feedbackRating = rating;
    },
    openFeedbackModal() {
      if (this.rating == 4 || this.rating == 5) {
        this.positiveModal = true;
        this.feedbackRating = this.rating;
      } else {
        this.negativeModal = true;
        this.feedbackRating = this.rating;
      }
    },
    async threeStarFeedback() {
      let result = await this.$validator.validateAll();
      if (result) {
        let data = {
          feedback: this.feedbackTest,
          reviewRating: this.feedbackRating,
          key: this.id,
        };

        this.$store.dispatch("mailFeebackAdd", data).then((response) => {
          if (response.status) {
            this.negativeModal = false;
            this.$toastr.s(`${response.message}`);
            this.getFeedback(this.id);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        });
      }
    },
    cancel() {
      this.positiveModal = false;
      this.negativeModal = false;
    },
    platformRedirect(id) {
      let data = {
        key: id,
      };
      this.$store.dispatch("redirectUrl", data).then((response) => {
        this.redirectList = response.data;
      });
    },
  },
};
</script>
<style scoped>
.userForm {
  width: 870px;
  height: 44px;
  left: 285px;
  top: 340px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #9194b3;
}

p {
  word-wrap: break-word;
}

.starDes {
  height: 80px;
  left: 41.11%;
  right: 53.33%;
  top: calc(50% - 80px / 2 + 14px);
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
}

.modal-container {
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-text {
  width: 530px;
  height: 96px;
  left: 455px;
  top: 380px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  color: #9197b3;
}

.platform-card {
  width: 250px;
  height: 100px;
  left: 455px;
  top: 516px;

  background: #ffffff;
  border: 1px solid #f4f5fa;
  box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.13);
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.platform-logo {
  width: 152px;
  height: 48.21px;
  left: 504px;
  top: 541.89px;
}

.viewReviewSecondPartTextArea {
  width: 530px;
  height: 150px;
  left: 455px;
  top: 492px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 15px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  display: flex;
  align-items: center;

  color: #9194b3;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  /*remove the resize handle on the bottom right*/
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.loginButton {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

#footer {
  width: 100%;
  text-align: center;
  bottom: 0;
  left: 50%;
  padding-bottom: 10px;
  display: block;
  padding-top: 20%;
}

.vue-star-rating span.vue-star-rating-star {
  padding: 0 8px;
}

.poweredBy {
  height: 17px;
  left: 650.01px;
  top: 858px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #9194b3;
}

.logo-site {
  width: 141.32px;
  height: 41px;
  left: 649.34px;
  top: 883px;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* responsive start */
@media (max-width: 1600px) {
  .logo-text-wrap img {
    width: auto;
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
  }
}

@media (max-width: 1400px) {
  .userForm {
    height: auto;
  }

  .logo-text-wrap img {
    max-width: 300px;
  }

  .logo-text-wrap p {
    padding: 0 40px;
  }

  .vue-star-rating svg {
    width: 40px;
  }

  .modal-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}

@media (max-width: 991.98px) {
  .userForm {
    width: 100%;
  }

  #footer {
    position: fixed;
    left: 0;
    bottom: 20px;
    padding: 0;
  }

  .modal-container {
    max-width: 85%;
  }
}

@media (max-width: 767.98px) {
  .userForm {
    padding: 0 10px;
    font-size: 14px;
  }

  .starDes {
    height: auto;
  }

  .vue-star-rating svg {
    width: 20px;
    height: auto;
  }

  .vue-star-rating span.vue-star-rating-star {
    padding: 0 3px;
  }

  .logo-text-wrap img {
    max-width: 150px;
    margin-bottom: 10px;
  }

  .modal-container {
    width: 90%;
  }

  .modal-text {
    width: auto;
  }

  .column {
    width: 100%;
  }
}
</style>