<template>
    <!-- Vertical Navbar -->
    <nav class="
        navbar
        show
        navbar-vertical
        h-lg-screen
        navbar-expand-lg
        px-0
        py-0
        navbar-light
        bg-white
        border-bottom border-bottom-lg-0 border-end-lg
        sideBar
      " id="navbarVertical">
        <div class="container-fluid leftPanel">
            <!-- Toggler -->
            <button class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse"
                data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Brand -->
            <a class="navbar-brand logo-center" role="button" @click="showDiv('dashboard')">
                <img src="/resources/assets/logo-sidebar.svg" alt="..." />
            </a>
            <!-- User menu (mobile) -->
            <div class="navbar-user d-lg-none">
                <!-- Dropdown -->
                <div class="dropdown">
                    <!-- Toggle -->
                    <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                    </a>
                    <!-- Menu -->
                </div>
            </div>
            <!-- Collapse -->
            <div class="collapse navbar-collapse" id="sidebarCollapse">
                <!-- Navigation -->
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a :class="{ active: show == 'admindashboard' }" class="nav-link" @click="showDiv('admindashboard')"
                            href="#">
                            <img :src="show == 'admindashboard' ? '/resources/assets/activedashboard.svg' : '/resources/assets/dashboard.svg'"
                                alt />&nbsp;Dashboard
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: show == 'homepage' }" class="nav-link" @click="showDiv('homepage')"
                            href="#">
                            <img width="24" height="24"
                                :src="show == 'homepage' ? '/resources/assets/active-homepage.svg' : '/resources/assets/homepage.svg'"
                                alt />
                            &nbsp;Homepage
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: show == 'clients' }" class="nav-link" @click="showDiv('clients')" href="#">
                            <img :src="show == 'clients' ? '/resources/assets/active-user.svg' : '/resources/assets/users.svg'"
                                alt />
                            &nbsp;Clients
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: show == 'faq' }" class="nav-link" @click="showDiv('faq')" href="#">
                            <img width="24" height="24" :src="show == 'faq' ? '/resources/assets/active-faq.svg' : '/resources/assets/faq.svg'"
                                alt />
                            &nbsp;FAQ
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: show == 'support' }" class="nav-link" @click="showDiv('support')" href="#">
                            <img width="24" height="24" :src="show == 'support' ? '/resources/assets/active-support.svg' : '/resources/assets/support.svg'"
                                alt />
                            &nbsp;Support
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: show == 'wizardsetting' }" class="nav-link" @click="showDiv('wizardsetting')" href="#">
                            <img width="24" height="24" :src="show == 'wizardsetting' ? '/resources/assets/active-setup.svg' : '/resources/assets/setup.svg'"
                                alt />
                            &nbsp;Wizard Links
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: show == 'changepassword' }" class="nav-link" @click="showDiv('changepassword')"
                            href="#">
                            <img :src="show == 'changepassword' ? '/resources/assets/active-lock.svg' : '/resources/assets/lock.svg'"
                                alt />
                            &nbsp;Change Password
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#" @click="logout">
                            <i class="bi bi-box-arrow-left"></i>&nbsp;Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- </div> -->
</template>
  
<script>
export default {
    name: "Sidebar",
    data() {
        return {
            show: this.$route.name.toLowerCase(),
        };
    },

    mounted() {
        let adminToken = localStorage.getItem('adminToken')
        if (!adminToken && adminToken == undefined) {
            this.$router.push("/admin/login");
        }
        console.clear();
    },
    created() { },
    computed: {},
    methods: {
        showDiv(data) {
            console.log(data);
            if (data === "admindashboard") {
                this.$router.push("/admin/dashboard");
            }
            if (data === "homepage") {
                this.$router.push("/admin/homepage");
            }
            if (data === "clients") {
                this.$router.push("/admin/clients");
            }
            if (data === "faq") {
                this.$router.push("/admin/faq");
            }
            if (data === "support") {
                this.$router.push("/admin/support");
            }
            if (data === "wizardsetting") {
                this.$router.push("/admin/wizard");
            }
            if (data === "changepassword") {
                this.$router.push("/admin/change-password");
            }

            this.show = data;
        },
        logout() {
            localStorage.removeItem("adminToken");
            localStorage.clear();
            this.$router.push("/admin/login");
        },
    },
};
</script>

<style scoped>
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

.leftPanel {
    background: #27293b;
    position: static;
}

.sideBar {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    height: 100vh !important;
    margin-bottom: 0px;
    border-bottom-width: 0px !important;
}

/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
*/


.nav-item active {
    background: #00b0ac;
    background-color: #00b0ac;
}

.navbar-light .navbar-nav .nav-link {
    margin-left: 1vw;
    margin-top: 2vh;
    font-size: 14px;
    color: #9194b3;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active {
    color: #fdd640;
    font-size: 14px;
    background-color: #27293b;
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
    margin-left: 1vw;
    margin-top: 2vh;
}

.logo-center {
    justify-content: center;
    margin-left: -15px !important;
}

a.navbar-brand {
    padding: 30px 0 0 50px !important;
}

#sidebarCollapse {
    padding: 0;
    margin-top: 40px;
}

#sidebarCollapse li.nav-item a {
    position: relative;
}

#sidebarCollapse li.nav-item a::before {
    position: absolute;
    content: "";
    width: 46px;
    height: 46px;
    left: -36px;
    top: -6px;
    background: #fdd640;
    box-shadow: 0px 0px 17px 5px rgba(253, 214, 64, 0.12);
    border-radius: 15px;
    transform: rotate(45deg);
    opacity: 0;
    transition: 0.3s ease all;
}

#sidebarCollapse li.nav-item a {
    margin: 0;
    padding-left: 30px;
    margin-top: 10px;
}

#sidebarCollapse li.nav-item a.active::before {
    opacity: 1;
}

#sidebarCollapse li.nav-item a:hover::before {
    opacity: 1;
}

#sidebarCollapse li.nav-item a:hover {
    background: transparent;
}

@media (max-width: 991.98px) {

    /* responsive sidebar */
    .navbar-collapse.collapse {
        display: none !important;
        background: #27293b;
        width: 100%;
        height: auto;
        padding: 10px 20px !important;
        margin: 0;
    }

    .sideBar {
        height: auto !important;
    }

    a.navbar-brand {
        padding: 0 !important;
    }

    #sidebarCollapse {
        margin-top: 0;
    }

    button.navbar-toggler {
        position: absolute;
        right: 30px;
        background: #fff !important;
    }

    .container-fluid.leftPanel {
        position: relative;
    }

    .navbar-collapse.collapse.show {
        display: block !important;
    }

    #sidebarCollapse ul.navbar-nav {
        width: 100%;
    }
}

@media (max-width: 767.98px) {
    .navbar-collapse.collapse {
        padding: 10px 0px !important;
    }

    ul.navbar-nav {
        margin: 0 !important;
    }

    #sidebarCollapse li.nav-item a::before {
        left: -26px;
        width: 36px;
        height: 36px;
        top: 0;
    }
}
</style>
    