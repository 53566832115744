<template>
  <div>
    <div>
      <transition name="modal" v-if="showVideo">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container" style="background: none;box-shadow:none">
              <form class="d-block">
                <div class="d-flex mb-2" style="justify-content: end;">
                  <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                </div>
                <div class="table-responsive">
                  <div class="plyr__video-embed" id="player">
                    <iframe class="youtube-video" title="YouTube video" :src="video_url" allowfullscreen
                      allowtransparency allow="autoplay"></iframe>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <Header :moreReviews="moreReviews"></Header>
    <section class="section-center bg-left" style="background-image: url('resources/assets/ellipse_yellow.svg')"
      id="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img :src="positive.image_url" alt="" @click="playVideo('positive')" />
          </div>
          <div class="col-lg-6">
            <h1 class="section-bd-heading">
              <span v-html="positive.heading"></span>
            </h1>
            <p class="yellow-ecllipse-paragraph">
              <span v-html="positive.description"></span>
            </p>
            <a href="#signup"> <button type="button" class="btn btn-outline">Get Started</button></a>
          </div>
        </div>
      </div>
    </section>
    <section class="section-center bg-right" style="background-image: url('resources/assets/ellipse_pink.svg')">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h1 class="section-bd-heading">
              <span v-html="negative.heading"></span>
            </h1>
            <p class="pink-ecllipse-paragraph">
              <span v-html="negative.description"></span>
            </p>
            <a href="#signup"><button type="button" class="btn btn-outline">Sign Up</button></a>
          </div>
          <div class="col-lg-6">
            <img :src="negative.image_url" alt="" @click="playVideo('negative')" />
          </div>
        </div>
      </div>
    </section>

    <section class="section-center bg-left" style="background-image: url('resources/assets/ellipse_green.svg')">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img :src="feedback.image_url" alt="" @click="playVideo('feedback')" />
          </div>
          <div class="col-lg-6">
            <h1 class="section-bd-heading">
              <span v-html="feedback.heading"></span>
            </h1>
            <p class="green-ecllipse-paragraph">
              <span v-html="feedback.description"></span>
            </p>
            <a href="#signup"><button type="button" class="btn btn-outline">Get Started</button></a>
          </div>
        </div>
      </div>
    </section>
    <section class="" v-if="faqList.length > 0" id="faq">
      <h1 class="accordion-header">Frequently Asked Questions (FAQ)</h1>
      <div class="accordion-wrapper">

        <div class="accordion" v-for="faq in faqList" :key="faq.id">
          <input type="radio" name="radio-a" :id="faq.id" class="accordion-input">
          <label class="accordion-label" :for="faq.id">{{faq.title}}</label>
          <div class="accordion-content">
            <p>{{faq.description}} &#128522;</p>
          </div>
        </div>
      </div>
    </section>
    <section class="form-sec" v-if="showNext" id="pricing">
      <div class="container">
        <div class="form-inner">
          <div class="form-left">
            <img src="resources/assets/signup_left.svg" alt="" />
          </div>
          <div class="form-right" id="signup">
            <div class="form-wrapper">
              <h1 class="form-heading">Sign Up</h1>
              <h1 class="form-dark-heading">$79.99/Month</h1>
              <form class="w-100">
                <div class="row">
                  <div class="col-md-6 mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="text" class="loginInputsEmail" placeholder="First Name" autocomplete="off"
                        v-validate="'required'" v-model="register.first_name" v-bind:name="'register.first_name'"
                        v-bind:ref="'register.first_name'" :class="{
                          'is-invalid': errors.has('register.first_name'),
                        }" />
                    </div>
                  </div>
                  <div class="col-md-6 mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="text" class="loginInputsEmail" placeholder="Last Name" autocomplete="off"
                        v-model="register.last_name" v-validate="'required'" v-bind:name="'register.last_name'"
                        v-bind:ref="'register.last_name'" :class="{
                          'is-invalid': errors.has('register.last_name'),
                        }" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="text" class="loginInputsEmail" placeholder="Business/Organization Name"
                        autocomplete="off" v-model="register.organisation_name" v-validate="'required'"
                        v-bind:name="'register.organisation_name'" v-bind:ref="'register.organisation_name'" :class="{
                          'is-invalid': errors.has(
                            'register.organisation_name'
                          ),
                        }" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="email" class="loginInputsEmail" placeholder="Email" autocomplete="off"
                        v-model="register.email" v-validate="'required'" v-bind:name="'register.email'"
                        v-bind:ref="'register.email'" :class="{
                          'is-invalid': errors.has('register.email'),
                        }" />
                    </div>
                  </div>
                  <div class="col-md-6 mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="number" class="loginInputsEmail" placeholder="Phone" autocomplete="off"
                        v-model="register.phone" v-validate="'required'" v-bind:name="'register.phone'"
                        v-bind:ref="'register.phone'" :class="{
                          'is-invalid': errors.has('register.phone'),
                        }" />
                    </div>
                  </div>
                </div>
                <div class="mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input type="password" class="loginInputsEmail" placeholder="Password" autocomplete="off"
                      v-model="register.password" v-validate="'required'" v-bind:name="'register.password'"
                      v-bind:ref="'register.password'" :class="{
                        'is-invalid': errors.has('register.password'),
                      }" />
                  </div>
                </div>
                <div class="mb-4 pb-2">
                  <div class="form-outline form-white">
                    <input type="text" class="loginInputsEmail" placeholder="Billing Address" autocomplete="off"
                      v-model="register.billing_address" v-validate="'required'"
                      v-bind:name="'register.billing_address'" v-bind:ref="'register.billing_address'" :class="{
                        'is-invalid': errors.has('register.billing_address'),
                      }" @input="initialize" id="autocomplete"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="text" class="loginInputsEmail" placeholder="City" autocomplete="off"
                        v-model="register.city" v-validate="'required'" v-bind:name="'register.city'"
                        v-bind:ref="'register.city'" :class="{
                          'is-invalid': errors.has('register.city'),
                        }" />
                    </div>
                  </div>
                  <div class="col-md-3 mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="text" class="loginInputsEmail" placeholder="State" autocomplete="off"
                        v-model="register.state" v-validate="'required'" v-bind:name="'register.state'"
                        v-bind:ref="'register.state'" :class="{
                          'is-invalid': errors.has('register.state'),
                        }" />
                    </div>
                  </div>
                  <div class="col-md-3 mb-4 pb-2">
                    <div class="form-outline form-white">
                      <input type="text" class="loginInputsEmail" placeholder="Zip" autocomplete="off"
                        v-model="register.zip_code" v-validate="'required'" v-bind:name="'register.zip_code'"
                        v-bind:ref="'register.zip_code'" :class="{
                          'is-invalid': errors.has('register.zip_code'),
                        }" />
                    </div>
                  </div>
                </div>
                <div class="d-grid gap-2">
                  <button class="signUpButton" type="button" @click="signUp()">
                    <p class="signUpButtonText">Sign Up</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="form-sec" v-else>
      <div class="container">
        <div class="form-inner">
          <div class="form-left">
            <img src="resources/assets/signup_left.svg" alt="" />
          </div>
          <div class="form-right">
            <div class="form-wrapper">
              <h1 class="form-heading">Sign Up</h1>
              <h1 class="form-dark-heading">$79.99/Month</h1>
              <Payment :registerData="register" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features-card" id="features">
      <div class="container">
        <h1 class="features-heading">Features</h1>
        <div class="features-wrapper">
          <ul class="features-list">
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>
              SMS Review Invite Messages
            </li>
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>
              Email Review Invite Messages
            </li>
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>
              Custom Invite Messages
            </li>
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>
              Custom Feedback Form
            </li>
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>

              Interactive into Facebook, Google, Yelp and Zillow Reviews
            </li>
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>
              SMS Review Invite Messages
            </li>
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>
              Reporting
            </li>
            <li>
              <span class="feature-icon">
                <img src="resources/assets/circleCheck.png" />
              </span>
              No Contracts! Cancel Anytime
            </li>
          </ul>
        </div>
        <!-- <div class="review-inner d-flex" style="margin: -115px 0 0 78%">
          <img src="resources/assets/plant_startup.svg" alt="" />
        </div> -->
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "./landingpage/LandingHeader";
import Footer from "./landingpage/LandingFooter";
import Payment from "./LandingPayment";

export default {
  name: "landing-page",
  components: {
    Header,
    Footer,
    Payment,
  },
  data() {
    return {
      register: {
        first_name: "",
        last_name: "",
        organisation_name: "",
        email: "",
        phone: "",
        password: "",
        billing_address: "",
        city: "",
        state: "",
        zip_code: "",
      },
      showNext: true,
      moreReviews: {},
      positive: [],
      negative: [],
      feedback: [],
      faqList: [],
      showVideo: false,
      video_url: ""
    };
  },
  mounted() {
    this.landingData();
    this.faqData();
  },
  methods: {
    async signUp() {
      let result = await this.$validator.validateAll();
      if (result) {
        this.$store.dispatch("register", this.register).then((response) => {
          if (response.success == true) {
            this.showNext = false;
          } else {
            if (response.data.data.email) {
              this.$toastr.e(response.data.data.email);
            }
            if (response.data.data.password) {
              this.$toastr.e(response.data.data.password);
            }
          }
        });
      }
    },
    landingData() {
      this.$store.dispatch("landingHome").then((response) => {
        if (response.success) {
          this.moreReviews = response.data[0];
          this.positive = response.data[1];
          this.negative = response.data[2];
          this.feedback = response.data[3];
        }
      });
    },
    faqData() {
      this.$store.dispatch("faqList").then((response) => {
        if (response.success) {
          let res = response.data.data;
          this.faqList = res ? res : "";
        }
      });
    },
    cancel() {
      this.showVideo = false
    },
    playVideo(data) {
      if (data === 'positive') {
        this.video_url = this.positive.video_url
      }
      if (data === 'negative') {
        this.video_url = this.negative.video_url
      } if (data === 'feedback') {
        this.video_url = this.feedback.video_url
      }
      if (this.video_url) {
        this.showVideo = true;
      }
    },
    initialize(e) {
      var self = this;
      let input = document.getElementById("autocomplete");
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        let place = autocomplete.getPlace();
        self.register.billing_address = place.name
        self.register.city =
          place.address_components[
            place.address_components.length - 4
          ].long_name;
        self.register.state =
          place.address_components[
            place.address_components.length - 3
          ].long_name;
        self.register.zip_code =
          place.address_components[
            place.address_components.length - 1
          ].long_name;
      });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  margin: 0;
  min-height: 100vh;

  display: flex;
  /* min-height: 100vh; */
  flex-direction: column;
  background: #e5e5e5;
}

.flexbox {
  display: flex;
  /* height: 100px; */
  column-gap: 20px;
}

.flexbox>div {
  /* border: 1px solid green; */
  flex: auto;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

section {
  padding: 60px 0;
}

.first-left-section {
  position: absolute;
  width: 646.5px;
  height: 646.5px;
  left: 50px;
  top: 838.75px;
  background: rgba(0, 5, 60, 0.02) none repeat scroll 0% 0%;
}

.section-center {
  margin: 25px auto;
}

.ecllipse-bg {
  /* position: absolute; */
  width: 646.5px;
  height: 646.5px;
  left: -91.5px;
  top: 838.75px;
  background: rgba(0, 5, 60, 0.02);
  border-radius: 50%;
  display: inline-block;
}

.pink-ecllipse-img {
  padding: 100px 10px 0px;
}

.green-ecllipse-img {
  padding: 100px 0px 0px 150px;
}

.yellow-ecllipse-img {
  padding: 100px 0 100px 100px;
}

.pink-ecllipse-paragraph {
  /* position: absolute; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #9194b3;
  margin: 25px 0;
  padding: 0 25px;
}

.pink-ecllipse-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}

.yellow-ecllipse-paragraph {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #9194b3;
  margin: 25px 0;
  padding: 0 25px;
}

.yellow-ecllipse-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}

.green-ecllipse-paragraph {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #9194b3;
  margin: 25px 0;
  padding: 0 25px;
}

.green-ecllipse-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}

.features-card {
  background-color: rgb(0, 5, 60);
  width: 100%;
}

.signup-card {
  width: 100%;
  background: #f2f5fa;
}

.startBtn {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 25%;
  height: 50px;
  font-weight: 600;
}

.buttonText {
  left: 54.13%;
  right: 38.58%;
  top: 28.17%;
  bottom: 71.36%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;

  display: flex;
  align-items: center;
  text-align: center;
  background: white;
  color: #00053c;
}

.signup-form {
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  padding: 20px;
}

.signup-text {
  width: 257px;
  left: 739.71px;
  top: 3017px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 73px;
  color: #00053c;
}

.signup-price {
  width: 257px;
  left: 739.71px;
  top: 3017px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  /* or 150% */

  color: #00053c;
}

.largeFont {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 60px;
  color: #00053c;
}

.doller-per-month {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 60px;
  color: #00053c;
}

.loginInputsEmail {
  width: 100%;
  height: 58px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 15px;
  font-size: 16px;
}

.signUpButton {
  margin-top: 20px;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
  transition: 0.2s ease;
}

.signUpButtonText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  display: flex;
  align-items: center;

  color: #9194b3;
}

.review-inner {
  position: relative;
}

.review-inner::after {
  position: absolute;
  content: "";
  background: rgba(145, 148, 179, 0.3);
  width: 1px;
  height: 100%;
  top: -20px;
  left: 20px;
}

.review-feature .colorWhite {
  font-family: "Inter";
  font-weight: 600;
  font-size: 60px;
  color: #ffffff;
}

.colorWhite {
  color: white;
  font-size: 3.25rem;
  font-weight: 500;
}

.features-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 73px;
  color: #ffffff;
  padding: 100px 50px 0 50px;
}

.list {
  padding-top: 30px;
  margin-top: 50px;
  padding-left: 30px;
  margin-left: 20px;
  line-height: 29px;
}

.list li {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #9194b3;
  list-style: none;
  margin-bottom: 20px;
}

.list li img {
  margin-right: 2vw;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.btn.btn-solid {
  background: #00053c;
  color: #ffffff;
}

.btn.btn-outline {
  border: 1px solid #00053c;
  color: #00053c;
  background: transparent;
}

.btn {
  border-radius: 15px;
  height: 50px;
  min-width: 150px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  background: #00053c;
}

.btn-solid:hover {
  background: #5b95f5 !important;
  color: #ffffff !important;
}

.form-inner {
  display: flex;
  flex-wrap: wrap;
}

.form-left {
  flex: 0 0 50%;
}

.form-right {
  flex: 0 0 50%;
}

.form-sec {
  background: #f2f5fa;
  padding: 0;
  margin-top: 60px;
}

.form-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 50px;
  margin-top: -60px;
  margin-bottom: -60px;
  position: relative;
  z-index: 9;
}

.form-check-label {
  font-size: 12px;
}

.form-heading {
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 73px;
  color: #00053c;
  margin-bottom: 5px;
}

.form-dark-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
  margin-bottom: 20px;
}

.section-bd-heading {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}

.section-bd-heading strong {
  font-style: normal;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
  font-weight: 600;
}

.yellow-ecllipse-paragraph {
  position: relative;
}

.yellow-ecllipse-paragraph:before {
  content: "";
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fdd640;
  border-radius: 3px;
}

.yellow-ecllipse-paragraph {
  position: relative;
}

.yellow-ecllipse-paragraph:before {
  content: "";
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fdd640;
  border-radius: 3px;
}

.pink-ecllipse-paragraph {
  position: relative;
}

.pink-ecllipse-paragraph:before {
  content: "";
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fa4344;
  border-radius: 3px;
}

.green-ecllipse-paragraph {
  position: relative;
}

.green-ecllipse-paragraph:before {
  content: "";
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #38cb89;
  border-radius: 3px;
}

.btn.btn-solid {
  background: #00053c;
  color: #ffffff;
}

.btn.btn-outline {
  border: 1px solid #00053c;
  color: #00053c;
  background: transparent;
}

.btn {
  border-radius: 15px;
  height: 50px;
  min-width: 150px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  background: #00053c;
}

.btn-solid:hover {
  background: #5b95f5 !important;
  color: #ffffff !important;
}

.btn-outline:hover {
  background: #00053c !important;
  color: #ffffff !important;
}

@media (min-width: 1400px) {
  .largeFont {
    font-size: 50px;
  }

  .doller-per-month {
    font-size: 50px;
  }
}

@media (max-width: 1599.98px) {
  .largeFont {
    font-size: 50px;
  }

  .doller-per-month {
    font-size: 50px;
  }

  .review-feature .colorWhite {
    font-size: 50px;
  }
}

@media (max-width: 1199.98px) {
  .largeFont {
    font-size: 40px;
  }

  .doller-per-month {
    font-size: 40px;
  }

  .review-feature .colorWhite {
    font-size: 40px;
  }
}

@media (max-width: 991.98px) {

  .form-left,
  .form-right,
  .hero-left,
  .hero-right {
    flex: 0 0 100%;
  }

  .container {
    max-width: 85% !important;
  }

  .navbar-collapse.collapse:not(.show) {
    display: none !important;
  }

  .navbar-collapse {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
  }

  .site-header .container-fluid:before,
  .site-header .container-fluid:after {
    display: none;
  }

  .navbar-collapse .navbar-nav.navbar-nav {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }

  .navbar-collapse .navbar-nav.navbar-nav .main-nav li {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .navbar-collapse .navbar-nav.navbar-nav .main-nav {
    flex-wrap: wrap;
  }

  .navbar-collapse .navbar-nav.navbar-nav .right-nav li {
    flex: 0 0 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .navbar-collapse .navbar-nav.navbar-nav .right-nav {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .site-header .navbar.navbar-expand-lg>.container-fluid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
  }

  .site-header .navbar.navbar-expand-lg>.container-fluid .navbar-brand {
    margin: 0;
    flex: 0 0 auto;
    justify-content: flex-start;
    float: none !important;
  }

  .site-header .navbar.navbar-expand-lg>.container-fluid button.navbar-toggler {
    justify-content: flex-end;
    flex: 0 0 auto;
    background: transparent !important;
  }

  .form-wrapper {
    margin-top: 0;
  }

  .form-heading {
    font-size: 45px;
    line-height: 52px;
  }

  .form-dark-heading {
    font-size: 34px;
    line-height: 50px;
  }
}

.bg-left {
  background-repeat: no-repeat;
  background-position: top left 30px;
  background-size: contain;
}

.bg-right {
  background-repeat: no-repeat;
  background-position: top right 30px;
  background-size: contain;
}

.features-card {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-image: url("./../../assets/features.svg");
  position: relative;
}

.features-card:before {
  content: "";
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-image: url("./../../assets/plant_startup.svg");
  width: 400px;
  height: 275px;
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.features-list>li {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #9194b3;
  position: relative;
  padding-left: 52px;
  list-style-type: none;
  min-height: 32px;
  flex: 0 0 50%;
  padding-right: 30px;
}

.features-list>li:not(:last-child) {
  margin-bottom: 26px;
}

.feature-icon {
  height: 32px;
  width: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
}

.features-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 73px;
  color: #ffffff;
  padding-bottom: 60px;
  margin: 0;
}

section.features-card {
  padding-top: 120px;
  padding-bottom: 80px;
}

@media (max-width: 767.98px) {
  .features-list>li {
    flex: 0 0 100%;
  }

  .multi-heading[data-v-5b83b900] {
    font-size: 45px;
    line-height: 50px;
  }

  .section-bd-heading,
  .section-bd-heading strong {
    font-size: 32px;
    line-height: 45px;
  }

  .form-wrapper {
    padding: 30px;
  }
}

@media (max-width: 480px) {}

.accordion-header {
  text-align: center;
  margin: 20px;
}

.accordion-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.accordion-wrapper {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  width: 600px;
  margin: 0 auto;
}

.accordion {
  width: 100%;
  color: white;
  overflow: hidden;
  margin-bottom: 16px;
}

.accordion:last-child {
  margin-bottom: 0;
}

.accordion-label {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;
  /* background: rgba(4, 57, 94, .8); */
  background: #9194B3;
  font-weight: bold;
  cursor: pointer;
  font-size: 20px;
}

.accordion-label:hover {
  /* background: rgba(4, 57, 94, 1); */
  background: #00053C;
}

.accordion-label::after {
  content: "\276F";
  width: 16px;
  height: 16px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.accordion-content {
  max-height: 0;
  padding: 0 16px;
  /* color: rgba(4, 57, 94, 1); */
  background: #00053C;
  background: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.accordion-content p {
  margin: 0;
  /* color: rgba(4, 57, 94, .7); */
  color: #00053C;
  font-size: 18px;
}

input:checked+.accordion-label {
  /* background: rgba(4, 57, 94, 1); */
  background: #00053C;
}

input:checked+.accordion-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

input:checked~.accordion-content {
  max-height: 100vh;
  padding: 16px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: table;
  margin: auto
}

.modal-container {
  margin: 100px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.youtube-video {
  width: 600px;
  height: 300px;
}
</style>