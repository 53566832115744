<template>
  <div>
    <div v-if="apiLoading" class="loading">
      Loading&#8230;
    </div>
    <main class="py-6" id="mainDiv">
      <div>
        <div class="card-header" style="background: none; border: none">
          <h2 class="">Individual Feedback</h2>
        </div>
      </div>

      <div class="card border-0 mb-7 table-align">
        <div class="card border-0 mb-7" style="margin: 30px; border-radius: 36px">
          <div class="row align-items-center mrgn-mobile" style="margin: 19px">
            <div class="col-sm-4 col-12 mb-4 mb-sm-0">
              <div class="card-header" style="background: none; border: none">
                <h2 class="mb-0 user-name" style="font-size: 26px">
                  {{ customerName }}
                </h2>
                <p class="date-font-size">
                  {{ moment(feedbackViewData.created_at).format("MMMM D, YYYY") }}
                  •
                  {{ moment(feedbackViewData.created_at).format("h:mm A") }}
                </p>
              </div>
            </div>
            <div class="col-md-8 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
              <div class="form-outline form-white">
                <select class="
                  viewReviewSecondPartdropdownText
                  inputOrange
                  userForm
                  form-control-lg
                  feedback-status
                " v-model="feedbackStatus">
                  <option class="viewReviewSecondPartdropdownText" value="0">
                    New
                  </option>
                  <option class="viewReviewSecondPartdropdownText" value="1">
                    Pending
                  </option>
                  <option class="viewReviewSecondPartdropdownText" value="2">
                    Resolved
                  </option>
                </select>
              </div>

              <button type="button" class="submit-button feedback-button-bg" @click="statusFeedback()">
                <p style="margin:0" class="submitButtonText">Submit</p>
              </button>
            </div>
          </div>

          <hr style="margin: 10px 30px 40px; box-shadow: 1px 1px #f4f5fa" />
          <div class="row m-0 px-4 feedback-row">
            <div class="card border-0 mb-7 col-5 pr-4">
              <div class="feeback-action">
                <div class="row align-items-center" style="margin-top: 25px; padding: 15px">
                  <div class="col-md-8 col-12 mb-4 mb-sm-0">
                    <p class="d-flex align-items-center feedback-label">
                      {{ `Feedback Submitted on ${feedbackViewData.platformName}` }}
                    </p>
                  </div>
                  <div class="
                    col-md-4 col-12
                    mb-4 mb-sm-0
                    d-flex
                    justify-content-end
                  ">
                    <p class="
                      d-flex
                      align-items-center
                      viewReviewSecondPartTopText
                    ">
                      <img v-for="index in parseInt(feedbackViewData.rating)" :key="index + 'yellow'"
                        src="resources/assets/yellowStar.png" />
                      <img v-for="index in 5 - parseInt(feedbackViewData.rating)" :key="index + 'blank'"
                        src="resources/assets/blankStar.png" />
                    </p>
                  </div>
                </div>

                <div class="card border-0 mb-7 inner-feedback-action">
                  <div class="col-12 mb-4 mb-sm-0 d-flex">
                    <p class="d-flex align-items-center recent-reviews" style="margin: 10px">
                      Recent Reviews
                    </p>
                  </div>
                  <div class="col-12 mb-4 mb-sm-0 d-flex">
                    <div class="form-outline form-white inner-white" style="width: 49%; margin-left: 110px">
                      <h3 class="heading">{{ customerName }}</h3>
                    </div>
                    <div class="form-outline form-white inner-white">
                      <h3 class="heading">
                        <img v-for="index in parseInt(feedbackViewData.rating)" :key="index + 'yellow'"
                          src="resources/assets/yellowStar.png" />
                        <img v-for="index in 5 - parseInt(feedbackViewData.rating)" :key="index + 'blank'"
                          src="resources/assets/blankStar.png" />
                      </h3>
                    </div>
                  </div>
                  <div class="col-12 mb-4 mb-sm-0 d-flex">
                    <div class="form-outline form-white inner-white" style="margin-left: 20%"></div>
                    <div class="form-outline form-white">
                      <p class="communicationFormLabel">
                        {{ feedbackViewData.feedback }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 mb-4 mb-sm-0 d-flex">
                    <div class="form-outline form-white inner-white" style="margin-left: 20%"></div>
                    <div class="form-outline form-white inner-white d-flex">
                    </div>
                  </div>

                  <!-- <div v-if="feedbackViewData.platformName == 'google'" class="card border-0 feeback-action col-9" style="margin-left: auto; margin-right: 20px">
                  <div class="mb-4 mb-sm-0 d-flex">
                    <div class="form-outline form-white" style="width: 49%; padding: 10px">
                      <p class="communicationFormLabel">2 Reviews</p>
                    </div>
                    <div class="form-outline form-white" style="width: 51%; padding: 10px">
                      <p class="communicationFormLabel" style="justify-content: end">
                        a week ago
                      </p>
                    </div>
                  </div>

                  <div class="mb-4 mb-sm-0 d-flex">
                    <div class="form-outline form-white">
                      <p class="heading">Response from the owner</p>
                    </div>
                  </div>
                  <div class="mb-4 mb-sm-0 d-flex">
                    <div class="form-outline form-white">
                      <p class="communicationFormLabel">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                  </div>

                </div> -->
                  <div class="center-item">
                    <button type="button" class="respondButton">
                      <a style="text-decoration: none;" :href="`${feedbackViewData.url
                      ? feedbackViewData.url
                      : '#'
                      }`">
                        <p class="buttonText" style="background: white; color: #00053c; margin: 0px">
                          {{ `Respond on ${feedbackViewData.platformName}` }}
                        </p>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 mb-7 col-7">
              <div class="feeback-action">
                <div class="mt-4 mb-2" style="padding: 15px">
                  <p class="d-flex align-items-center feedback-label">Action</p>
                </div>
                <div class="card border-0 mb-7 inner-feedback-action" style="border-radius: 36px">
                  <div style="padding: 25px">
                    <div class="form-outline form-white inner-white">
                      <h3 class="heading">Send Thank You Text</h3>
                      <p class="communicationFormLabel">Text Customer</p>
                      <input type="text" class="
                        form-control
                        userForm
                        form-control-lg
                        feedbackInput
                        inputOrange
                      " v-model="textMessage" />
                    </div>
                    <div class="row">
                      <div class="col-md-12 mb-4 pb-2">
                        <div class="form-outline form-white d-flex flex-row-reverse">
                          <button class="assignButton" style="width: 150px"
                            @click="individualFeedbackFiveResponse('TEXT')">
                            Send Now
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="form-outline form-white inner-white" id="emailBlock">
                      <h3 class="heading">Send Thank You Email</h3>
                      <p class="communicationFormLabel">Email Customer</p>
                      <input type="text" class="
                        form-control
                        userForm
                        form-control-lg
                        feedbackEmailInput
                        inputOrange
                      " style="border-radius: 15px 15px 0px 0px" placeholder="Send to:" v-model="emailSendTo" />
                      <input type="text" class="
                        form-control
                        userForm
                        form-control-lg
                        feedbackEmailInput
                        inputOrange
                      " placeholder="Subject:" v-model="emailSubject" />
                      <vue-editor v-model="emailMessage" :editorToolbar="customToolbar" class="ql-editor"></vue-editor>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mb-4 pb-2">
                        <div class="form-outline form-white d-flex flex-row-reverse">
                          <button class="assignButton" style="width: 150px"
                            @click="individualFeedbackFiveResponse('Email')">
                            Send Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-card">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap formtable">
              <thead class="thead-light">
                <tr>
                  <th class="heading" scope="col">SENT DATE</th>
                  <th class="heading" scope="col">MESSAGE TYPE</th>
                  <th class="heading" scope="col">UPDATED</th>
                  <th class="heading" scope="col">ASSIGNED</th>
                  <th class="heading" scope="col">MESSAGE</th>
                  <th class="heading" scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody v-if="!feedbackList || feedbackList.length == 0">
                <tr>
                  <td colspan="6" style="text-align: center; font-weight: 600">
                    No Data Found.
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="feedback in feedbackList" v-bind:key="feedback.id">
                  <td class="text-heading">
                    {{ moment(String(feedback.sentDate)).format("MM-DD-YYYY") }}
                  </td>
                  <td class="text-heading">{{ feedback.messageType }}</td>
                  <td class="text-heading">
                    {{ moment(String(feedback.updated)).format("MM-DD-YYYY") }}
                  </td>
                  <td class="text-heading">
                    {{ feedback.assigned }}
                  </td>
                  <td class="text-heading">
                    <span v-html="feedback.message"></span>
                    <!-- <p>{{ feedback.message }}</p> -->
                  </td>
                  <td>
                    <a href="#" class="btn btn-sm btn-neutral"><img src="resources/assets/view_icon.svg"
                        @click="openFeedbackSixModel()" /></a>
                    <a href="#" class="btn btn-sm btn-neutral" @click="individualFeedbackDelete(feedback.id)">
                      <img src="resources/assets/trash_icon.svg" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <transition name="modal" v-if="feedbackSixModel">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="feedback-modal-container">
              <div>
                <form class="d-block">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h1 class=""></h1>
                    <div class="align-items-center">
                      <p class="center-item viewReviewSecondPartTopName">
                        {{ feedbackViewData.customerName }}
                      </p>
                      <p class="center-item">
                        <img v-for="index in parseInt(feedbackViewData.rating)" :key="index"
                          src="resources/assets/yellowStar.png" />
                        <img v-for="index in 5 - parseInt(feedbackViewData.rating)" :key="index"
                          src="resources/assets/blankStar.png" />
                      </p>
                    </div>
                    <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                  </div>
                  <div class="row mb-5" style="justify-content: center">
                    <button type="button" class="yellowButton1">
                      <a v-if="customerPhoneName" :href="`tel:${customerPhoneName}`" style="text-decoration: none">
                        <p class="callButtonText">Call</p>
                      </a>
                      <p v-else class="callButtonText">Call</p>
                    </button>
                  </div>
                  <div class="row">
                    <div class="form-outline form-white"></div>

                    <div class="viewReviewSecondPartdropdown">
                      <div v-for="(communication, index) in textCommunicationList" v-bind:key="index">
                        <div class="customerName">
                          <p class="customerNameText dark-color">
                            {{ communication.assigned }}
                          </p>
                          <p class="customerNameText light-color">
                            {{
                                moment(String(communication.created_at)).format(
                                  "MM-DD-YYYY"
                                )
                            }}
                            •
                            {{
                                moment(String(communication.created_at)).format(
                                  "hh:mm A"
                                )
                            }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="viewReviewSecondPartdropdownText">
                            {{ communication.message }}
                          </p>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="modal" v-if="customerFeedbackModal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container-view">
              <CustomerDetails :customerId=feedbackId @closeModal="closeM($event)" />
            </div>
          </div>
        </div>
      </transition>
    </main>
  </div>

</template>

<script>
import moment from "moment";
import CustomerDetails from "./CustomerDetails";
export default {
  name: "Feedback3",
  props: {
    scrollToEmaill: {
      type: Boolean,
    },
    feedbackId: {
      type: Number,
    },
  },
  components: {
    CustomerDetails
  },
  data() {
    return {
      moment: moment,
      feedbackViewData: [],
      feedbackSixModel: false,
      textMessage: "",
      emailSendTo: "",
      emailSubject: "",
      emailMessage: "",
      customerName: "",
      customerPhoneName: "",
      feedbackList: [],
      textCommunicationList: [],
      feedbackStatus: 0,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      apiLoading: false,
      customerFeedbackModal: false
    };
  },

  mounted() {
    this.individualFeedbackList();
    if (this.scrollToEmaill) {
      var element = document.getElementById("emailBlock");
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      var element = document.getElementById("mainDiv");
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }

    this.viewFeedback(this.feedbackId);
  },
  created() {
    this.viewFeedback(this.feedbackId);
    this.defaultFeedbackData();
  },
  computed: {},
  methods: {
    viewFeedback(id) {
      this.feedbackViewData = [];
      this.customerName = "";
      this.$store.dispatch("viewFeedback", id).then((response) => {
        this.feedbackViewData = response.data[0];
        this.customerName = this.feedbackViewData.customerName;
        this.customerPhoneName = this.feedbackViewData.phoneNumber;
        this.feedbackStatus = this.feedbackViewData.status
          ? this.feedbackViewData.status
          : 0;
      });
    },
    cancel() {
      this.feedbackSixModel = false;
    },
    statusFeedback() {
      let data = {
        id: this.feedbackId,
        status: this.feedbackStatus,
      };
      this.$store.dispatch("statusFeedback", data).then((response) => {
        if (response.success) {
          this.$toastr.s(`${response.message}`);
        } else {
          this.$toastr.e(`${response.message}`);
        }
      });
    },
    openFeedbackSixModel() {
      this.feedbackSixModel = true;
      this.textCommunication();
    },
    textCommunication(id) {
      let data = {
        parent_id: this.feedbackId,
      };
      this.$store.dispatch("textList", data).then((response) => {
        if (response.data.length > 0) {
          this.textCommunicationList = response.data;
          this.feedbacksixModel = true;
        }
      });
    },
    individualFeedbackFiveResponse(data) {
      let message =
        data === "TEXT"
          ? this.textMessage
          : data === "Email"
            ? this.emailMessage
            : "";

      let feedbackData = {
        messageType: data,
        message: message,
        email: this.emailSendTo,
        subject: this.emailSubject,
        parent_id: this.feedbackId,
        parentUserID: localStorage.getItem("id"),
        customerName: this.customerName,
        assigned: this.feedbackViewData.assignment,
        userID: this.feedbackViewData.userID,
      };
      this.apiLoading = true;

      this.$store
        .dispatch("individualFeedbackFiveResponse", feedbackData)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            // this.resetFeedbackData();
            this.individualFeedbackList();
          } else if (response.success == false) {
            if (response.data && response.data.userID) {
              this.$toastr.e(response.data.userID);
            } else {
              if (response.message == 'Please Add customer Details') {
                this.$toastr.i(`${response.message}`);
                this.customerFeedbackModal = true;
              } else {
                this.$toastr.e(`${response.message}`);
              }
            }
          }
          else {
            this.$toastr.e(`${response.message}`);
          }
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    individualFeedbackList() {
      this.feedbackList = [];
      let data = {
        id: this.feedbackId,
      };
      this.$store.dispatch("individualFeedbackList", data).then((response) => {
        this.feedbackList = response.data;
      });
    },
    individualFeedbackDelete(id) {
      let data = {
        id,
      };
      this.$store
        .dispatch("individualFeedbackDelete", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.individualFeedbackList();
          } else {
            this.$toastr.e(`${response.message}`);
          }
        });
    },
    defaultFeedbackData() {
      let data = {
        userId: localStorage.getItem("id"),
      };
      this.$store.dispatch("defaultFeedbackData", data).then((response) => {
        let res = response.data[0];
        let subject = response.data.subject;
        this.textMessage = res.thankyouMessage
          ? res.thankyouMessage
          : "";
        this.emailMessage = res.thankyouEmail
          ? res.thankyouEmail
          : "";
        this.emailSendTo = res.replyEmail ? res.replyEmail : "";
        this.emailSubject = subject ? subject : "";
      });
    },
    resetFeedbackData() {
      this.emailSendTo = "";
      this.emailSubject = "";
      this.emailMessage = "";
      this.textMessage = "";
    },
    closeM() {
      this.customerFeedbackModal = false;
    },
  },
};
</script>


<style scoped>
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

.modal-container-view {
  width: 800px;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.text-heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  text-transform: capitalize;
  color: #00053c;
  white-space: normal; 
}

.formtable {
  text-align: center;
}

.heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 1.3;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00053c !important;
}

.feedback-button {
  border-radius: 15px;
  width: 15%;
  margin: 10px;
}

.feedback-button-bg {
  background: #00053c;
}

.row {
  margin-right: 0px;
}

.table>tbody>tr>td {
  padding: 30px 15px;
}

.form-white>div {
  width: 26vw;
  margin: 0 5vw;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.3;
  color: #9194b3;
}

svg {
  width: 40px;
  height: 40px;
}

svg {
  color: white;
  z-index: 1;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.date-font-size {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.user-name {
  /* position: absolute; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #323b4b;
}

.feedback-status {
  width: 80%;
  height: 50px;
  top: 500px;
  background: #f4f5fa;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  /* width: 10%; */
  margin: 10px;
}

.full-feedback {
  /* width: 330px;
    height: 292px;
    left: 395px;
    top: 471px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: #9194b3;
}

.feeback-action {
  border-radius: 20px;
  background: #f4f5fa;
}

.inner-feedback-action {
  margin: 15px 20px;
  border-radius: 15px;
}

.feedback-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.submitButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0px;
}

.communicationFormLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #9194b3;
}

.feedbackInput {
  width: 100%;
  height: 58px;
  padding: 20px;
  background: #f4f5fa;
  border: 1px solid #f3f3f3;
  border-radius: 15px;
}

.feedbackEmailInput {
  width: 100%;
  height: 58px;
  padding: 20px;
  background: #f4f5fa;
  border: 0 !important;
  border-bottom: 1px solid #f3f3f3;
  border-radius: 0;
}

.feedbackEmailTextArea {
  width: 100%;
  height: 150px;
  background: #f4f5fa;
  border: 0;
  padding: 20px;
}

.inner-white {
  border-radius: 15px;
  padding: 25px 0px;
}

.assignButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  background: #00053c;
  color: #ffffff;
  width: 180px;
  height: 51px;
  border-radius: 15px;
}

.likeButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  background: #00053c;
  color: #ffffff;
  width: 180px;
  height: 51px;
  border-radius: 15px;
}

::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
}

textarea {
  resize: none;
}

.yellowButton {
  background: #fdd640;
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  height: 50px;
  border-radius: 15px;
  padding: 10px;
}

.yellowButton1 {
  background: #fdd640;
  border-radius: 15px;
  width: 25%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: #00053c;
  height: 50px;
  padding: 10px;
}

.customer-name {
  margin: 10px;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #00053c;
}

.reviews-count {
  margin: 10px;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
  justify-content: space-evenly;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
}

.feedback-modal-container {
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  width: 600px;
  margin: 40px auto;
}

.viewReviewSecondPartTopName {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #00053c;
}

.viewReviewSecondPartdropdownText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.customerName {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.customerNameText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
}

.light-color {
  color: #9194b3;
}

.dark-color {
  color: #00053c;
}

.viewReviewSecondPartdropdown {
  margin: 15px 0px;
  background: #ffffff;
  /* border: 1px solid #f3f3f3; */
  box-sizing: border-box;
  border-radius: 15px;
}

.assignFollowUp {
  height: 50px;
  left: 889px;
  top: 413px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  color: #00053c;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: none;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 25px;
}

.respondButton {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 80%;
  height: 51px;
  font-weight: 600;
}

.feedback-status {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  top: 467px;
  padding: 10px;
  background: #f4f5fa;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  color: #00053c;
}

.submit-button {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 15%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
  padding: 10px;
  margin: 20px 0px 0px 30px;
}

.recent-reviews {
  margin: 20px 50px 10px 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: #00053c;
}

.table-align {
  margin-top: 15px;
  border-radius: 20px;
  margin-left: 10px;
  background: #f4f5fa;
}

.feedback-card {
  border-radius: 20px;
  background: #f4f5fa;
}

table th:first-child {
  border-radius: 15px 0 0 15px;
}

table th:last-child {
  border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
  /* border: 0 !important; */
  border-top: none;
}

.header th {
  height: 10px;
}

table th {
  background-color: #f4f5fa !important;
  height: 51px !important;
  padding: 15px !important;
  text-align: center !important;
}

/* style start */
.card-header h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px;
  color: #00053c;
}

.table-card {
  background: #f4f5fa;
  border-radius: 20px;
  padding: 10px 30px 20px;
}

.table-card .table-responsive {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
}

.table-responsive .table th {
  border: 0;
}

.modal-wrapper a {
  cursor: pointer;
}

@media (max-width: 1599.98px) {
  .table>tbody>tr>td {
    padding: 10px;
  }

  table .btn {
    padding: 0 5px !important;
  }
}

@media (max-width: 1200.98px) {

  .feedback-row>.col-5,
  .feedback-row>.col-7 {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .card-header h2 {
    font-size: 26px;
  }

  header {
    margin: 0 !important;
  }

  .table-align {
    margin: 0 !important;
  }

  .table-align>.card {
    margin: 12px !important;
  }

  button.submit-button.feedback-button-bg {
    width: auto;
  }

  .mrgn-mobile {
    margin: 10px !important;
  }

  .feedback-row {
    padding: 0 0px 0 5px !important;
  }

  .feedback-label {
    font-size: 16px;
    margin: 0;
  }

  .card.border-0.mb-7.inner-feedback-action {
    margin: 0 10px;
  }

  .form-white.inner-white {
    margin-left: 0 !important;
  }

  .form-outline.form-white.inner-white {
    margin-left: 10px !important;
  }

  .inner-white {
    padding: 10px 0;
  }

  .communicationFormLabel {
    font-size: 10px;
    line-height: inherit;
  }

  .likeButton {
    line-height: 1.3;
    height: 35px;
    font-size: 14px;
  }

  .card.border-0.feeback-action.col-9 {
    width: 100% !important;
  }

  .respondButton {
    height: 40px;
    font-size: 14px;
  }

  .inner-feedback-action>div {
    padding: 10px !important;
  }

  div#emailBlock textarea {
    font-size: 14px !important;
  }

  div#emailBlock input::placeholder,
  div#emailBlock textarea::placeholder {
    font-size: 14px !important;
  }

  .table-card {
    padding: 10px;
  }

  .feedback-modal-container {
    width: 90%;
  }
  .modal-container-view {
    width: 90%;
  }

}

/* vue2-editior style start */
.form-white>div {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: #f4f5fa;
  border-radius: 15px;
  font-weight: 500;
  font-size: 20px;
  color: #9194b3;
}

::v-deep .ql-toolbar.ql-snow {
  border: 0;
  background: rgba(145, 148, 179, 0.1);
  border-top: 1px solid rgba(145, 148, 179, 0.2);
}

::v-deep .ql-container.ql-snow {
  border: 0;
  height: 150px;
  overflow: scroll;
}

::v-deep .quillWrapper.ql-editor {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column-reverse !important;
}

::v-deep .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  color: #9194b3 !important;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg {
  width: 16px;
  height: 16px;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg line,
.ql-toolbar.ql-snow span.ql-formats svg path {
  stroke: #9194b3;
}

::v-deep .ql-snow .ql-picker {
  color: #9194b3;
}

/* vue2-editior style ends */
.callButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00053c;
}
</style>
