<template>
  <section>
    <div v-if="apiLoading" class="loading">Loading&#8230;</div>
    <form class="w-100">
      <div class="row">
        <div class="col-md-6 mb-4 pb-2">
          <input
            type="checkbox"
            id="registerFormChecked"
            v-model="registerFormChecked"
            @change="check()"
          />
          <label class="form-check-label fadeColor" for="registerFormChecked">
            <!-- My Billing Name and Address -->
            Use My Previous Information
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-4 pb-2">
          <div class="form-outline form-white">
            <input
              v-model="register.b_fname"
              type="text"
              class="loginInputsEmail inputOrange"
              placeholder="First Name"
              autocomplete="off"
              v-validate="'required'"
              v-bind:name="'register.b_fname'"
              v-bind:ref="'register.b_fname'"
              :class="{
                'is-invalid': errors.has('register.b_fname'),
              }"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4 pb-2">
          <div class="form-outline form-white">
            <input
              v-model="register.b_lname"
              type="text"
              class="loginInputsEmail inputOrange"
              placeholder="Last Name"
              autocomplete="off"
              v-validate="'required'"
              v-bind:name="'register.b_lname'"
              v-bind:ref="'register.b_lname'"
              :class="{
                'is-invalid': errors.has('register.b_lname'),
              }"
            />
          </div>
        </div>
      </div>
      <div class="mb-4 pb-2">
        <div class="form-outline form-white">
          <input
            v-model="register.b_billing_address"
            type="text"
            id="autocomplete"
            class="loginInputsEmail inputOrange"
            placeholder="Billing Address"
            autocomplete="off"
            v-validate="'required'"
            v-bind:name="'register.b_billing_address'"
            v-bind:ref="'register.b_billing_address'"
            :class="{
              'is-invalid': errors.has('register.b_billing_address'),
            }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-4 pb-2">
          <div class="form-outline form-white">
            <input
              v-model="register.b_city"
              type="text"
              id="form3Examplea4"
              class="loginInputsEmail inputOrange"
              placeholder="City"
              autocomplete="off"
              v-validate="'required'"
              v-bind:name="'register.b_city'"
              v-bind:ref="'register.b_city'"
              :class="{
                'is-invalid': errors.has('register.b_city'),
              }"
            />
          </div>
        </div>
        <div class="col-md-3 mb-4 pb-2">
          <div class="form-outline form-white">
            <input
              v-model="register.b_state"
              type="text"
              class="loginInputsEmail inputOrange"
              placeholder="State"
              autocomplete="off"
              v-validate="'required'"
              v-bind:name="'register.b_state'"
              v-bind:ref="'register.b_state'"
              :class="{
                'is-invalid': errors.has('register.b_state'),
              }"
            />
          </div>
        </div>
        <div class="col-md-3 mb-4 pb-2">
          <div class="form-outline form-white">
            <input
              v-model="register.b_zip_code"
              type="text"
              class="loginInputsEmail inputOrange"
              placeholder="Zip"
              autocomplete="off"
              v-validate="'required'"
              v-bind:name="'register.b_zip_code'"
              v-bind:ref="'register.b_zip_code'"
              :class="{
                'is-invalid': errors.has('register.b_zip_code'),
              }"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-4 pb-2">
          <div class="form-outline form-white">
            <div
              id="card-number"
              type="text"
              class="loginInputsStripe inputOrange"
              placeholder="Credit Card Number"
              autocomplete="off"
            ></div>
          </div>
        </div>
        <div class="col-md-3 mb-4 pb-2">
          <div class="form-outline form-white">
            <div
              id="card-expiry"
              type="text"
              class="loginInputsStripe inputOrange"
              placeholder="Exp. Date"
              autocomplete="off"
            ></div>
          </div>
        </div>
        <div class="col-md-3 mb-4 pb-2">
          <div class="form-outline form-white">
            <div
              id="card-cvc"
              type="password"
              class="loginInputsStripe inputOrange"
              placeholder="CVV"
              autocomplete="off"
            ></div>
          </div>
        </div>
      </div>

      <div class="mb-4 pb-2 custom-control custom-checkbox checkbox-lg">
        <input
          type="checkbox"
          id="terms_cond"
          autocomplete="off"
          v-model="terms_cond"
          v-validate="'required'"
          v-bind:name="'terms_cond'"
          v-bind:ref="'terms_cond'"
        />
        <label
          class="form-check-label fadeColor"
          for="terms_cond"
          :class="{
            'is-invalid1': errors.has('terms_cond'),
          }"
        >
          I Agree to the Terms of Service
        </label>
      </div>
      <div class="d-grid gap-2">
        <button class="signUpButton" type="button" @click="signUp">
          <p class="signUpButtonText">Submit</p>
        </button>
      </div>
    </form>
  </section>
</template>
  <script src="https://js.stripe.com/v3"></script>
  
  <script>
export default {
  name: "landing-page",
  components: {},
  props: {
    registerData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      register: {
        first_name: "",
        last_name: "",
        organisation_name: "",
        email: "",
        phone: "",
        password: "",
        billing_address: "",
        city: "",
        state: "",
        zip_code: "",
        b_fname: "",
        b_lname: "",
        b_billing_address: "",
        b_city: "",
        b_state: "",
        b_zip_code: "",
      },
      showNext: true,
      registerFormChecked: false,
      terms_cond: false,
      apiLoading: false,
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
  },
  mounted() {
    this.register.first_name = this.registerData.first_name;
    this.register.last_name = this.registerData.last_name;
    this.register.organisation_name = this.registerData.organisation_name;
    this.register.email = this.registerData.email;
    this.register.phone = this.registerData.phone;
    this.register.billing_address = this.registerData.billing_address;
    this.register.city = this.registerData.city;
    this.register.state = this.registerData.state;
    this.register.zip_code = this.registerData.zip_code;
    this.register.password = this.registerData.password;

    const style = {
      base: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "29px",
        border: "1px solid #f3f3f3",
        boxSizing: "border-box",
        borderRadius: "15px",
        background: "#ffffff",
        fontFamily: '"Roboto", sans-serif',
        "::placeholder": {
          color: "#9194b3",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardNumber.update({ placeholder: "Credit Card Number" });
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardExpiry.update({ placeholder: "Exp. Date" });
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
    this.cardCvc.update({ placeholder: "CVV" });
  },
  methods: {
    async signUp() {
      let result = await this.$validator.validateAll();
      if (result) {
        this.apiLoading = true;
        const { token, error } = await this.$stripe.createToken(
          this.cardNumber
        );
        if (error) {
          this.apiLoading = false;
          this.$toastr.e(`${error.message}`);
          return;
        }
        let data = {
          token: token,
          registerForm: this.register,
        };
        this.$store
          .dispatch("subscribe", data)
          .then((response) => {
            if (response.status == true && response.code == 200) {
              this.$router.push("/Dashboard");
            } else {
              this.$toastr.e("Invalid Credentials");
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.apiLoading = false;
          });
      }
    },
    check() {
      if (this.registerFormChecked) {
        this.register.b_fname = this.registerData.first_name;
        this.register.b_lname = this.registerData.last_name;
        this.register.b_billing_address = this.registerData.billing_address;
        this.register.b_city = this.registerData.city;
        this.register.b_state = this.registerData.state;
        this.register.b_zip_code = this.registerData.zip_code;
      } else {
        this.resetPaymentForm();
      }
    },
    resetPaymentForm() {
      this.register.b_fname = "";
      this.register.b_lname = "";
      this.register.b_billing_address = "";
      this.register.b_city = "";
      this.register.b_state = "";
      this.register.b_zip_code = "";
    },
  },
};
</script>
  
  <style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  margin: 0;
  min-height: 100vh;

  display: flex;
  /* min-height: 100vh; */
  flex-direction: column;
  background: #e5e5e5;
}
.flexbox {
  display: flex;
  /* height: 100px; */
  column-gap: 20px;
}

.flexbox > div {
  /* border: 1px solid green; */
  flex: auto;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.first-left-section {
  position: absolute;
  width: 646.5px;
  height: 646.5px;
  left: 50px;
  top: 838.75px;
  background: rgba(0, 5, 60, 0.02) none repeat scroll 0% 0%;
}
.section-center {
  margin: 25px auto;
}
.ecllipse-bg {
  /* position: absolute; */
  width: 646.5px;
  height: 646.5px;
  left: -91.5px;
  top: 838.75px;
  background: rgba(0, 5, 60, 0.02);
  border-radius: 50%;
  display: inline-block;
}
.pink-ecllipse-img {
  padding: 100px 10px 0px;
}

.green-ecllipse-img {
  padding: 100px 0px 0px 150px;
}

.yellow-ecllipse-img {
  padding: 100px 0 100px 100px;
}
.pink-ecllipse-paragraph {
  /* position: absolute; */
  width: 543px;
  height: auto;
  left: 175px;
  top: 1780px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #9194b3;
  border-left: thick solid #fa4344;
  border-radius: 3px;
  margin: 25px;
  padding: 0 25px;
}
.pink-ecllipse-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}

.yellow-ecllipse-paragraph {
  width: 543px;
  height: auto;
  left: 757px;
  top: 1090px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #9194b3;
  border-left: thick solid #fdd640;
  border-radius: 3px;
  margin: 25px;
  padding: 0 25px;
}
.yellow-ecllipse-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}
.green-ecllipse-paragraph {
  width: 543px;
  height: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #9194b3;
  border-left: thick solid #38cb89;
  border-radius: 3px;
  margin: 25px;
  padding: 0 25px;
}
.green-ecllipse-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}
.features-card {
  height: 603px;
  background: rgb(0, 5, 60) none repeat scroll 0% 0%;
  width: 100%;
}
.signup-card {
  width: 100%;
  height: 708px;
  left: 0px;
  top: 3007px;

  background: #f2f5fa;
}
.startBtn {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 25%;
  height: 50px;
  font-weight: 600;
}
.buttonText {
  left: 54.13%;
  right: 38.58%;
  top: 28.17%;
  bottom: 71.36%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;

  display: flex;
  align-items: center;
  text-align: center;
  background: white;
  color: #00053c;
}
.signup-form {
  width: 720px;
  height: 828px;
  left: 1100px;
  top: 3000px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  padding: 20px;
}

.signup-text {
  width: 257px;
  left: 739.71px;
  top: 3017px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 73px;
  color: #00053c;
}
.signup-price {
  width: 257px;
  left: 739.71px;
  top: 3017px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  /* or 150% */

  color: #00053c;
}

.largeFont {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 60px;
  color: #00053c;
}

.doller-per-month {
  margin-left: 60px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 60px;
  color: #00053c;
}

.loginInputsEmail {
  width: 100%;
  height: 58px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 15px;
  font-size: 16px;
}

.loginInputsStripe {
  width: 100%;
  height: 58px;
  top: 467px;
  padding: 15px;
  background: #ffffff;
  /* BG 2 */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.signUpButton {
  margin-top: 20px;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
  transition: 0.2s ease;
}

.signUpButtonText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  display: flex;
  align-items: center;

  color: #9194b3;
}

.review-inner {
  position: relative;
}

.review-inner::after {
  position: absolute;
  content: "";
  background: rgba(145, 148, 179, 0.3);
  width: 1px;
  height: 100%;
  top: -20px;
  left: 20px;
}

.review-feature .colorWhite {
  font-family: "Inter";
  font-weight: 600;
  font-size: 60px;
  color: #ffffff;
}
.colorWhite {
  color: white;
  font-size: 3.25rem;
  font-weight: 500;
}
.features-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 73px;
  color: #ffffff;
  padding: 100px 50px 0 50px;
}

.list {
  padding-top: 30px;
  margin-top: 50px;
  padding-left: 30px;
  margin-left: 20px;
  line-height: 29px;
}
.list li {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #9194b3;
  list-style: none;
  margin-bottom: 20px;
}
.list li img {
  margin-right: 2vw;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}
.is-invalid1 {
  background-image: none !important;
  color: #dc3545 !important;
}
.form-control.is-invalid1,
.was-validated .form-control:invalid {
  color: #dc3545 !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}


@media (min-width: 1400px) {
  .largeFont {
    font-size: 50px;
  }
  .doller-per-month {
    font-size: 50px;
  }
}
@media (max-width: 1599.98px) {
  .largeFont {
    font-size: 50px;
  }
  .doller-per-month {
    font-size: 50px;
  }
  .review-feature .colorWhite {
    font-size: 50px;
  }
}

@media (max-width: 1199.98px) {
  .largeFont {
    font-size: 40px;
  }

  .doller-per-month {
    font-size: 40px;
  }
  .review-feature .colorWhite {
    font-size: 40px;
  }
}
@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 480px) {
}
</style>