<template>
    <!-- Vertical Navbar -->
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->

            <Sidebar></Sidebar>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <main class="py-6">
                    <div class="row align-items-center">
                        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                            <div class="card-header" style="background: none; border: none">
                                <h2 class="mb-0">Change Password</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-11 col-12 mb-4 mb-sm-0 basic-info">
                        <div style="margin-top: 20px">
                            <div class="com-set-wrap"
                                style=" background-color: white; margin: 0px 27px; border-radius: 15px;">

                                <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap"
                                    style="margin: 15px; padding-bottom: 50px">

                                    <div>
                                        <form class="d-block" enctype="multipart/form-data">
                                            <div class="d-flex justify-content-between align-items-center mb-5">
                                                <h1>Edit Password</h1>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Current password*
                                                        </p>

                                                        <input type="password" v-model="currentPassword"
                                                            class="form-control userForm form-control-lg inputOrange"
                                                            v-validate="'required|min:6'"
                                                            v-bind:name="'currentPassword'"
                                                            v-bind:ref="'currentPassword'"
                                                            :class="{ 'is-invalid': errors.has('currentPassword') }"
                                                            @keydown.space.prevent placeholder="Current Password" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            New password*
                                                        </p>
                                                        <input type="password"
                                                            class="form-control userForm form-control-lg inputOrange"
                                                            v-model="newPassword" v-validate="'required|min:6'"
                                                            v-bind:name="'newPassword'" v-bind:ref="'newPassword'"
                                                            :class="{ 'is-invalid': errors.has('newPassword') }"
                                                            placeholder="New Password" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            New password confirmation*
                                                        </p>
                                                        <input type="password"
                                                            class="form-control userForm form-control-lg inputOrange"
                                                            v-model="newPasswordConfirm"
                                                            v-validate="'required|min:6|confirmed:newPassword'"
                                                            v-bind:name="'newPasswordConfirm'"
                                                            v-bind:ref="'newPasswordConfirm'"
                                                            :class="{ 'is-invalid': errors.has('newPasswordConfirm') }"
                                                            placeholder="Confirm Password" />
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white d-flex flex-row-reverse">
                                                <button class="platFormCardButton" style="width: 150px"
                                                    @click="changePassword"> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>
  
<script>
import Sidebar from './Sidebar'
export default {
    name: "ChangePassword",
    components: {
        Sidebar
    },
    data() {
        return {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            adminID: "",
            apiLoading: false
        };
    },

    mounted() {
        this.adminID = localStorage.getItem('adminID');
        console.clear();
    },
    created() { },
    computed: {},
    methods: {
        async changePassword() {
            let result = await this.$validator.validateAll();
            if (result) {
                this.apiLoading = true;
                let data = {
                    userID: this.adminID,
                    old_password: this.currentPassword,
                    new_password: this.newPassword,
                    confirm_password: this.newPasswordConfirm,
                }
                this.$store
                    .dispatch("adminPassword", data)
                    .then((response) => {
                        if (response.status) {
                            this.$toastr.s(`${response.message}`);
                            this.clear();
                            this.$nextTick(() => {
                                this.$validator.reset();
                            });
                        } else {
                            this.$toastr.e(`${response.message}`);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => (this.apiLoading = false));
            }
        },
        clear() {
            this.currentPassword = "";
            this.newPassword = "";
            this.newPasswordConfirm = "";
        }
    },
};
</script>
<style scoped>
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

::placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;

    color: #9194b3;
}

.userForm {
    height: 50px;
    /* height: 58px; */
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
}

.row {
    margin-right: 0px;
}

.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    background: #00053c;
    color: #ffffff;
    width: 180px;
    height: 51px;
    border-radius: 15px;
}

.communicationFormLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    /* or 207% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;

    color: #9194b3;
}


.navOutDiv {
    position: fixed;
    width: 100%;
}

.dashScroll {
    margin-right: 0 !important;
    padding-right: 15px;
}

.basic-info {
    margin: 20px;
    border-radius: 20px;
    background: rgb(244, 245, 250);
    padding: 20px;
}

.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

@media (min-width: 1400px) {}

@media (max-width: 1599.98px) {}

@media (max-width: 1299.98px) {}

@media (max-width: 1199.98px) {
    .com-set-wrap {
        margin: 0 !important;
    }
}

@media (max-width: 991.98px) {
    .sideBar {
        height: auto !important;
    }


    .navOutDiv {
        position: unset;
    }

}

@media (max-width: 767.98px) {
    main .container {
        max-width: 100%;
    }

    .card-header h2 {
        font-size: 26px;
    }

    .basic-info {
        margin: 10px 0 0 0;
    }

    .profile-text-wrap {
        padding: 0 !important;
        margin-top: 60px;
    }

    .com-set-wrap {
        margin: 0 !important;
    }
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .form-white.inner-white {
        margin-left: 0 !important;
    }

    .form-outline.form-white.inner-white {
        margin-left: 10px !important;
    }
}
</style>